import React, {useState, useEffect} from 'react';
import queryString from 'query-string';
import Row from "antd/es/row";
import Col from "antd/es/col"
import { logoUrl } from "pages/Login/Login";
import app from "../../base.js";
import "./styles.scss";
const Action = ({history, location})=> {
  const [oobCode, setOobCode] = useState("");
  const [mode, setMode] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [form, setForm] = useState("");
  const {search} = location;
  const auth = app.auth();
  const db = app.firestore();

  const handleVerifyEmail = (oobCode)=>{
    try {
      auth.applyActionCode(oobCode).then(() => {
        setMessage("You have successfully verified your email! Now you can navigate back to our website by clicking button below.")
      }).catch((e) => {
        console.log(e);
        setErrorMessage("Link expired or invalid. Please verify email address again by signing in to our system again.");
      });
    } catch (e){
      console.log(e);
      setErrorMessage("Internal error");
    }
  }
  const handleResetPassword = (oobCode)=>{
    setForm(PasswordForm);
    auth.verifyPasswordResetCode(oobCode).then((email) => {
      setEmail(email);
    }).catch((e) => {
      console.log(e);
      setErrorMessage("Link expired or invalid. Try to reset the password again.");
    });
  }

  // const handleRecoverEmail = (oobCode)=>{

  // }
  const handleRevertSecondFactorAddition = (oobCode) => {
    try  {
      auth.applyActionCode(oobCode).then(() => {
        setMessage("You have successfully unenrolled second factor! Now you can navigate back to our website by clicking button below.");
        try {
          const phoneNumber = auth.currentUser && auth.currentUser.multiFactor.enrolledFactors[0].phoneNumber; 
          if (phoneNumber) {
            db.collection("phoneNumbers").doc(phoneNumber).delete();
          }
        } catch(e) {
          console.log(e);
        }
      }).catch((e) => {
        console.log(e);
        setErrorMessage("Link expired or invalid. Please verify email address again by signing in to our system again.");
      });
    } catch (e){
      if (e.code === "auth/argument-error") {
        setErrorMessage("Invalid code");
      } else {
        setErrorMessage("Internal error");
      }
      console.log(e);
    }
    
  }

  useEffect(() => {
    const params = queryString.parse(search);
    // console.log(params);
    const {mode, oobCode} = params;
    setOobCode(oobCode);
    setMode(mode);
    switch (mode) {
      case 'resetPassword':
        handleResetPassword(oobCode);
        break;
      // case 'recoverEmail':
      //   handleRecoverEmail(oobCode);
      //   break;
      case 'verifyEmail':
        handleVerifyEmail(oobCode);
        break;
      case "revertSecondFactorAddition":
        handleRevertSecondFactorAddition(oobCode);
        break;
      default:
        setMessage("");
        setErrorMessage("");
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const updatePassword = ()=>{
    setMessage("");
    setErrorMessage("");
    if(password !== confirmPassword) {
      setErrorMessage("Passwords do not match!");
    } else {
      auth.confirmPasswordReset(oobCode, password).then(() => {
        setMessage("Password updated successfully! Click the button to sign in with new credentials");
      }).catch((e) => {
        console.log("here");
        console.log(e);
        setErrorMessage("Weak password or the link is expired");
      });
    }
  }

  useEffect(() => {
    if(mode === 'resetPassword') {
      setForm(PasswordForm);
    }
  }, [email, password, confirmPassword, errorMessage]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    setForm(MainForm);
  }, [message]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const MainForm = <div className="action-form" >
  <span className='action-form__info'>{message}</span>
    <br/>
    <span className="action-form__error" >{errorMessage}</span>
    <button className="action-form__button back" onClick={()=>history.push("/")}>Go back to main</button>
  </div>;
  
  const PasswordForm = <form className="action-form" onSubmit={(e)=>{
    e.preventDefault();
    updatePassword();
  }}>
    <div><label className="action-form__line">Email: <b>{email}</b></label></div>
    <div className="action-form__line">
      <input id="psw" type="password" placeholder="Enter new password" value={password} onChange={(e)=>{setPassword(e.target.value)}} required/>
    </div>
    <div className="action-form__line">
      <input id="pswCon" type="password" placeholder="Confirm new password" value={confirmPassword} onChange={(e)=>{setConfirmPassword(e.target.value)}} required/>
    </div>
    <span className="action-form__error">{errorMessage}</span>
    <button className="action-form__button update" type="submit" onClick={()=> updatePassword()}>Reset</button>
  </form>;

  return <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
  <Col>
    <div className="logo" align="middle">
      <a href="https://www.texta.ai/">
        <img src={logoUrl} alt="Texta.ai"/>
      </a>
    </div>
    <div>{form}</div>
  </Col>
</Row>
}

export default Action;

