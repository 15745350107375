import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import { toast } from "react-toastify";

// ant-design
import Layout from "antd/es/layout";
import Space from "antd/es/space";
import Text from "antd/es/typography/Text";
import Divider from "antd/es/divider";
import Button from "antd/es/button";
import {Slider} from "antd";
import {
  LogoutOutlined,
  UserOutlined,
  LoadingOutlined
} from "@ant-design/icons";
import Avatar from "antd/es/avatar/avatar";
import Image from "antd/es/image";

// local components
import Modal from "components/Modal";
import {ProfileDetails} from "components/ProfileDetails";
import {PricingCard} from "./PricingCard";

import app from "../../base";
import Logo from "../Logo";
import "./index.scss";
import { AuthContext } from "Auth";
import { createCheckoutSession } from "createCheckoutSession";
import { plansMonthly } from "./plans";
import { ReactComponent as CoinsIcon } from "assets/icons/coins.svg";
// import { ReactComponent as PlagirismCheckerIcon } from "assets/icons/scanner.svg";
// import { ReactComponent as SEOIcon } from "assets/icons/compas.svg";
const db = app.firestore();
const { Header} = Layout;
const coinPrices = {
  "250" : {price: 15, blogArticles: 50, words: "88 000",  id: "price_1KdDixEYRXygJOoD7R4o85JG"},
  "600" : {price: 25, blogArticles: 120, words: "210 000", id: "price_1KdDkQEYRXygJOoDYCLpfVZd"},
  "1000" : {price: 35, blogArticles: 200, words: "350 000", id: "price_1KdDlHEYRXygJOoDkZNCSKRW"},
  "2500" : {price: 59, blogArticles: 500, words: "880 000", id: "price_1KdDm1EYRXygJOoDoRnSkzML"}
}
// const wordsPrices = {
//   "30000" : {price: 30, id: "price_1KWHSVIyK88iiIsF8sAWcUme"},
//   "50000" : {price: 50, id: "price_1KWHTDIyK88iiIsFNuWA7Dhp"},
//   "70000" : {price: 70, id: "price_1KWHTgIyK88iiIsF1Q3sWbVs"},
// }
// const checksPrices = {
//   "50" : {price: 10, id: "price_1KU7PDIyK88iiIsFP9hF5Lxn"},
//   "100" : {price: 18, id: "price_1KU7QRIyK88iiIsFS2PGnzlC"},
//   "150" : {price: 25, id: "price_1KU7R1IyK88iiIsFocMsicUg"},
// }
// const checksProjects = {
//   "1" : {price: 10},
//   "2" : {price: 15},
//   "3" : {price: 18},
//   "4" : {price: 21},
//   "5" : {price: 24},
//   "6" : {price: 27},
//   "7" : {price: 30},
//   "8" : {price: 33},
//   "9" : {price: 36},
//   "10" : {price: 39}
// }

const BuySlider = ({ coinsRef, headline, marks, element, setElement, prices, icon, label, info, color, checkout, type, loading, buttonColor }) => {
  return (
    <>
      <div className="profile-body__row" ref={coinsRef}>
        <h2>{headline}</h2>
      </div>
      <div className={`profile-body__coins-wrapper${type === "checks" ? " checks" : (type === "projects" ? " projects" : "")}`}>
        <Slider marks={marks} step={null} tooltipVisible={false} 
          trackStyle={{backgroundColor : color}} 
          handleStyle={{borderColor: color}}
          onChange={(val)=>{setElement({position: val, amount: marks[val]});}}
          value={element.position}/>
        <div className="coin-details">
          <span className="coin">{element.amount}{label}</span>
          {info && <span>~{prices[element.amount].blogArticles} Blog articles ({prices[element.amount].words} words)</span>}
        </div>
        <div className="card-button-wrapper">
          <button onClick={()=>{
            if(type === "projects") {
              window.open(`https://mail.google.com/mail/?view=cm&source=mailto&su=Buy%20SEO%20projects&body=Number%20of%20projects%3A%20${element.amount}%0APrice%3A%20$${prices[element.amount].price}&to=arman@texta.ai`,'_blank');
            } else {
              checkout(type);
            }
          }} className={`card-button ${buttonColor ? buttonColor : ""}`}>Get for ${prices[element.amount].price} {loading && <LoadingOutlined />}</button>
        </div>
      </div>
    </>
  )
}

const Profile = (props) => {
  const { currentUser, name } = useContext(AuthContext);
  // useEffect(()=>{
  //   localStorage.setItem("email", currentUser.email);
  //   // eslint-disable-next-line
  // }, []);
  const [subscription, setSubscription] = useState(null);
  const [isWhiteUser, setIsWhiteUser] = useState(null);
  // const [typeWords, setTypeWords] = useState(false);
  const [page, setPage] = useState("plans");
  // const [plan, setPlan] = useState("monthly");
  const [coins, setCoins] = useState({amount: "250", position: 25});
  // const [words, setWords] = useState({amount: "30000", position: 33});
  // const [checks, setChecks] = useState({amount: "50", position: 33});
  // const [projects, setProjects] = useState({amount: "1", position: 10});
  const [showProfileDetails, setShowProfileDetails] = useState(false);
  const [coinsLoading, setCoinsLoading] = useState(false);
  // const [wordsLoading, setWordsLoading] = useState(false);
  // const [checksLoading, setChecksLoading] = useState(false);
  const plansRef = useRef();
  const coinsRef = useRef();
  const history = useHistory();
  useEffect(() => {
    const updateSubscription = async ()=>{
      const user = {};
      await db.collection("customers")
        .doc(currentUser.uid)
        .get()
        .then((doc)=>{
          if(doc.exists) {
            setIsWhiteUser(doc.data().is_white_user);
            // if(doc.data().type_words) {
            //   setTypeWords(doc.data().type_words);
            // }
            setSubscription({
              role: doc.data().sub_type
            })
            user.subscription = doc.data().sub_type;
          }
        })
      if (user.subscription && user.subscription !== "Lifetime Deal" && user.subscription !== "Free Trial") {
        await db.collection("customers")
        .doc(currentUser.uid)
        .collection("subscriptions")
        .get()
        .then((snapshot) => {
          snapshot.forEach((subscription) => {
            if (subscription.data()) {
              setSubscription({
                id: subscription.id,
                role: subscription.data().role,
                status: subscription.data().status,
                current_period_start: subscription
                  .data()
                  .current_period_start.toDate(),
                current_period_end: subscription
                  .data()
                  .current_period_end.toDate(),
                cancelled: subscription
                  .data()
                  .cancel_at_period_end,
              });
            }
          });
        });
      }
    }
    currentUser && updateSubscription();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if(props.location && props.location.state) {
      setPage(props.location.state.detail);
    }
    // eslint-disable-next-line
  }, []);

  const avatar = currentUser && currentUser.picture ? (
    <Avatar src={<Image src={currentUser.picture} />} />
  ) : (
    <Avatar icon={<UserOutlined />} />
  );

  const renderedPlansMonthly = plansMonthly.map((plan, index) =>{
    return <div key={"monthly"+index} className="site-card-wrapper__card"><PricingCard plan={plan} 
      subscription={subscription} isWhiteUser={isWhiteUser}
    /></div>
  });
  // const renderedPlansAnnual = plansAnnual.map((plan, index) =>{
  //   return <div key={"annual"+index}className="site-card-wrapper__card"><PricingCard plan={plan} 
  //     subscription={subscription} annual={true}
  //   /></div>
  // });
  const marksCoins = {
    25: '250',
    50: '600',
    75: "1000",
    100: "2500",
  };
  // const marksWords = {
  //   33: '30000',
  //   66: '50000',
  //   100: "70000",
  // };
  // const marksChecks = {
  //   33: '50',
  //   66: '100',
  //   100: "150",
  // };
  // const marksProjects = {
  //   10: '1',
  //   20: '2',
  //   30: '3',
  //   40: '4',
  //   50: '5',
  //   60: '6',
  //   70: '7',
  //   80: '8',
  //   90: '9',
  //   100: "10",
  // };
  useEffect(()=>{
    if (page === "coins") {
      window.scrollTo({left: 0, top: coinsRef.current.offsetTop, behavior: "smooth"});
    } else {
      window.scrollTo({left: 0, top: plansRef.current.offsetTop-100, behavior: "smooth"});
    }
  }, [page]);


  const checkout = (type)=> {
    if ((!subscription || subscription.status === "trialing") && (type === "coins" || type === "words")) {
      toast.error("You may buy credits only if you have active subscription not in the free trial period");
      return;
    }
    if(type === "coins") {
      setCoinsLoading(true);
      createCheckoutSession(currentUser.uid, coinPrices[coins.amount].id, "payment");
    }
    // if(type === "words") {
    //   setWordsLoading(true);
    //   createCheckoutSession(currentUser.uid, wordsPrices[words.amount].id, "payment");
    // }
    // if(type === "checks") {
    //   setChecksLoading(true);
    //   createCheckoutSession(currentUser.uid, checksPrices[checks.amount].id, "payment");
    // }
  }
  return (
    <div>
      <Header className="profile-header" style={{ padding: 0 }}>
        <div className="profile-header__wrapper">
          <Logo className="profile-header__logo" source={props.source}/>
          {props.source !== "login" &&
          <>
            <Link className="profile-header__navlink" to="/" >
              <Text>App</Text>
            </Link>
          
            <Link to="#plans" className="profile-header__navlink">
              <Text className={`${page === "plans" && "active"}`} onClick={()=>{setPage("plans")}}>Plans</Text>
            </Link>
            <Link to="#coins" className="profile-header__navlink nav-long" onClick={()=>{setPage("coins")}}>
              <Text className={`${page === "coins" && "active"}`}>Credits, Checks and SEO</Text>
            </Link>
          </>
          }
          <Divider type="vertical" />
          <Space className="profile-header__user">
            <Link to={`/${props.source === "login" ? "plans" : "profile"}`} onClick={()=> {setShowProfileDetails(true)}}>
              <Space>
                {avatar}
                <Text>{name}</Text>
              </Space>
            </Link>
            <Divider type="vertical" />
            <Button
              onClick={() => app.auth().signOut().then(()=>{history.push("/login")})}
              icon={<LogoutOutlined />}
            >
              Log out
            </Button>
          </Space>
        </div>
      </Header>
      <div className="profile-body">
        <div ref={plansRef} className="profile-body__row">
          <h2>Select a Plan</h2>
        </div>
        {/* <div className="profile-body__slider">
          <div 
            className={`profile-body__slider__month ${plan === "monthly" ? "active" : "inactive"}`}
            onClick={()=>{Mixpanel.track('Montly: Upgrade Plan'); setPlan("monthly")}}
          >
            <span>Monthly</span>
          </div>
          <div 
            className={`profile-body__slider__year ${plan === "annual" ? "active" : "inactive"}`}
            onClick={()=>{Mixpanel.track('Annual'); setPlan("annual");}}
          >
            <span>Annual</span>
          </div>
        </div> */}
        {/* {!isWhiteUser && <div className="profile-body__info">TRY OUT ONLY FOR $7 - 4000 words included</div>} */}
        <div className="profile-body__plans" id="plans">
          <div className="site-card-wrapper__block">
            {renderedPlansMonthly}
          </div>
        </div>
        <br/>

        {props.source !== "login" &&
        <>
          {/* {!typeWords ? */}
            <BuySlider
              coinsRef={coinsRef}
              headline={"Buy credits"}
              label={" coins"}
              marks={marksCoins}
              element={coins}
              setElement={setCoins}
              prices={coinPrices}
              icon={<CoinsIcon />}
              info={true}
              color={"rgb(43, 93, 238)"}
              checkout={checkout}
              type={"coins"}
              loading={coinsLoading}
            />
          {/* //   <BuySlider
          //     coinsRef={coinsRef}
          //     headline={"Buy words"}
          //     label={" words"}
          //     marks={marksWords}
          //     element={words}
          //     setElement={setWords}
          //     prices={wordsPrices}
          //     color={"#2B5DEE"}
          //     checkout={checkout}
          //     type={"words"}
          //     loading={wordsLoading}
          //   />
          // }
          // <BuySlider
          //   headline={"Buy plagiarism checks"}
          //   label={" articles checks"}
          //   marks={marksChecks}
          //   element={checks}
          //   setElement={setChecks}
          //   prices={checksPrices}
          //   icon={<PlagirismCheckerIcon className="coin__icon-green" />}
          //   color={"#00ac73"}
          //   checkout={checkout}
          //   type={"checks"}
          //   loading={checksLoading}
          //   buttonColor={"green"}
          // />
          // <BuySlider
          //   headline={"Buy SEO tools projects"}
          //   label={" SEO projects"}
          //   marks={marksProjects}
          //   element={projects}
          //   setElement={setProjects}
          //   prices={checksProjects}
          //   icon={<SEOIcon className="coin__icon-yellow" />}
          //   color={"#f59b25"}
          //   checkout={checkout}
          //   type={"projects"}
          //   buttonColor={"yellow"}
          // /> */}
        </>
        }
      </div>  
      {showProfileDetails &&
        <Modal 
          onClick={() => {setShowProfileDetails(false);}}
          colorOverflow={"rgba(49, 52, 58, 0.212)"}
        >
          <ProfileDetails user={{subscription, currentUser}}/>
        </Modal>
      }
    </div>
  );
};

export default Profile;
