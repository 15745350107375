import React, { useState, useContext, useEffect, createContext } from "react";
import { AuthContext } from "../../Auth";
import DashboardForm from "components/DashboardForm";
import Results from "components/Results";
import Header from "components/Header";
import Sidebar from "components/Sidebar";
import EditorPage from "components/LongTextGen";
import LongTextGen from "components/LongTextGen2";
import Tutorials from "components/Tutorials";
import BeastMode from "components/BeastMode";
import BeastMode2 from "components/BeastMode2"
import generatedText from "store/generatedText";
// import Modal from "components/Modal";
import { observer } from "mobx-react-lite";
import { specialCategories } from "store/categories";
import "./index.scss";
import app from '../../base';
import { toast } from "react-toastify";
import Questionnaire from "components/Questionnaire";
// import { Mixpanel } from "Mixpanel";
import DraftEditor from "components/DraftEditor";
// import Profile from "components/Profile";
import Row from "antd/es/row";
import Spin from "antd/es/spin";
import PlagiarismChecker from "components/PlagiarismChecker"
// import {TeamManagementPopup} from "components/TeamManagementPopup";
export const db = app.firestore();

export const TeamContext = createContext();
export default observer((props) => {
  const { currentUser } = useContext(AuthContext);
  const freeDays = 7 - Math.floor((Date.now()-parseInt(currentUser.metadata.a))/86400000);
  const [typeWords, setTypeWords] = useState(false);
  const [localWords, setLocalWords] = useState({planWords: 0, additionalWords: 0});
  const [teamWords, setTeamWords] = useState({planWords: 0, additionalWords: 0});
  const [localCoins, setLocalCoins] = useState({planCoins: 0, additionalCoins: 0});
  const [teamCoins, setTeamCoins] = useState({planCoins: 0, additionalCoins: 0});
  const [mode, setMode] = useState("personal");
  const [project, setProject] = useState(null);
  const [subscription, setSubscription] = useState(null);
  const [userLevel, setUserLevel] = useState(0);
  const [teamInfo, setTeamInfo] = useState(null);
  const [id, setId] = useState(currentUser.uid);
  const [showTeamForm, setShowTeamForm] = useState(false);
  // const [neverAskCheck, setNeverAsk] = useState(false);
  const [activeSpecialSection, setSpecialSection] = useState(
    specialCategories[0]?.uid
  );
  const [questionnaire, setQuestionnaire] = useState(false);
  const [plagiarismContent, setPlagiarismContent] = useState("");
  // const [isWhiteUser, setIsWhiteUser] = useState(null);
  useEffect(() => {
    if(props.location.state) {
      setSpecialSection(props.location.state.detail ? props.location.state.detail : activeSpecialSection);
      setPlagiarismContent(props.location.state.content ? props.location.state.content : "");
    }

    // async function showQuestionnaire(){
    //   const questionnaireRef = db
    //     .collection("questionnaire")
    //     .doc(currentUser.uid);
    //   await questionnaireRef.get().then(async (doc) => {
    //     if (!doc.exists) {
    //       setQuestionnaire(true);
    //     }
    //   }).catch((e) => {
    //     console.log(e);
    //   });
    // }
    // setTimeout(() => {
    //   showQuestionnaire();
    // }, 300000);
    
    // Mixpanel.identify(currentUser.email);

    // const usersRef = db
    //   .collection("customers")
    //   .doc(currentUser.uid);
    // usersRef.get().then(async (doc) => {
    //   if (doc.exists) {
    //     Mixpanel.people.set_once({
    //       '$email' : currentUser.email,
    //       'Position' : doc.data().position ? doc.data().position[0] : "",
    //       'Industry' : doc.data().industry ? doc.data().industry[0] : "",
    //       'Source' : doc.data().source ? doc.data().source : ""
    //     });
    //     // if(doc.data().coins.amount + doc.data().additional_coins.amount === 0 ) {
    //     //   toast.warn("You have no credits left!");
    //     // }
    //   }
    // }).catch((e) => {
    //   console.log(e);
    // });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const loadCoins = async (id) => {
    let coinsReturned, addCoinsReturned, wordsReturned, addWordsReturned;
    const customerRef = db
      .collection("customers")
      .doc(id);
    await customerRef.get().then(async (doc) => {
      if (doc.exists) {
        if(typeWords) {
          const {words, additional_words} = doc.data();
          if (id === currentUser.uid) {
            setLocalWords({
              planWords: words ? words.amount : 0, 
              additionalWords:  additional_words ? additional_words.amount : 0
            });
          } else {
            setTeamWords({
              planWords: words ? words.amount : 0, 
              additionalWords:  additional_words ? additional_words.amount : 0
            });
          }
          wordsReturned = (words && words.amount) || 0;
          addWordsReturned = (additional_words && additional_words.amount) || 0;
        } else {
          const {coins, additional_coins} = doc.data();
          if (id === currentUser.uid) {
            setLocalCoins({
              planCoins: coins ? coins.amount : 0, 
              additionalCoins: additional_coins ? additional_coins.amount : 0
            });
          } else {
            setTeamCoins({
              planCoins: coins ? coins.amount : 0, 
              additionalCoins: additional_coins ? additional_coins.amount : 0
            });
          }
          coinsReturned = (coins && coins.amount) || 0;
          addCoinsReturned = (additional_coins && additional_coins.amount) || 0;
        }
      }
    }).catch((e) => {
      console.log(e);
    });
    if(typeWords) {
      return {wordsReturned, addWordsReturned};
    }
    return {coinsReturned, addCoinsReturned};
  }
  useEffect(()=>{
    if (teamInfo && teamInfo.member_of_team) {
      const search = async () => {
        await loadCoins(teamInfo.owner_id);
      };
      search();
    } 
    if (teamInfo && !teamInfo.owns_team && !teamInfo.showed_team_invite) {
      setShowTeamForm(true);
    } // eslint-disable-next-line
  }, [teamInfo]);


  const submitQuestionnaire = async (input, status) => {
    const questionnaireRef = db
      .collection("questionnaire")
      .doc(currentUser.uid);
    await questionnaireRef.get().then(async (doc) => {
      if (!doc.exists) {
        await questionnaireRef.set({
          status: status,
          uid: currentUser.uid,
          email: currentUser.email,
          subscription: subscription,
          answer1: input[0].answer,
          answer2: input[1].answer,
          answer3: input[2].answer,
          answer4: input[3].answer,
        }).catch(e=> {
          console.log(e);
        });
      }
    }).catch((e) => {
      console.log(e);
    });
  }
  useEffect(()=>{
    let lvl = 0;
    switch (subscription) {
      case "Lifetime Deal":
        lvl = 5;
        break;
      case "Basic":
      case "Basic Annual":
        lvl = 1;
        break;
      case "Pro":
      case "Pro Annual":
        lvl = 2;
        break;
      case "Pro MAX":
      case "Pro MAX Annual":
        lvl = 3;
        break;
      case "Pro MAX Plus":
      case "Pro MAX Plus Annual":
        lvl = 4;
        break;
      default:
        lvl = 0;
    }
    setUserLevel(lvl);
  }, [subscription]);
  useEffect(() => {
    const search = async () => {
      const customerRef = db
        .collection("customers")
        .doc(currentUser.uid);
      customerRef.get().then(async (doc) => {
        if (doc.exists) {
          const {coins, additional_coins, type_words, words, additional_words, sub_type, member_of_team, member_team_id, owns_team, owns_team_id, showed_team_invite} = doc.data();
          // setIsWhiteUser(is_white_user);
          setSubscription(sub_type);
          if(type_words) {
            setTypeWords(type_words);
            setLocalWords({
              planWords: words ? words.amount : 0, 
              additionalWords:  additional_words ? additional_words.amount : 0
            });
          } else {
            setLocalCoins({
              planCoins: coins ? coins.amount : 0, 
              additionalCoins: additional_coins ? additional_coins.amount : 0
            });
          }
          if (member_of_team) {
            await db.collection("teams").doc(member_team_id).get().then(doc=>{
              if (doc.exists){
                const {owner_id} = doc.data();
                setTeamInfo({
                  member_of_team,
                  member_team_id,
                  owns_team,
                  owns_team_id,
                  owner_id,
                  showed_team_invite
                })
              }
            })
          } else {
            setTeamInfo({
              owns_team,
              owns_team_id,
              showed_team_invite
            })
          }
        }
      }).catch((e) => {
        console.log(e);
      });
    };
    search(); // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    const updateUserData = async()=>{
      await db.collection("customers").doc(currentUser.uid).update({showed_team_invite: true});
    }
    if (showTeamForm) {
      updateUserData();
    }
  }, [showTeamForm, currentUser]);

  // useEffect(()=>{
  //   const updateUserData = async()=>{
  //     await db.collection("customers").doc(currentUser.uid).update({never_show_team_invite: true});
  //   }
  //   if (neverAskCheck) {
  //     updateUserData();
  //   }
  // }, [neverAskCheck, currentUser]);
  
  useEffect(() => {
    const searchProject = async () => {
      const projectRef = db
        .collection("storage")
        .doc(currentUser.uid);
      projectRef.get().then(doc => {
        if (!doc.exists) {
          projectRef.set({email: currentUser.email}).then(
          projectRef.collection("projects").add({name: "Project 1", text: []}).then((snap)=> {
            setProject({name: "Project 1", id: snap.id});
            projectRef.update({currentProject: snap.id})
          }).catch(e=> {
            console.log(e);
          }))
        } else {
          projectRef.collection("projects").doc(doc.data().currentProject).get().then((snap)=> {
            setProject({name: snap.data().name, id: snap.id});
          })
        }
      }).catch((e) => {
        console.log(e);
      });
    };
    searchProject();
  }, [currentUser]);

  useEffect(() => {
    const lifetimeSet = async () => {
      const customerRef = db.collection("customers").doc(currentUser.uid);
      customerRef.get().then(async doc => {
        if (doc.exists) {
          const { coins } = doc.data();
          let today = new Date();
          if (coins.due_date.toDate().getFullYear() <= today.getFullYear() && 
              coins.due_date.toDate().getMonth() <= today.getMonth() &&
              coins.due_date.toDate().getDate() <= today.getDate()) {
            today.setMonth(today.getMonth() + 1);
            await customerRef.get().then(doc => {
              const {multiplier, multiplier_premium} = doc.data().coins;
              customerRef.update({  
                coins: {
                  amount: ((multiplier === undefined && multiplier_premium === undefined) && 1000) + 1000 * (multiplier ? multiplier : 0) + 1500 * (multiplier_premium ? multiplier_premium : 0),
                  due_date: today,
                  multiplier: multiplier ? multiplier : 0,
                  multiplier_premium: multiplier_premium ? multiplier_premium : 0,
                  sub_type: "Lifetime Deal",
                },
                sub_type: "Lifetime Deal",
              });
            })
            loadCoins(mode === "personal" ? currentUser.uid : teamInfo.owner_id);
          }
        }
      })
    };
    if (subscription === 'Lifetime Deal') {
      lifetimeSet();
    }// eslint-disable-next-line
  }, [currentUser, subscription]);

  const coinsReduce = async(amount) => {
    const docRef = db.collection("customers").doc(mode === "personal" ? currentUser.uid : teamInfo.owner_id);
    let reducePlan = 0;
    let reduceAdditional = 0;
    
    await docRef.get().then(doc => {
      if (doc.exists) {
        if(typeWords) {
          const {additional_words, words} = doc.data();
          if (mode === "personal") {
            reducePlan = Math.min(amount, localWords.planWords);
            reduceAdditional = amount - reducePlan;
            setLocalWords({
              planWords: localWords.planWords-reducePlan > 0 ? localWords.planWords-reducePlan : 0,
              additionalWords: localWords.additionalWords-reduceAdditional > 0 ? localWords.additionalWords-reduceAdditional : 0,
            });
          } else {
            reducePlan = Math.min(amount, teamWords.planWords);
            reduceAdditional = amount - reducePlan;
            setTeamWords({
              planWords: localWords.planWords-reducePlan > 0 ? localWords.planWords-reducePlan : 0,
              additionalWords: localWords.additionalWords-reduceAdditional > 0 ? localWords.additionalWords-reduceAdditional : 0,
            });
          }
          if (reducePlan) {
            docRef.update({
              words: {
                ...words,
                amount: words.amount - reducePlan > 0 ? words.amount - reducePlan : 0
              }
            });
          }
          if (reduceAdditional) {
            docRef.update({
              additional_words: {
                ...additional_words,
                amount: additional_words.amount - reduceAdditional
              }
            });
          }
        } else {
          const {additional_coins, coins} = doc.data();
          if (mode === "personal") {
            reducePlan = Math.min(amount, localCoins.planCoins);
            reduceAdditional = amount - reducePlan;
            setLocalCoins({
              planCoins: localCoins.planCoins-reducePlan > 0 ? localCoins.planCoins-reducePlan : 0,
              additionalCoins: localCoins.additionalCoins-reduceAdditional > 0 ? localCoins.additionalCoins-reduceAdditional : 0,
            });
          } else {
            reducePlan = Math.min(amount, teamCoins.planCoins);
            reduceAdditional = amount - reducePlan;
            setTeamCoins({
              teamCoins: teamCoins.planCoins-reducePlan > 0 ? teamCoins.planCoins-reducePlan : 0,
              additionalCoins: teamCoins.additionalCoins-reduceAdditional > 0 ? teamCoins.additionalCoins-reduceAdditional : 0,
            });
          }
          if (reducePlan) {
            docRef.update({
              coins: {
                ...coins,
                amount: coins.amount - reducePlan > 0 ? coins.amount - reducePlan : 0
              }
            });
          }
          if (reduceAdditional) {
            docRef.update({
              additional_coins: {
                ...additional_coins,
                amount: additional_coins.amount - reduceAdditional > 0 ? additional_coins.amount - reduceAdditional : 0
              }
            });
          }
        }
      }

    }).catch(e => {
      console.log(e);
    })
  }

  const saveText = async(name, content, input) => {
    const projectRef = db.collection("storage").doc(id).collection("projects").doc(project ? project.id: "");
    await projectRef.get().then((project)=>{
      if (project.exists) {
        projectRef.update({text: [...project.data().text, {name, content, input}]})
        toast.success(`Saved in ${project.data().name}!`);
      }
    }).catch(e => {
      console.log(e);
    });
  }
  
  function onChangeSection(params) {
    generatedText.setSelectedTool(params);
    setSpecialSection("");
  }

  function onChangeSpecialSection(uid) {
    generatedText.setSpecialSelectedTool(uid);
    setSpecialSection(uid);
  }

  function toggleCategory(category) {
    generatedText.toggleToolCategory(category);
  }

  function getActiveSpecialSectionProps(uid) {
    const props = specialCategories.filter((item) => item.uid === uid);

    if (!props.length) {
      return undefined;
    }

    return props[0];
  }

  function getTitle() {
    if (activeSpecialSection) {
      return getActiveSpecialSectionProps(activeSpecialSection)?.title;
    }

    return generatedText.selectedToolProperties.toolName;
  }
  function getDescription() {
    if (activeSpecialSection) {
      return getActiveSpecialSectionProps(activeSpecialSection)?.description;
    }

    return generatedText.selectedToolProperties?.aboutTool;
  }

  function getActiveContent(activeSpecialSection) {
    switch (activeSpecialSection) {
      case "clipboard":
        return <Results results={[]} loading={true} />;
      case "long-text-gen":
        return <EditorPage 
          freeDays={freeDays} 
          subscription={subscription} 
          uid={currentUser.uid}
          coins={localCoins.planCoins + localCoins.additionalCoins}
          typeWords={typeWords}
          words={localWords.planWords + localWords.additionalWords}
          teamWords={teamWords.planWords + teamWords.additionalWords}
          coinsReduce={coinsReduce} 
          mode={mode}
          teamInfo={teamInfo}
          teamCoins={teamCoins.planCoins + teamCoins.additionalCoins}
          loadCoins={loadCoins}
          save={saveText}
          onChangeSpecialSection={onChangeSpecialSection}
          setPlagiarismContent={setPlagiarismContent}
          />;
      case "long-text-gen-2.0":
        return <LongTextGen 
          freeDays={freeDays} 
          subscription={subscription} 
          uid={currentUser.uid}
          coins={localCoins.planCoins + localCoins.additionalCoins} 
          typeWords={typeWords}
          words={localWords.planWords + localWords.additionalWords}
          teamWords={teamWords.planWords + teamWords.additionalWords}
          coinsReduce={coinsReduce} 
          save={saveText}
          onChangeSpecialSection={onChangeSpecialSection}
          setPlagiarismContent={setPlagiarismContent}
          mode={mode}
          teamInfo={teamInfo}
          teamCoins={teamCoins.planCoins + teamCoins.additionalCoins}
          loadCoins={loadCoins}
          />;  
      case "editor":
        return <DraftEditor 
          coins={localCoins.planCoins + localCoins.additionalCoins} 
          typeWords={typeWords}
          words={localWords.planWords + localWords.additionalWords}
          teamWords={teamWords.planWords + teamWords.additionalWords}
          coinsReduce={coinsReduce}
          userLevel={userLevel} 
          onChangeSpecialSection={onChangeSpecialSection}
          setPlagiarismContent={setPlagiarismContent}
          source="editor"
          mode={mode}
          teamInfo={teamInfo}
          save={saveText}/>; 
      case "plagiarism":
        return <PlagiarismChecker 
          coins={localCoins.planCoins + localCoins.additionalCoins} 
          typeWords={typeWords}
          words={localWords.planWords + localWords.additionalWords}
          teamWords={teamWords.planWords + teamWords.additionalWords}
          coinsReduce={coinsReduce}
          userLevel={userLevel}
          content={plagiarismContent}
          setContent={setPlagiarismContent}
          onChangeSpecialSection={onChangeSpecialSection}
          source="plagiarism"
          save={saveText}/>; 
      case "beast-mode":
        return <BeastMode 
          freeDays={freeDays} 
          subscription={subscription} 
          uid={currentUser.uid}
          coins={localCoins.planCoins + localCoins.additionalCoins} 
          typeWords={typeWords}
          words={localWords.planWords + localWords.additionalWords}
          teamWords={teamWords.planWords + teamWords.additionalWords}
          coinsReduce={coinsReduce}
          onChangeSpecialSection={onChangeSpecialSection}
          setPlagiarismContent={setPlagiarismContent}
          userLevel={userLevel} 
          source="beast-mode"
          mode={mode}
          teamInfo={teamInfo}
          teamCoins={teamCoins.planCoins + teamCoins.additionalCoins}
          loadCoins={loadCoins}
          save={saveText}/>; 
      case "beast-mode-2.0":
        return <BeastMode2 
          freeDays={freeDays} 
          subscription={subscription} 
          uid={currentUser.uid}
          coins={localCoins.planCoins + localCoins.additionalCoins} 
          typeWords={typeWords}
          words={localWords.planWords + localWords.additionalWords}
          teamWords={teamWords.planWords + teamWords.additionalWords}
          coinsReduce={coinsReduce}
          onChangeSpecialSection={onChangeSpecialSection}
          setPlagiarismContent={setPlagiarismContent}
          userLevel={userLevel} 
          source="beast-mode"
          mode={mode}
          teamInfo={teamInfo}
          teamCoins={teamCoins.planCoins + teamCoins.additionalCoins}
          loadCoins={loadCoins}
          save={saveText}/>; 
      case "":
        return <DashboardForm 
          freeDays={freeDays} 
          subscription={subscription} 
          coins={localCoins.planCoins + localCoins.additionalCoins} 
          typeWords={typeWords}
          words={localWords.planWords + localWords.additionalWords}
          teamWords={teamWords.planWords + teamWords.additionalWords}
          coinsReduce={coinsReduce} 
          loadCoins={loadCoins}
          mode={mode}
          teamInfo={teamInfo}
          teamCoins={teamCoins.planCoins + teamCoins.additionalCoins}
          save={saveText}/>;
      case "tutorials":
        return <Tutorials />;
      default:
        return null;
    }
  }
  const renderPage = ()=>{
    if(subscription === null) {
      return <Row
        className='spin'
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
      >
        <Spin tip="Loading..."/>
      </Row>
    } else {
        return <TeamContext.Provider
          value={{teamInfo, mode, id, setId, project, setProject}}
        >
        <div className="dashboard">
          <Sidebar
            className="dashboard__sidebar"
            onChangeSection={onChangeSection}
            onChangeSpecialSection={onChangeSpecialSection}
            toggleCategory={toggleCategory}
            activeSpecialSection={activeSpecialSection}
            selectedProps={generatedText.selectedToolProperties}
            subscription={subscription}
            typeWords={typeWords}
            uid={currentUser.uid}
          />
          <div className="dashboard__hider" />
          <Header
            mode={mode}
            teamInfo={teamInfo}
            setMode={setMode}
            title={getTitle()}
            description={getDescription()}
            freeDays={freeDays}
            subscription={subscription}
            coins={localCoins.planCoins + localCoins.additionalCoins}
            teamCoins={teamCoins.planCoins + teamCoins.additionalCoins}
            typeWords={typeWords}
            words={localWords.planWords + localWords.additionalWords}
            teamWords={teamWords.planWords + teamWords.additionalWords}
            project={project}
            className="dashboard__header"
          />
          <div className="dashboard__content">
            {getActiveContent(activeSpecialSection)}
          </div>
          {questionnaire ?
            <Questionnaire 
              onClick={()=>{setQuestionnaire(false)}}
              handleSubmit={submitQuestionnaire}
            >
            </Questionnaire>
          : null}
          {/* {showTeamForm && 
            <Modal 
              onClick={() => {setShowTeamForm(false)}}
              colorOverflow={"rgba(49, 52, 58, 0.212)"}
            >
              <TeamManagementPopup user={{subscription, currentUser}} neverAskCheck={neverAskCheck} setNeverAsk={setNeverAsk}/>
            </Modal>} */}
        </div>
      </TeamContext.Provider>
    }
  }
  return (renderPage());
});
