import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";
import { AuthContext } from "../Auth";
const PrivateRoute = ({ component: RouteComponent, source, ...rest }) => {
  const {currentUser} = useContext(AuthContext);
  // let via = window.location.search.substring(1);
  // via = via.split("=")[1];
  // localStorage.setItem("affiliate", via || localStorage.getItem("affiliate"));
  return (
    <Route
      {...rest}
      render={routeProps =>
        !!currentUser ? (
          <RouteComponent {...routeProps} source={source} />
        ) : (
          <Redirect to={{pathname: "/login", state: {from: routeProps.location}}} />
        )
      }
    />
  );
};

export default PrivateRoute