import React, {useState, useContext} from 'react';
import firebase from 'firebase';
import app from '../../base';
import { SignInContext } from './Login';
export const db = app.firestore();

export const VerifiedEmailButton = ({setLoading, setCurrentStep}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const {setIsSignedIn} = useContext(SignInContext);
  const checkEmailVerification = async()=>{
    setLoading(true);
    await firebase.auth().currentUser.reload().then(() => {
      if (firebase.auth().currentUser.emailVerified) {
        db.collection("customers").doc(firebase.auth().currentUser.uid).get().then(userRef =>{
          if (userRef.exists && (userRef.data().reg_answer4 || userRef.data().source)) {
            setLoading(false);
            setIsSignedIn(true);
          } else {
            setLoading(false);
            setCurrentStep("reg-form");
          } 
        })
      } else {
        setErrorMessage("Email not verified yet");
      }
    }).catch(e => {
      setErrorMessage("Internal error");
    }).finally(()=>{
      setLoading(false);
    })
  }
  return <form className='login-form' onSubmit={(e)=>{
  e.preventDefault();
  checkEmailVerification();
}}>
  <h3>Confirm your account</h3>
  <label>Please, check your inbox. You should have received an email with a confirmation link.</label>
  <button className='login-form__button blue' type="submit" >Click after email verification</button>
  <span className='login-form__error'>{errorMessage}</span>
</form>
};