import React, { Component } from "react";
import { EditorState, ContentState, Modifier, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import DraftPasteProcessor from 'draft-js/lib/DraftPasteProcessor';
import {stateToHTML} from 'draft-js-export-html';
import { createText } from "fireFunctions";
import GeneratedText from "store/generatedText";
import LongTextGenPanel from "components/LongTextGenPanel";
import Modal from "components/Modal";
import {EditorInstructions} from "./EditorInstructions";
import { ReactComponent as RegenerateIcon} from "assets/icons/regenerate.svg";
import "./index.scss";
import { toast } from "react-toastify";
import ProjectManagement from "components/ProjectManagement";
class DraftEditor extends Component {
  constructor(props) {
    super(props);
    const longTextString = this.props.source === "beast-mode" ? localStorage.getItem("longTextString") : localStorage.getItem("shortTextString");
    if (longTextString) {
      const processed = DraftPasteProcessor.processHTML(longTextString);
      this.state = {
        editorState: EditorState.createWithContent(ContentState.createFromBlockArray(processed)),
        loading: false,
      };
    } else {
      this.state = {
        editorState: EditorState.createWithContent(ContentState.createFromText("")),
        loading: false,
        showInstructions: false
      };
    }
  }

  componentDidMount() {
    this.focusEditor();
  }

  setEditor = (editor) => {
    this.editor = editor;
  };

  focusEditor = () => {
    if (this.editor) {
      this.editor.focusEditor();
    }
  };
  save = (textType, content, input)=>{
    const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks;
    const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join('\n');
    if (this.props.source === "beast-mode") {
      this.props.save("Long Text Edited", 
        value,
        {...input,
          tag: this.props.tag || "standard"
        }
      )
    } else {
      this.props.save("Short Text Edited", 
        value,
        {}
      )
    }
  }

  sendToPlagiarism = () => {
    const blocks = convertToRaw(this.state.editorState.getCurrentContent()).blocks;
    const value = blocks.map(block => (!block.text.trim() && '\n') || block.text).join(' ');
    if(value !== "\n") {
      this.props.setPlagiarismContent(value);
      this.props.onChangeSpecialSection("plagiarism");
    } else {
      toast.error("Cannot proceed empty input!");
    }
  }

  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
  };

  sendTextToEditor = (text) => {
    this.setState({
      editorState: this.insertText(text, this.state.editorState),
    });
    this.focusEditor();
  };
  saveInStorage = () => {
    localStorage.setItem(this.props.source === "beast-mode" ? "longTextString" : "shortTextString", 
      stateToHTML(this.state.editorState.getCurrentContent()).toString()
    );
    toast.success("Saved in local storage. Now you won't lose your text after page refresh.");
  }
  autoSave = (content, input) => {
    localStorage.setItem(this.props.source === "beast-mode" ? "longTextString" : "shortTextString", 
      stateToHTML(this.state.editorState.getCurrentContent()).toString()
    );
  }

  generateNewText = async() => {
    const {mode, teamInfo, loadCoins, teamCoins, coins, uid, words, teamWords, typeWords} = this.props;
    let length;
    switch (GeneratedText.longTextResponseLength) {
      case "small":
        length = 75;
        break;
      case "large":
        length = 125;
        break;
      default:
        length = 100;
    }
    if ((((mode === "personal" && coins < 2) || (mode === "team" && teamCoins < 2)) && !typeWords) ||
      (((mode === "personal" && words < length) || (mode === "team" && teamWords < length)) && typeWords)) {
      toast.error("Insufficient balance");
      return;
    }
    if(typeWords) {
      const {wordsReturned, addWordsReturned} = await loadCoins(mode === "personal" ? uid : teamInfo.owner_id);
      if (wordsReturned + addWordsReturned < length) {
        toast.error("Insufficient balance ");
        return;
      }
    } else {
      const {coinsReturned, addCoinsReturned} = await loadCoins(mode === "personal" ? uid : teamInfo.owner_id);
      if (coinsReturned + addCoinsReturned < 2) {
        toast.error("Insufficient balance");
        return;
      }
    }
    this.setState({ loading: true });
    const currentContent = this.state.editorState.getCurrentContent();
    const currentBlockKey = this.state.editorState.getSelection().getStartKey();
    const currentBlockIndex = this.state.editorState
      .getCurrentContent()
      .getBlockMap()
      .keySeq()
      .findIndex((k) => k === currentBlockKey);
    const blocks = convertToRaw(currentContent).blocks.slice(
      0,
      currentBlockIndex + 1
    );
    let textBefore = blocks
      .map((block) => (!block.text.trim() && "\n") || block.text)
      .join("\n");
    const allSections = [...textBefore.matchAll(/[0-9]\..*\n/g)];
    const currentSectionData = allSections && allSections.pop();
    let currentSection = "", offset = 0;
    if (currentSectionData) {
      currentSection = currentSectionData[0].slice(0, -1);
      offset = currentSectionData.index + currentSection.length+1;
    } 
    textBefore = textBefore.slice(offset).trim();
    const toolName = textBefore ? "Beast Mode: continuation" : "Beast Mode: generation";
    const data = {
      toolName,
      hasName: true,
      hasDescription: true,
      name: GeneratedText.name,
      userText: textBefore,
      length: GeneratedText.longTextResponseLength,
      description: GeneratedText.description,
      keywords: GeneratedText.keywords,
      subtopic: currentSection || GeneratedText.name,
    };
    createText(data)
      .then((result) => {
        if (result.data.generatedText.length > 0) {
          const newText = result.data.generatedText[0]["text"];
          if (newText) {
            const wordsCounter = newText.split(/\n| /).filter(subtext => subtext.trim() !== "").length;
            this.props.coinsReduce(typeWords ? wordsCounter : 2);
            this.sendTextToEditor(newText);
          }
        } 
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => this.setState({ loading: false }));
  };

  insertText = (text, editorState) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );
    const newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };

  render() {
    const { editorState } = this.state;
    return (
      <div className="long-text-gen">
        <div className="long-text-gen__editor">
          { !this.state.showInstructions && 
            <div className="guide">
            {/* <span
              onClick={()=>{this.sendToPlagiarism();}}
              className="save green-button"
              title="Send to Plagiarism Checker"
            >
              Check Plagiarism
            </span> */}
             {this.props.source === "beast-mode" ? 
             <>
              <span className="guide__icon" title="Reset"
                onClick={()=>{this.props.setGenerateArticle(false);}}
              ><RegenerateIcon/></span>
              <span className="guide__sign" title="Instructions"
                 onClick={()=>{this.setState({showInstructions: true});}}
              >?</span>
              </> : 
              <>
              <span
                onClick={()=>{this.saveInStorage();}}
                className="save"
                title="Save in editor"
              >Save</span>
              </>
             }
            </div>
          }
          <div className="long-text-gen__editor-block">
            {this.props.source === "beast-mode" ? 
              <ProjectManagement 
                save={this.save}
                horizontal={true}
                source={true}
              />
            : null}
          </div>
          <Editor
            ref={this.setEditor}
            editorState={editorState}
            editorStyle={{ fontFamily: "SF Pro Display", fontSize: "15px", lineHeight: "157%" }}
            // toolbarCustomButtons={[
            //   <input className='tinput' placeholder="Title"></input>
            // ]}
            toolbar={{
              options: ["inline", "blockType", "history"],
              inline: {
                options: ["bold", "italic", "underline", "strikethrough"],
              },
              blockType: {
                inDropdown: false,
                options: ["Normal", "H1", "H2"],
              },
            }}
            wrapperClassName="layout-content-block long-text-gen__block editor"
            toolbarClassName={`editor__toolbar${this.props.source === "beast-mode" ? " extended":""}`}
            editorClassName="editor__content"
            placeholder={this.props.source === "beast-mode" ?`Insert the Article Outline first
            
Example:
1. Introduction
2. Content Marketing Tools
3. Three Types of Content Marketing
4. Social Media's Role in Content Marketing
5. The Benefits of Social Media Advertising for Startups
6. Conclusion` : "1. Introduction..."}
            onEditorStateChange={this.onEditorStateChange}
            onBlur={()=>{
              this.autoSave();
            }}
          />
        </div>

        <LongTextGenPanel
          className="layout-content-block long-text-gen__block long-text-gen__panel"
          generateContent={this.generateNewText}
          saveInStorage={this.saveInStorage}
          loading={this.state.loading}
          save={this.save}
          typeWords={this.props.typeWords}
          userLevel={this.props.userLevel}
          source={this.props.source}
        />
        {this.state.showInstructions &&         
          <Modal 
            onClick={() => {this.setState({showInstructions:false});}}
            colorOverflow={"rgba(49, 52, 58, 0.212)"}
          ><EditorInstructions/>
          </Modal>}
      </div>
    );
  }
}

export default DraftEditor;