import React, { useState, useMemo, useContext } from "react";
import { AuthContext } from "../../Auth";
import app from "../../base";
// import Image from "antd/es/image";
// import Avatar from "antd/es/avatar/avatar";
import { Link } from "react-router-dom";
import { ReactComponent as LikeIcon } from "assets/icons/like.svg";
import { ReactComponent as MoreIcon } from "assets/icons/more.svg";
import { ReactComponent as ClockIcon } from "assets/icons/clock.svg";
import { ReactComponent as DiamondIcon } from "assets/icons/diamond.svg";
import { ReactComponent as ProjectIcon } from "assets/icons/project.svg";
import { ReactComponent as LogoutIcon } from "assets/icons/logout.svg";
// import { ReactComponent as CoinsIcon } from "assets/icons/coins.svg";
import { ReactComponent as SettingsIcon } from "assets/icons/settings.svg";
// import { ReactComponent as CreditCardIcon } from "assets/icons/credit-card.svg";
import "./index.scss";
// import { UserOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
const ProfileMenu = ({ freeDays, subscription, project, typeWords }) => {
  const history = useHistory();
  const [show, toggle] = useState(false);
  const eventHandlers = useMemo(
    () => ({
      onBlur: () => toggle(false),
    }),
    []
  );

  return (
    <div className="profile-menu" tabIndex="-1" {...eventHandlers}>
      <div
        className={`profile-menu__toggle toggle ${show ? "_active" : ""}`}
        onClick={() => {
          toggle(!show);}}
      >
        <MoreIcon className="toggle__icon" />
      </div>

      {show && (
            <div className="profile-menu__menu menu">
        <div className="menu__list">
          {subscription === "Free Trial" &&
          (<div className="item days">
            <ClockIcon className="item__icon" />
            <span className="item__text">Free Trial Days</span>
            <span className="item__text _bubble">{Math.max(freeDays, 0)}</span>
          </div>)}
          <div className="item plan">
            <DiamondIcon className="item__icon" />
            <span className="item__text">Your plan</span>
            <span className="item__text _highlight">{subscription}</span>
          </div>
          <div className="item projects" onClick={()=>{history.push("/projects");}}>
            <ProjectIcon className="item__icon_project" />
            <span className="item__text">Project   </span>
            <span className="item__text _highlight">
              {project && 
              // project.name
              "Saved Texts"}
            </span>
          </div>
          {/* {subscription !== "Free Trial" ?
            <div className="item projects"
              onClick={()=>{
                Mixpanel.track('Buy Credits'); 
                history.push({
                  pathname: "/profile",
                  state: { detail: "coins" }
                }
            )}}>
              <CreditCardIcon className="item__icon_credit_card" />
              <span className="item__text">Buy credits</span>
            </div>
          : null} */}
          {/* <div className="item projects" onClick={()=>{
            Mixpanel.track('Earn Credits');
            // history.push("/earn-credits");
            history.push({
              pathname: "/earn-credits",
              state: { detail: typeWords }
            });
          }}>
            <CoinsIcon className="item__icon_project"/>
            <span className="item__text">Earn credits</span>
          </div> */}
          <div className="item projects" onClick={()=>{history.push("/personal-profile");}}>
            <SettingsIcon className="item__icon"/>
            <span className="item__text">Personal Profile</span>
          </div>
            <div className="item logout" onClick={() => app.auth().signOut().then(()=>{history.push("/login");})}>
              <LogoutIcon className="item__icon logout__icon" />
              <span className="item__text logout__text">Log out</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const Header = ({
  className,
  title,
  freeDays,
  coins,
  typeWords,
  words,
  teamWords,
  description,
  subscription,
  project, 
  mode, 
  setMode,
  teamInfo,
  teamCoins
}) => {
  const { name } = useContext(AuthContext);
  // const avatar = currentUser.picture ? (
  //   <Avatar src={<Image src={currentUser.picture} />} />
  // ) : (
  //   <Avatar icon={<UserOutlined />} />
  // );
  return (
    <header className={`${className} layout-content-block header`}>
      <div className="header__title title">
        <h1 className="title__text">{title}</h1>
        {description ? (
          <div className="title__question question">
            ? <p className="question__description">{description}</p>
          </div>
        ) : null}
      </div>
      {/* <div className="header__affiliate-button affiliate-button" onClick={()=>Mixpanel.track('Join our Affiliate Program: App')}>
        <a href="https://texta.getrewardful.com/signup" target="_blank" rel="noopener noreferrer" className="affiliate-button__link-block">
          <span className="affiliate-button__link-block__highlighted">Join our </span>Affiliate Program
        </a>
      </div> */}
      <Link to="/profile" className="header__plan-button plan-button" >
        <span className="plan-button__text">
          <span className="plan-button__text _highlighted">{"Upgrade "}</span>
          Plan
        </span>
        <LikeIcon className="plan-button__icon" />
      </Link>
      <div className="header__profile profile">
        {/* {typeWords || <CoinsIcon className="profile__coins_icon"/>} */}
        <div className="profile__coins">
          <div>
            <div className="coin-text">{typeWords ? (mode === "personal" ? words : teamWords)+" words" : (mode === "personal" ? coins : teamCoins)+" credits left"}</div>
            {/* <span className="small-text"> left this month</span> */}
          </div>
          {teamInfo && teamInfo.member_of_team && 
            <div className="coins-mode-wrapper">
              <div className="coins-mode">
                <span className={mode === "personal" ? "active" : ""} onClick={()=>{
                  if (mode === "team") {
                    setMode("personal");
                  }
                }}>Personal</span>
                <span className={mode === "team" ? "active" : ""} onClick={()=>{
                  if (mode === "personal") {
                    setMode("team");
                  }
                }}>Team</span>
              </div>
            </div>
          }
        </div>
        <div className="profile__fullname">{name}</div>
        {/* <div className="profile__avatar">{avatar}</div> */}
        <ProfileMenu freeDays={freeDays} subscription={subscription} project={project} typeWords={typeWords} />
      </div>
    </header>
  );
};

export default Header;
