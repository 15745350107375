import { ReactComponent as MoreIcon } from "assets/icons/add.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy.svg";
import { ReactComponent as HeartIcon } from "assets/icons/heart.svg";
import Button from "components/Button";
import ResultsLoader from "components/ResultsLoader";
import { toast } from "react-toastify";
import "./styles.scss";
import ProjectManagement from "components/ProjectManagement";

const Results = ({ className, results, input, generateText, loading, save, toolName }) => {
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast("Copied");
  };
  return (
    <div className={`layout-content-block ${className} result`}>
      <div className="result__header">
        <div className="result__title">Choose your favorite results</div>

        {generateText ? (
          <Button
            onClick={()=>{generateText();}}
            IconEl={MoreIcon}
            className="result__more-btn"
          >
            Make More
          </Button>
        ) : null}
        <ProjectManagement shortText={true} horizontal={true}/>
      </div>

      <div className="result__content">
        {loading ? <ResultsLoader /> : null}
        {results.map((item) => (
          <div className="result__row" key={item.index}>
            <div className="result__text">{item.text}</div>
            <Button
              onClick={() => {copyToClipboard(item.text);}}
              className="result__action-btn"
            >
              <CopyIcon />
            </Button>
            <Button className="result__action-btn _save"  onClick={()=>{save(toolName, item.text, input);}}>
              <HeartIcon className="result__action-icon" />
            </Button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Results;
