import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { ReactComponent as MoreIcon } from "assets/icons/more.svg";
import { ReactComponent as WordIcon } from "assets/icons/word.svg";
import { ReactComponent as CopyIcon } from "assets/icons/copy2.svg";
// import { ReactComponent as PlagirismCheckerIcon } from "assets/icons/scanner.svg";
import { useEffect, useState, useRef } from "react";
import { toast } from "react-toastify";
import { Document, Packer, Paragraph } from 'docx'
import { saveAs } from 'file-saver'
import { useHistory } from "react-router-dom";
// const InputBlock = ({label, content}) => {
//   return (
//     <div>
//       <h1>{label}</h1>
//       <p>{content}</p>
//     </div>
//   )
// }

const Text = ({name, content, input, index, search, deleteText}) => {
  const history = useHistory();
  const ref = useRef();
  useEffect(() => {
    const onBodyClick = (event) => {
      if (ref.current.contains(event.target)) {
        return;
      }
      setShowMenu(false);
    };
    document.body.addEventListener("click", onBodyClick, { capture: true });
 
    return () => {
      document.body.removeEventListener("click", onBodyClick, {
        capture: true,
      });
    };
  }, []);
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    toast.success("Copied");
  };
  const saveDocumentToFile =(doc, fileName)=> {
    // Create a mime type that will associate the new file with Microsoft Word
    const mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    // Create a Blob object from Packer containing the Document instance and the mimeType
    Packer.toBlob(doc).then((blob) => {
      const docblob = blob.slice(0, blob.size, mimeType)
      // Save the file using saveAs from the file-saver package
      saveAs(docblob, fileName)
    })
  }
  const generateWordDocument =(event) =>{
    event.preventDefault();
    const subsections = copyText.split("\n").map(txt => new Paragraph({text: txt}));
    let doc = new Document({
      sections: [
        {
          children: subsections,
        },
      ],
    })
    saveDocumentToFile(doc, 'New Document.docx')
  }
  // const sendToPlagiarism = () => {
  //   let resultText = "";
  //   if(name === "Long Text Edited") {
  //     resultText = content
  //   } else {
  //     content.map(({ text, outline }) => {
  //       resultText += text;
  //       return resultText;
  //     });
  //   }
  //   console.log(resultText);
  //   if(resultText !== "\n") {
  //     history.push({
  //       pathname: "/",
  //       state: { detail: "plagiarism", content: resultText }
  //     });
  //   } else {
  //     toast.error("Cannot proceed empty input!");
  //   }
  // }

  const copyToEditor = () => {
    let resultText = "";
    if(name !== "Long Text") {
      content.split("\n").forEach((line) => {
        resultText += `<p>${line}</p>`;
      });
    } else {
      content.forEach(({ text, outline }) => {
        resultText += `<h3>${outline}</h3>`;
        text.split("\n").forEach((line) => {
          resultText += `<p>${line}</p>`;
        });
      });
    }
    localStorage.setItem("shortTextString", resultText);
    history.push({
      pathname: "/",
      state: { detail: "editor" }
    });
  }
  // let text;
  // let outline;
  // const [isExpanded, setIsExpanded] = useState(false);
  const [showMenu, setShowMenu]= useState(false);
  const [copyText] = useState("");
  // useEffect(()=>{
  //   let localText = "";
  //   if (name === "Long Text") {
  //     content.forEach(({outline, text}) => {
  //       localText += outline + "\n";
  //       localText += text + "\n\n";
  //     })
  //   } else {
  //     localText = content;
  //   }
  //   setText(localText); // eslint-disable-next-line
  // }, []);
  // if (name === "Long Text") {
  //   text = content.map(({outline, text}, ind) => {
  //     return <div key={"div"+ind}><h1>{outline}</h1><p>{text}</p></div>;
  //   })
  //   // eslint-disable-next-line
  //   outline = content.map(({outline}, ind) => {
  //     return <span key={"p"+ind}>{outline}</span>;
  //   })
  // } else {
  //   text = content.split("\n").filter((line)=> line.length > 0).map((line, index) => {
  //     return (<p key={"p"+index}>{ line } <br/></p>)
  //   });
  // }
  return <div className={`text-block ${!name.includes(search) && "invisible"}`} key={index}>
    <div className="tool">
      <div className="tool-name"><h3 className="input-name" onClick={()=>{copyToEditor();}}>{name}</h3></div>
      {/* <div className="expand" onClick={()=>{setIsExpanded(!isExpanded);}}>
        {isExpanded ? "Hide" : "Expand"}
      </div> */}
      <div className="more" ref={ref} onClick={()=>{
        setShowMenu(!showMenu)}}>
      <MoreIcon className="more__icon"/>
      {showMenu && 
      <div className="menu">
        {/* {name === "Long Text" || name === "Long Text Edited" ?
          <div className="menu-item">
            <PlagirismCheckerIcon />
            <span onClick={()=>{sendToPlagiarism()}}>Check for plagiarism</span>
          </div>
        : null} */}
        <div className="menu-item">
          <WordIcon/>
          <span onClick={(e)=>{generateWordDocument(e)}}>Download in Word</span>
        </div>
        {/* <div className="menu-item">
          <EditorIcon/>
          <span onClick={()=>{
            copyToEditor()}
          }>
            Copy to editor
          </span>
        </div> */}
        <div className="menu-item">
          <CopyIcon/>
          <span onClick={()=>{
            copyToClipboard(copyText);
          }}>Copy content</span>
        </div>
        <div className="menu-item">
          <DeleteIcon/>
          <span onClick={()=>{deleteText(index);}}>Delete</span>
        </div>
      </div>
    }
      </div>
    </div>
    {/* {input ? <div className={`input-block${isExpanded ? " expanded" : ""}`}>
      {input.tag ? (
        <InputBlock label={"Style:"} content={input.tag} />
      ) : null}
      {input.title ? (
        <InputBlock label={`${name === "Long Text" ? "Article Title:" : "Product Name:"}`} content={input.title} />
      ) : null}
      {input.description ? (
        <InputBlock label={`${name === "Long Text" ? "Article Brief:" : "Product Description:"}`} content={input.description} />
      ) : null}
      {input.keywords ? (
        <InputBlock label={"Keywords:"} content={input.keywords} />
      ) : null}
      {input.audience ? (
        <InputBlock label={"Target Audience:"} content={input.audience} />
      ) : null}
      {input.outline ? (
        <InputBlock label={"Outline:"} content={input.outline} />
      ) : null}
    </div> : null}
    <div className={`content${isExpanded ? " expanded" : ""}`}>{text}</div> */}
  </div>
}

const Texts = ({project, deleteText, search}) => {
  const renderedText = project && project.text && project.text.map(({name, content, input}, index) => {
    return <Text name={name} content={content} input={input} index={index} search={search} deleteText={deleteText} key={index} />
  })
  return (<div className="text-block-container"><span>{renderedText}</span></div>)
}

export default Texts;
