import {makeAutoObservable, action} from "mobx";
import {createText, createTextAPI} from "../fireFunctions";
import {categories, specialCategories} from '../store/categories';
import { toast } from "react-toastify";

class GeneratedText {
  audience = "";
  loading = false;
  results = [];
  outlineLength = 0;
  description = "";
  subtopic = "";
  name = "";
  tone = "";
  outline = "";
  keywords = "";
  longTextString = "";
  longText = [{text: "", outline: ""}];
  error = null;
  longTextAbout = "";
  longTextArticleBrief = "";
  longTextResponseLength = "medium";
  inputLanguage = "en";
  outputLanguage = "en";
  examples = [
    {title: "", description: "", bulletPoint: ""}, 
    {title: "", description: "", bulletPoint: ""}, 
    {title: "", description: "", bulletPoint: ""}
  ];
  selectedToolProperties = {
    toolName: "",
    category: "",
    hasAudience: false,
    hasName: true,
    hasTone: false,
    hasDescription: true,
    hasOutline: true,
    api: false,
    aboutTool: "",
  };

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.audience = "";
    this.loading = false;
    this.results = [];
    this.description = "";
    this.name = "";
    this.tone = "";
    this.error = null;
  }

  generateText = action((typeWords) => {
    const data = {
      description: this.description,
      name: this.name,
      tone: this.tone,
      inputLanguage: this.inputLanguage,
      outputLanguage: this.outputLanguage,
      audience: this.audience,
      subtopic: this.subtopic,
      keywords: this.keywords,
      typeWords: typeWords,
      ...this.selectedToolProperties,
    };
    this.changeLoading(true);
    this.changeError(null);
    if (this.selectedToolProperties.api) {
      createTextAPI(data)
        .then((result) => {
          this.setResultsMore(result.data.generatedText);
        })
        .catch((error) => {
          this.changeError(error);
        })
        .finally(() => this.changeLoading(false));
    } else {
      createText(data)
        .then((result) => {
          this.setResultsMore(result.data.generatedText);
        })
        .catch((error) => {
          if (error.message) {
            toast.error(error.message);
          }
          this.changeError(error);
        })
        .finally(() => this.changeLoading(false));
    }
  });

  setResults(results) {
    this.results = results;
  }

  setResultsMore(results) {
    this.results = [...results, ...this.results];
  }

  setLongTextAbout(text) {
    this.longTextAbout = text;
  }

  setLongTextArticleBrief(text) {
    this.longTextArticleBrief = text;
  }

  setLongTextResponseLength(length) {
    this.longTextResponseLength = length;
  }

  changeOutlineLength(length) {
    this.outlineLength = length;
  }

  changeDescription(value) {
    this.description = value;
  }

  changeName(value) {
    this.name = value;
  }

  changeKeywords(value) {
    this.keywords = value;
  }

  changeSubtopic(value) {
    this.subtopic = value;
  }

  changeInputLanguage(language) {
    this.inputLanguage = language;
  }

  changeOutputLanguage(language) {
    this.outputLanguage = language;
  }

  changeAudience(value) {
    this.audience = value;
  }

  changeTone(value) {
    this.tone = value;
  }

  changeLoading(value) {
    this.loading = value;
  }

  changeError(error) {
    this.error = error;
  }
  changeLongText(value) {
    this.longText = value;
  }
  changeLongTextStringFromLongText(content) {
    this.longTextString = "";
    content.forEach(({text, outline}) => {
      const textSplit = text.split("\n");
      let textRendered = "";
      for (let i = 0; i < textSplit.length; i++) {
        if (textSplit[i]) {
          textRendered += "<div>" + textSplit[i] + "</div>";
        }
      }
      this.longTextString += `<div>
        <div><b>${outline}</b></div>
        <br/>
        <div>${textRendered}</div>
      </div>`;
    });
    localStorage.setItem("longTextString", this.longTextString);
  }
  changeLongTextString(value) {
    this.longTextString = value;
    localStorage.setItem("longTextString", value);
  }
  changeOutline(value) {
    this.outline = value;
  }
  changeExampleTitle(index, value) {
    this.examples[index].title = value;
  }
  changeExampleDescription(index, value) {
    this.examples[index].description = value;
  }
  changeExampleBulletPoint(index, value) {
    this.examples[index].bulletPoint = value;
  }

  setSelectedTool({ tool, category }) {
    this.setResults([]);

    const toolProperties = categories[category][tool];
    this.selectedToolProperties = toolProperties;
  }
  setSpecialSelectedTool(uid) {
    this.setResults([]);
    const toolProperties = specialCategories.filter(category => category.uid === uid);
    this.selectedToolProperties = toolProperties[0];
  }

  clearSelectedTool() {
    this.selectedToolProperties = {
      toolName: "",
      category: "",
      hasAudience: false,
      hasName: false,
      hasTone: false,
      hasDescription: false,
      api: false,
    };
  }

  toggleToolCategory(category) {
    if (this.selectedToolProperties.category === category) {
      this.selectedToolProperties = {
        ...this.selectedToolProperties,
        category: "",
      };

      return;
    }

    this.selectedToolProperties = {
      ...this.selectedToolProperties,
      category,
    };
  }
};

export default new GeneratedText();
