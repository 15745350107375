import React from "react";
import ReactDOM from "react-dom";
import ButtonClose from "components/ButtonClose";

import './styles.scss';

class Modal extends React.Component {
  constructor(props) {
    super(props);
    this.el = document.createElement("div");
    this.modalRoot = document.querySelector("#modal-root");
  }

  escFunction(event) {
    if (event.keyCode === 27) {
      this.props.onClick();
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
    this.modalRoot.appendChild(this.el);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
    this.modalRoot.removeChild(this.el);
  }

  render() {
    return ReactDOM.createPortal(
      <div className="modal">
        <div className="modal__overflow"
          onClick={this.props.onClick}
          style={{background: this.props.colorOverflow}}
        />
        <div className="layout-content-block modal__content">
          {!this.props.hideClose &&
          <ButtonClose
            onClick={this.props.onClick}
            className="modal__close-button"
          />}
          {this.props.children}
        </div>
      </div>,
      this.el
    );
  }
}

export default Modal;