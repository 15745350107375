import React, { useContext, useState, useEffect } from "react";
import "./index.scss";
import InputWrapper from "components/InputWrapper";
import Input from "components/Input";
import {Space} from "antd";
import Button from "components/Button";
import app from "../../base";
import { AuthContext } from "../../Auth";
import { toast } from "react-toastify";
import { TeamContext } from "pages/Dashboard";
export const db = app.firestore();

const ProjectManagement = ({
    save, 
    content, 
    input, 
    shortText,
    source,
    horizontal
  }) => {
  const [newProject, setNewProject] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [projectsInfo, setProjectsInfo] = useState({projects: []});
  const [teamProjectsInfo, setTeamProjectsInfo] = useState({projects: []});
  const [currentProjectInfo, setCurrentProjectInfo] = useState(teamProjectsInfo);
  const [myTeamProjectsInfo, setMyTeamProjectsInfo] = useState({projects: []});
  const {teamInfo, mode, id, setId, project, setProject} = useContext(TeamContext);
  const [localMode, setLocalMode] = useState(mode);

  useEffect(()=>{
    switch (localMode) {
      case "personal":
        setId(currentUser.uid);
        setCurrentProjectInfo(projectsInfo);
        break;
      case "myTeam":
        setId(teamInfo.owns_team_id);
        setCurrentProjectInfo(myTeamProjectsInfo);
        break;
      case "team":
        setId(teamInfo.member_team_id);
        setCurrentProjectInfo(teamProjectsInfo);
        break;
      default:
        break;
    }
  }, [localMode, setId, teamInfo, currentUser, teamProjectsInfo, myTeamProjectsInfo, projectsInfo]);

  useEffect(()=>{
    setProject(currentProjectInfo.projects && currentProjectInfo.projects[0]);
  }, [currentProjectInfo, setProject]);

  const searchProjects = async (id, mode) => {
      const projectRef = db
        .collection("storage")
        .doc(id);
      await projectRef.get().then(doc => {
        if (doc.exists) {
          projectRef.collection("projects").get().then((snap)=> {
            const projectsList = [];
            let index = 0;
            snap.forEach(project => {
              projectsList.push({index, name: project.data().name, id: project.id})
              index += 1;
            })
            if (mode === "personal") {
              setProjectsInfo({projects: projectsList});
            } else if (mode === "team") {
              setTeamProjectsInfo({projects: projectsList});
            } else {
              setMyTeamProjectsInfo({projects: projectsList});
            }
          })
        }
      }).catch((e) => {
        console.log(e);
      });
    };
  useEffect(() => {
    searchProjects(currentUser.uid, "personal");
    teamInfo && teamInfo.member_of_team && searchProjects(teamInfo.member_team_id, "team");
    teamInfo && teamInfo.owns_team && searchProjects(teamInfo.owns_team_id, "myTeam");
  }, [currentUser.uid]); // eslint-disable-line react-hooks/exhaustive-deps

  const addNewProject = async (e) => {
    e.preventDefault();
    if(newProject !== "Project 1") {
      const projectRef = db
        .collection("storage")
        .doc(id)
        .collection("projects");
      await projectRef.add({name: newProject, text: []}).then((snap)=> {
        toast.success(`Added a new project: ${newProject}`);
        searchProjects(id, localMode);
        setNewProject("");
      }).catch(e => {
        console.log(e);
        toast.error("New project is not added!");
      });
    } else {
      toast.error("Cannot add project with default name!");
      setNewProject("");
    }
  }

  const handleSave = async () => {
    if(!shortText) {
      save("Long Text", content, input);
    } else {
      toast(`${project.name} was selected!`);
    }
  }

  const renderedModes = <>
    <option key={"mode 0"} value={"personal"}>Personal</option>
    {teamInfo && teamInfo.owns_team && <option key={"mode 1"} value={"myTeam"}>My Team</option>}
    {teamInfo && teamInfo.member_of_team && <option key={"mode 2"} value={"team"}>Joined Team</option>}
  </>
  return (
    <div className={`project-management${horizontal ? "": " long"}`}>
      <div className="project-management__line">
        <select className={`project-select-results mode`} name="mode" id="mode" value={localMode}
          onChange={(e)=>{
            setLocalMode(e.target.value);
          }}>
          {renderedModes}
        </select>
        <select className={`project-select-results`} name="project" id="project" value={project ? project.index: 0}
          onChange={(e)=>{
            setProject(currentProjectInfo.projects[e.target.value]);
          }}>
          {currentProjectInfo && currentProjectInfo.projects && currentProjectInfo.projects
            .map(({name}, index) => {
              return <option key={"option"+index} value={index}>{name}</option>
          })}
        </select>
      </div>
      
      <Button className={`span-save${horizontal ? "_short-button" : ""}`} title="Save in projects" isBlue="true"
        onClick={async()=> {
          if(project) {
            await handleSave();
          } else {
            toast.error("Choose a project!");
          }
        }}>
        <Space>
          {horizontal && !source ? "Select" : "Save"}
        </Space>
      </Button>
      <form className={`add-new-project${horizontal ? " short" : ""}`} onSubmit={addNewProject}>
        <InputWrapper
          allowClear={true}
          value={newProject}
          onChange={(e)=>{setNewProject(e.target.value)}}
        >
          <Input
            placeholder="ex.: Project 2"
            maxLength={25}
            value={newProject}
            onChange={(e)=>{setNewProject(e.target.value)}}
            required={true}
            onBlur={async() => {await handleSave()}}
          />
        </InputWrapper>
        <Button className={`span-mid${horizontal ? "_short-button" : ""}`} title="Add new project" type="submit" isBlue="true">
          <Space>
            Add new project
          </Space>
        </Button>
      </form>
    </div>
  )
}

export default ProjectManagement;