export const specialCategories = [
  // {
  //   uid: "tutorials",
  //   btnText: "Tutorials",
  //   title: "Tutorials",
  //   description: "",
  // },
  // {
  //   uid: "beast-mode-2.0",
  //   btnText: "Beast Mode 2.0",
  //   title: "Beast Mode 2.0",
  //   description: "Best Mode 2.0 offers great quality. More controllable variables — now you can choose a custom target audience, writing style, and article length.",
  //   label: "NEW",
  //   name: ""
  // },
  {
    uid: "beast-mode",
    btnText: "Article Writer",
    title: "Article Writer",
    description: "",
    name: ""
  },
  // {
  //   uid: "long-text-gen-2.0",
  //   btnText: "Article Generation 2.0",
  //   title: "Article Generation 2.0",
  //   description: "In this updated version we offer enhanced introduction paragraphs and logically structured paragraphs.",
  //   name: "",
  //   hasName : true,
  //   hasDescription : true,
  //   hasOutline: true
  // },
  // {
  //   uid: "long-text-gen",
  //   btnText: "Article Generation",
  //   title: "Article Generation",
  //   description: "",
  //   name: "",
  //   hasName : true,
  //   hasDescription : true,
  //   hasOutline: true
  // },
  {
    uid: "editor",
    btnText: "Editor",
    title: "Editor",
    description: "",
    name: ""
  },
  // {
  //   uid: "plagiarism",
  //   btnText: "Plagiarism Checker",
  //   title: "Plagiarism Checker",
  //   description: "",
  //   name: ""
  // },
  // {
  //   uid: "clipboard",
  //   btnText: "Clipboard",
  //   title: "Your favorite results",
  //   description: "",
  // },
];
export const categories = {
  "Ecommerce": {
    "Amazon Product Description": {
      toolName: "Amazon Product Description",
      category: "Ecommerce",
      aboutTool: "Give us related product examples and we'll generate great e-commerce product description",
      hasExamples: true,
      hasKeywords: true,
    },
  },
  "Writing Tools": {
    // "Outline Generator": {
    //   toolName: "Essay Outline",
    //   category: "Writing Tools",
    //   hasAudience: false,
    //   hasName: false,
    //   nameValue: "",
    //   hasTone: false,
    //   hasDescription: true,
    //   aboutTool: "Write a great essay for school, your blog, or your website",
    // },
    "Paragraph Generator" : {  
      toolName: "Paragraph Generator",
      category: "Writing Tools",
      hasAudience: false,
      hasName: true,
      nameValue: "Texta.ai",
      hasTone: false,
      hasDescription: true,
      aboutTool: "Generate great paragraphs on specific topics",
      hasSubtopic: true,
      hasKeywords: true
    },
    "Blog Ideas": {
      toolName: "Blog Ideas",
      category: "Writing Tools",
      hasAudience: false,
      hasName: false,
      nameValue: "",
      hasTone: false,
      hasDescription: true,
      aboutTool: "Get great blog ideas in a few clicks",
    },
    "Blog Titles": {
      toolName: "Blog Titles",
      category: "Writing Tools",
      hasAudience: false,
      hasName: false,
      nameValue: "",
      hasTone: false,
      hasDescription: true,
      aboutTool: "Kickstart your creativity",
    },
  },
  "Social Ads": {
    "Product Description": {
      toolName: "Product Description",
      category: "Social Ads",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      api: false,
      aboutTool:
        "Give us some characteristics and we’ll give you a beautiful description",
    },
    "Facebook Primary Text": {
      toolName: "Facebook Primary Text",
      category: "Social Ads",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      api: false,
      aboutTool: "Craft the perfect primary text",
    },
//     "Twitter Text": {
//       toolName: "Twitter Text",
//       category: "Social Ads",
//       hasAudience: true,
//       hasName: true,
//       hasTone: false,
//       hasDescription: true,
//       api: false,
//       aboutTool: "Craft the perfect Twit",
//     },
    "Facebook Listicle": {
      toolName: "Facebook Listicle",
      category: "Social Ads",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "Showcase your product",
    },
    "Facebook Headlines": {
      toolName: "Facebook Headlines",
      category: "Social Ads",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "Craft the perfect headline",
    },
    "Google Headlines": {
      toolName: "Google Headlines",
      category: "Social Ads",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "Craft the perfect headline",
    },
    "Google Description": {
      toolName: "Google Description",
      category: "Social Ads",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "Pro copy, pro results",
    },
    "LinkedIn Ads": {
      toolName: "LinkedIn Ads",
      category: "Social Ads",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "Capture B2B leads in a click",
    },
    "Ad Copy": {
      toolName: "Ad Copy",
      category: "Social Ads",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "For banner ads or billboards or anything in-between",
    },
    "Video Title Generator": {
      toolName: "Video Title Generator",
      category: "Social Ads",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "This is a clickbait video title generator, that creates a video title from description",
    },
  },
  "Startup Tools": {
    "Value Proposition": {
      toolName: "Value Proposition",
      category: "Startup Tools",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "What makes you different from your competitors?",
    },
    "Slogan Generator": {
      toolName: "Slogan Generator",
      category: "Startup Tools",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool:
        "The strategic core sentence that your brand represents everywhere",
    },
    "Brand Mission": {
      toolName: "Brand Mission",
      category: "Startup Tools",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool:
        "Define your brand's mission and how it applies to your product or service",
    },
    "Audience Refiner": {
      toolName: "Audience Refiner",
      category: "Startup Tools",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool:
        "Customize your target audience to match your exact specifications",
    },
    "Name Generator": {
      toolName: "Name Generator",
      category: "Startup Tools",
      hasAudience: false,
      hasName: false,
      nameValue: "",
      hasTone: false,
      hasDescription: true,
      aboutTool: "Create a name that's perfect for your products and services",
    },
    "Domain Name Generator": {
      toolName: "Domain Name Generator",
      category: "Startup Tools",
      hasAudience: false,
      audienceValue: "audience value",
      hasName: true,
      nameValue: "",
      hasTone: false,
      hasDescription: true,
    },
    "Startup Ideas": {
      toolName: "Startup Ideas",
      category: "Startup Tools",
      hasAudience: false,
      hasName: false,
      nameValue: "",
      hasTone: false,
      hasDescription: true,
      aboutTool: "Create your ideal start-up idea",
    },
    "Video Titles": {
      toolName: "Video Titles",
      category: "Startup Tools",
      hasAudience: false,
      hasName: false,
      nameValue: "",
      hasTone: false,
      hasDescription: true,
      aboutTool: "Improve your search ranking on YouTube",
    },
    "Text Hook": {
      toolName: "Text Hook",
      category: "Startup Tools",
      hasAudience: false,
      hasName: false,
      nameValue: "",
      hasTone: false,
      hasDescription: true,
      aboutTool: "Improve your search ranking on YouTube",
    },
  },
  "Website Copy": {
    "Subheader Text": {
      toolName: "Subheader Text",
      category: "Website Copy",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "Subheader Text from a few inputs. It’s that simple",
    },
    "Landing Page Hero Text": {
      toolName: "Landing Page Hero Text",
      category: "Website Copy",
      hasAudience: true,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "The hero copy that captures attention and generates sales",
    },

    Microcopy: {
      toolName: "Microcopy",
      category: "Website Copy",
      hasAudience: false,
      hasName: false,
      nameValue: "",
      hasTone: false,
      hasDescription: true,
      aboutTool: "We can craft the perfect microcopy",
    },
    "Meta Descriptions": {
      toolName: "Meta Descriptions",
      category: "Website Copy",
      hasAudience: true,

      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "SEO-optimize your site in an instant",
    },
    "Question Generator": {
      toolName: "Question Generator",
      category: "Website Copy",
      hasAudience: false,
      hasName: false,
      nameValue: "",
      hasTone: false,
      hasDescription: true,
      aboutTool: "Narrative copy for events, interviews, etc.",
    },
  },
  "Sales Copy": {
    "Marketing Strategy": {
      toolName: "Marketing Strategy",
      category: "Sales Copy",
      hasAudience: false,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "Build an awesome marketing strategy",
    },
    "Problem-Agitate-Solution": {
      toolName: "Problem-Agitate-Solution",
      category: "Sales Copy",
      hasAudience: false,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "The perfect Ad hook",
    },
    "Before-After-Bridge": {
      toolName: "Before-After-Bridge",
      category: "Sales Copy",
      hasAudience: false,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool:
        "Before After Bridge (BAB) is a tried and true advertising framework for your reader exactly why they need your product",
    },
    "Feature-Advantage-Benefit": {
      toolName: "Feature-Advantage-Benefit",
      category: "Sales Copy",
      hasAudience: false,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool:
        "Grab your reader's attention with this tried and true formula",
    },
    "Why/Try/Buy": {
      toolName: "Why/Try/Buy",
      category: "Sales Copy",
      hasAudience: false,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "Use the right call to action",
    },
    AIDPPC: {
      toolName: "Attention-Interest-Description-Persuasion-Proof-Close",
      category: "Sales Copy",
      hasAudience: false,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool:
        "Grab your reader's attention with this tried and true formula",
    },
    ACCA: {
      toolName: "Awareness-Comprehension-Conviction-Action",
      category: "Sales Copy",
      hasAudience: false,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "Use the right call to action",
    },
    "QUEST Strategy": {
      toolName: "QUEST Strategy",
      category: "Sales Copy",
      hasAudience: false,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "A strategy for any business, anywhere, anytime",
    },
    "Feature to Benefit": {
      toolName: "Feature to Benefit",
      category: "Sales Copy",
      hasAudience: false,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "Make your message clear",
    },
    "AIDA Copywriting": {
      toolName: "AIDA Copywriting",
      category: "Sales Copy",
      hasAudience: false,
      hasName: true,
      hasTone: false,
      hasDescription: true,
      aboutTool: "Attention, Interest, Desire, Action",
    },
  },
};
