import React, { useEffect, useState } from "react";
import app from "./base.js";
import Spin from "antd/es/spin";
import Row from "antd/es/row";
import { db } from "pages/Login/GoogleProvider.js";

export const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [subType, setSubType] = useState(null);
  const [pending, setPending] = useState(true);
  const [name, setName] = useState("");
  useEffect(() => {
    app.auth().onAuthStateChanged(async(user) => {
      if (user && user.emailVerified) {
        await db.collection("customers").doc(user.uid).get().then(doc => {
          if (doc.exists) {
            setSubType(doc.data().sub_type);
            setName(doc.data().name);
          }
        })
        setCurrentUser(user);
      } else {
        setCurrentUser(null);
      }
      setPending(false);
    });
  }, []);

  if (pending) {
    return (
      <Row
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
      >
        <Spin tip="Loading..." />
      </Row>
    );
  }
  return (
    <AuthContext.Provider
      value={{
        currentUser, setCurrentUser, subType, setSubType, name
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
