import Button from "components/Button";
import "./index.scss";

const DeleteProjectConfirmation = ({name, onClose, onChange}) => {
  return (
    <div className="delete-project-modal">
      <div className="confirmation">
        <h3>Delete "{name}" project?</h3>
      </div>
      <div className="buttons">
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onChange} isBlue={true}>
          Delete
        </Button>
      </div>
    </div>
  );
};

export default DeleteProjectConfirmation;
