import firebase from 'firebase';
import { useContext } from 'react';
import { SignInContext } from './Login';
import app from '../../base';
const auth = firebase.auth();
export const db = app.firestore();
const GoogleProvider = ({setCurrentStep, setErrorMessage, setLoading})=> {
  const {setIsSignedIn} = useContext(SignInContext);
  const signInWithProviders = async(provider) => {
    setLoading(true);
    await auth.signInWithPopup(provider)
        .then((result) => {
          const user = result.user;
          user.updateProfile({
            displayName: user.providerData && user.providerData[0].displayName
          }).then(()=>{
            db.collection("customers").doc(auth.currentUser.uid).get().then(userRef =>{
              if (userRef.exists && (userRef.data().reg_answer4 || userRef.data().source)) {
                setLoading(false);
                setIsSignedIn(true);
              } else {
                setLoading(false);
                setCurrentStep("reg-form");
              } 
            })
          });
        }).catch((e) => {
          setErrorMessage("Internal error");
          setLoading(false);
          console.log(e);
        })
  }
  return <button className="login-form__button google" onClick={()=>{
    signInWithProviders(new firebase.auth.GoogleAuthProvider());
  }}>Continue with Google</button>;
}

export default GoogleProvider;