import './styles.scss';

const ResultsLoader = () => {
    return (
        <div className="loader">
            <div className="loader__row">
                <div className="loader__block _big"/>
                <div className="loader__block"/>
                <div className="loader__block"/>
            </div>
            <div className="loader__row">
                <div className="loader__block _big"/>
                <div className="loader__block"/>
                <div className="loader__block"/>
            </div>
            <div className="loader__row">
                <div className="loader__block _big"/>
                <div className="loader__block"/>
                <div className="loader__block"/>
            </div>
            <div className="loader__row">
                <div className="loader__block _big"/>
                <div className="loader__block"/>
                <div className="loader__block"/>
            </div>
        </div>
    )
};

export default ResultsLoader;