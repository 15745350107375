export const EditorInstructions = ()=>{
  return <div>
    <h3>How to use editor?</h3>
    <ol>
      <li>Insert the article outline, written manually by yourself or generated in Article Generation tool above in dashboard
          <br/> Or paste draft of your article with outlines on separate lines. 
      </li>
      <li>
        <p>If the paragraph for an outline <b>is empty</b>:</p>
        <ul>
          <li>Enter a new line below your outline and keep your cursor at that new line</li>
          <li>Press the button on the right column "Write for me"</li>
        </ul>
        <b/>
        <p>If the paragraph for an outline <b>has some content</b>:</p>
        <ul>
          <li>Navigate your cursor to the place you want AI to continue</li>
          <li>Press the button on the right column "Write for me"</li>
        </ul>
      </li>
    </ol>
    <p>You can regulate the length of generated content on the right column in section "Output length"</p>
    <p><b>Length estimations: </b></p>
    <p>A little = <b>~50 words</b></p>
    <p>Between = <b>~80</b> words</p>
    <p>A lot = <b>~110</b> words</p>
  </div>;
} 