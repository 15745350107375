import { React } from "react";
import ReactPlayer from "react-player";
import './index.scss'
const Video = ({ url, title }) => {
  return (
    <>
      <ReactPlayer url={url} controls={true} width="300px" height="200px" />
    </>
  )
};

export default Video;
