import React, { useState, useMemo } from "react";
import { ReactComponent as ArrowIcon } from "assets/icons/arrow.svg";
import "./styles.scss";

/**
 * Пример данных
 * [{ code: "en", label: "English", icon: ReactComponent }]
 * icon - не обязательное поле
 * 
 * @return {string}: code
 */

const Dropdown = ({ list, value, onChange }) => {
  const [show, toggle] = useState(false);
  const eventHandlers = useMemo(() => ({ onBlur: () => toggle(false) }), []);
  const onSelect = (val) => {
    onChange(val);
    onToggle();
  };
  const onToggle = () => {
    toggle(!show);
  }
  const currentActiveEl = list.find((item) => item.code === value);
  const currentList = list.filter((item) => item.code !== value);
  return (
    <div className="dropdown" tabIndex="-1" {...eventHandlers}>
      <div className="dropdown__selected" onClick={() => toggle(!show)}>
        {currentActiveEl.icon ? (
          <currentActiveEl.icon className="dropdown__icon" />
        ) : null}
        {currentActiveEl.label}

        <div className="dropdown__selected-icon-container">
          <ArrowIcon className="dropdown__selected-icon" />
        </div>
      </div>

      {show && (
        <div className="dropdown__list">
          {currentList.map((item) => (
            <div className="dropdown__item" onClick={() => onSelect(item.code)}>
              {item.icon ? <item.icon className="dropdown__icon" /> : null}
              {item.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
