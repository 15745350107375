import { React, useState } from 'react';
import firebase from 'firebase';
import "./index.scss";

const ResetField = ({id, placeholder, type, value, onChange, infoMessage, errorMessage, buttonLabel}) => {
  return (
    <>
      <div className='reset-form__input'>
        <input id={id} placeholder={placeholder} type={type} value={value} onChange={onChange} required/>
      </div>
      {infoMessage && <span className='reset-form__info'>{infoMessage}</span>}
      {errorMessage && <span className='reset-form__error'>{errorMessage}</span>}
      {buttonLabel && <button className="reset-form__button" type="submit">{buttonLabel}</button>}
    </>
  )
}

const ResetForm = () => {
  const [showResetEmail, setShowResetEmail] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [showResetName, setShowResetName] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newName, setNewName] = useState("");
  const [newPasswordConfirm, setNewPasswordConfirm] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [infoMessage, setInfoMessage] = useState("");

  const clearFields = () => {
    setNewEmail("");
    setNewPassword("");
    setNewName("");
    setNewPasswordConfirm("");
    setCurrentPassword("");
    setErrorMessage("");
  }

  const reauthenticate = () => {
    var user = firebase.auth().currentUser;
    var cred = firebase.auth.EmailAuthProvider.credential(
        user.email, currentPassword);
    return user.reauthenticateWithCredential(cred);
  }

  const updateEmailFirebase = async (newEmail, uid) => {
    await firebase.firestore().collection("customers")
            .doc(uid)
              .update({
                email: newEmail,
              }).then(() => {
                window.location.reload();
              });
  }

  const resetEmail = async(e) => {
    e.preventDefault();
    setErrorMessage("");
    reauthenticate().then(() => {
      var user = firebase.auth().currentUser;
      user.updateEmail(newEmail).then(() => {
        setInfoMessage("Email updated!");
        updateEmailFirebase(newEmail, user.uid);
      }).catch((e) => { setErrorMessage(e.message); });
    }).catch((e) => {
        if (e.code === "auth/wrong-password") {
          setErrorMessage("Current password is invalid");
        } else {
          setErrorMessage(e.message);
        }
      });
  }

  const resetPassword = async(e) => {
    e.preventDefault();
    if (newPassword !== newPasswordConfirm) {
      setErrorMessage("Passwords do not match");
      return;
    }
    reauthenticate().then(() => {
      var user = firebase.auth().currentUser;
      user.updatePassword(newPassword).then(() => {
        setInfoMessage("Password updated!");
        clearFields();
      }).catch((e) => {
          if (e.code === "auth/weak-password") {
            setErrorMessage("Weak password");
          } else {
            setErrorMessage(e.message);
          }
        });
    }).catch((e) => {
        if (e.code === "auth/wrong-password") {
          setErrorMessage("Current password is invalid");
        } else {
          setErrorMessage(e.message);
        }
      });
  }

  // user.updateProfile({
  //      displayName: "Jane Q. User",
  //    }).then(function() {
  //     var displayName = user.displayName;
  //    }, function(error) {
  //    });
  
  const updateNameFirebase = async (newName, uid) => {
    await firebase.firestore().collection("customers")
          .doc(uid)
            .update({
              name: newName,
            }).then(() => {
              window.location.reload();
            });
  }
  const resetName = async(e) => {
    e.preventDefault();
    var user = firebase.auth().currentUser;
    updateNameFirebase(newName, user.uid);
  }
  return (
    <div className="reset-field">
      <div className="modal-label" onClick={()=>{
        if(!showResetName) {
          setInfoMessage("");
        }
        setShowResetName(!showResetName);
        setShowResetPassword(false);
        setShowResetEmail(false);
        clearFields();}}
      >
        Update Name
      </div>
      {showResetName &&
        <form  className='reset-form' onSubmit={resetName}>
          <ResetField id={"name"} placeholder={"New Name"} value={newName} buttonLabel={"Update Name"}
            onChange={(e)=>{
              setNewName(e.target.value);
              setInfoMessage("");
            }}
          />
        </form>}
      <div className="modal-label" onClick={()=>{
        if(!showResetEmail) {
          setInfoMessage("");
        }
        setShowResetEmail(!showResetEmail);
        setShowResetPassword(false);
        setShowResetName(false);
        clearFields();}}
      >
        Update Email
      </div>
      {showResetEmail &&
        <form  className='reset-form' onSubmit={resetEmail}>
          <ResetField id={"email"} placeholder={"New Email"} type={"email"} value={newEmail}
            onChange={(e)=>{
              setNewEmail(e.target.value);
              setInfoMessage("");}}
          />
          <ResetField id={"pswCurrEmail"} placeholder={"Current Password"} type={"password"} value={currentPassword} infoMessage={infoMessage} errorMessage={errorMessage} buttonLabel={"Reset Email"}
            onChange={(e)=>{
              setCurrentPassword(e.target.value);
              setErrorMessage("");}} 
          />
        </form>}
        
        
      <div className="modal-label" onClick={()=>{
        if(!showResetPassword) {
          setInfoMessage("");
        }
        setShowResetPassword(!showResetPassword);
        setShowResetEmail(false);
        setShowResetName(false);
        clearFields();}}
      >
        Reset Password
      </div>
      {showResetPassword &&
        <form  className='reset-form' onSubmit={resetPassword}>
          <ResetField id={"pswCurr"} placeholder={"Current Password"} type={"password"} value={currentPassword}
            onChange={(e)=>{
              setCurrentPassword(e.target.value);
              setErrorMessage("");
              setInfoMessage("");}}
          />
          <ResetField id={"psw"} placeholder={"New Password"} type={"password"} value={newPassword}
            onChange={(e)=>{
              setNewPassword(e.target.value);
              setErrorMessage("");
              setInfoMessage("");}}
          />
          <ResetField id={"pswConfirm"} placeholder={"Confirm New Password"} type={"password"} value={newPasswordConfirm} infoMessage={infoMessage} errorMessage={errorMessage} buttonLabel={"Reset Password"}
            onChange={(e)=>{
              setNewPasswordConfirm(e.target.value);
              setErrorMessage("");
              setInfoMessage("");}}
          />
        </form>}
        
    </div>
  )
}

export default ResetForm;
