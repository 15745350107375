import Button from "antd/es/button"
import { useHistory } from "react-router-dom";
import "./index.scss";
export const SuccessPayment = ()=>{
  const history = useHistory();
  return <div className="success-wrapper">
    <div className="success-card">
      <h2>Payment is being processed</h2>
      <p>In case the payment card has sufficient funds, your coins will be refilled</p>
      <p><b>Important: </b>It takes approximately <b>1 minute</b> to fill coins after payment</p>
      <p>Please reload the main page in a minute to refresh information on coins</p>
      <p>If the coins are not being updated, contact our support team through chat widget on the right bottom</p>
      <Button className="back-button" onClick={()=>{history.push("/")}}>Back to main</Button>
    </div>
  </div>
}