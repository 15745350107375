import { useState, useEffect } from "react";
import {message, Radio} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import app from '../../base';
import {applyDiscountOnCancel} from "fireFunctions";
import { useHistory } from "react-router-dom";
const reasons = ["Texta is not working for our needs", 
  "It's too expensive", 
  "Poor quality of generated content", 
  "I found another AI service", 
  "I don't need to generate content anymore"];

const db = app.firestore();
export const UnsubscribeForm = ({role, user, setFormSubmitted, id}) => {
  const [other, setOther] = useState("");
  const [otherChecked, setOtherChecked] = useState("");
  const [reason, setReason] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingRedeem, setLoadingRedeem] = useState(false);
  const [successRedeem, setSuccessRedeem] = useState(false);
  const [showRedeem, setShowRedeem] = useState(false);
  const history = useHistory();
  const renderedSource = reasons.map((name, index) => 
    <Radio value={name} key={`reason ${index}`} className='form__option'>{name}</Radio>
  );
  useEffect (()=>{
    const getInfo = async ()=>{
      db.collection("customers").doc(user.uid).get()
        .then((doc)=>{
          if (doc.exists) {
            const redeemedDiscount = doc.data().redeemedDiscount;
            if (!redeemedDiscount) {
              setShowRedeem(true);
            }
          }
        })
    }
    getInfo();
  }, [user]);

  return <form className='form' onSubmit={(e)=> {
      e.preventDefault();
      if (!reason || (otherChecked && !other)) {
        return;
      }
      const submitReason = async()=>{
        try {
          await db.collection("unsubscriptionReasons").add({
            email: user.email,
            reason: otherChecked ? other : reason
          });
          setFormSubmitted(true);
        } catch (e) {
          setLoading(false);
          message.error("Error, please try again");
        }
      }
      setLoading(true);
      submitReason();
    }}>
    {
      !successRedeem ? 
      <>
        <div className="form-content">
          <h1>Sorry to see you go</h1>
          {showRedeem && <div className="form-content__discount">
            <p><b>Exclusive offer</b>: 50% off for your next payment for current subscription</p> 
            <button className="form__button red"
              onClick={async()=>{
                setLoadingRedeem(true);
                try {
                  await applyDiscountOnCancel({subId: id});
                  await db.collection("customers").doc(user.uid).update({redeemedDiscount: true});
                  setSuccessRedeem(true);
                } catch(e) {
                  message.error("Error, please try again");
                  setLoadingRedeem(false);
                }
              }}
            >
              Redeem {loadingRedeem && <LoadingOutlined/>}
            </button>
          </div>}
          
          <p>You are going to unsubscribe from {role} subscription. <br/>Please take a moment to tell us why you no longer wish to proceed with your current plan.</p>
        </div>
        <Radio.Group className='form__options' onChange={(e)=>{
            setOtherChecked(e.target.value === "specialVal");
            setReason(e.target.value);
          }}>
          {renderedSource}
          <Radio value="specialVal" className='form__option'>
            <input disabled={!otherChecked} className='form__other' value={other} onChange={(e)=>setOther(e.target.value)} placeholder="Other..."/>
          </Radio>
        </Radio.Group>
        <button className="form__button" type="submit">Submit {loading && <LoadingOutlined/>}</button>
      </> :
      <div className="form-content success">
        <h1>Success!</h1>
        <p>You have redeemed 50% discount for your current subscription. 
        The discount will be applied on your next invoice.</p>
        <button className="form__button" onClick={()=>{history.push("/")}}>Back to main</button>
      </div>
    }

  </form>
}