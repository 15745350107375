import React, { useContext, useState, useEffect } from "react";
import Layout from "antd/es/layout";
import Logo from "../Logo";
import Row from "antd/es/row";
import Input from "components/Input";
import Space from "antd/es/space";
import { useHistory } from "react-router-dom";
import Text from "antd/es/typography/Text";
// import Divider from "antd/es/divider";
import ButtonSecondary from "../Button";
import Button from "antd/es/button";
import app from "../../base";
import { LogoutOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { AuthContext } from "../../Auth";
// import Avatar from "antd/es/avatar/avatar";
// import Image from "antd/es/image";
import "./index.scss";
import Texts from "../Texts";
import Modal from "components/Modal";
import DeleteProjectConfirmation from "components/DeleteProjectConfirmation"
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { TeamManagementPopup } from "components/TeamManagementPopup";
import { toast } from "react-toastify";
export const db = app.firestore();

const { Header, Content, Footer } = Layout;

const Projects = () => {
  const [search, setSearch] = useState("");
  const { currentUser, name } = useContext(AuthContext);
  const history = useHistory();
  // const avatar = currentUser.picture ? (
  //   <Avatar src={<Image src={currentUser.picture} />} />
  // ) : (
  //   <Avatar icon={<UserOutlined />} />
  // );
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [myTeamProjectsInfo, setMyTeamProjectsInfo] = useState({projects: []});
  const [teamProjectsInfo, setTeamProjectsInfo] = useState({projects: []});
  const [projectsInfo, setProjectsInfo] = useState({projects: []});
  const [currentProjectInfo, setCurrentProjectInfo] = useState(teamProjectsInfo);
  const [project, setProject] = useState(null);
  const [localMode, setLocalMode] = useState("personal");
  const [teamInfo, setTeamInfo] = useState(null); 
  const [id, setId] = useState(currentUser.uid);
  const [neverAskCheck, setNeverAsk] = useState(false);
  const [showTeamForm, setShowTeamForm] = useState(false);
  
  // useEffect(()=>{
  //   if (teamInfo && !teamInfo.owns_team && !teamInfo.never_show_team_invite) {
  //     setShowTeamForm(true);
  //   } // eslint-disable-next-line
  // }, [teamInfo]);

  useEffect(() => {
    const search = async () => {
      const customerRef = db
        .collection("customers")
        .doc(currentUser.uid);
      customerRef.get().then(async (doc) => {
        if (doc.exists) {
          const {member_of_team, member_team_id, owns_team, owns_team_id, never_show_team_invite} = doc.data();
          if (member_of_team) {
            await db.collection("teams").doc(member_team_id).get().then(doc=>{
              if (doc.exists){
                const {owner_id} = doc.data();
                setTeamInfo({
                  member_of_team,
                  member_team_id,
                  owns_team,
                  owns_team_id,
                  owner_id, 
                  never_show_team_invite
                })
              }
            })
          } else {
            setTeamInfo({
              owns_team,
              owns_team_id,
              never_show_team_invite
            })
          }
        }
      }).catch((e) => {
        console.log(e);
      });
    };
    search(); // eslint-disable-next-line
  }, []);

  useEffect(()=>{
    switch (localMode) {
      case "personal":
        setId(currentUser.uid);
        setCurrentProjectInfo(projectsInfo);
        break;
      case "myTeam":
        setId(teamInfo.owns_team_id);
        setCurrentProjectInfo(myTeamProjectsInfo);
        break;
      case "team":
        setId(teamInfo.member_team_id);
        setCurrentProjectInfo(teamProjectsInfo);
        break;
      default:
        break;
    }
  }, [localMode, teamInfo, currentUser, teamProjectsInfo, myTeamProjectsInfo, projectsInfo]);


  useEffect(()=>{
    setProject(currentProjectInfo.projects && currentProjectInfo.projects[0]);
  }, [currentProjectInfo]);


  useEffect(()=>{
    const updateUserData = async()=>{
      await db.collection("customers").doc(currentUser.uid).update({never_show_team_invite: true});
    }
    if (neverAskCheck) {
      updateUserData();
    }
  }, [neverAskCheck, currentUser]);

  const deleteText = (index) => {
    const projectRef = db
        .collection("storage")
        .doc(id).collection("projects").doc(project.id);
    projectRef.get().then(doc => {
      if (doc.exists) {
        projectRef.get().then(async(doc)=> {
          if (doc.exists) {
            const projectTextUpdated = project.text.filter((_, ind) => ind !== index);
            await projectRef.update({text: projectTextUpdated});
            await searchProjects(id, localMode);
            toast.success("Text deleted");
          }
        })
      }
    }).catch((e) => {
      console.log(e);
    });
  }
  const deleteProject = async () => {
    if(project.name !== "Project 1") {
      const projectRef = db
        .collection("storage")
        .doc(id).collection("projects").doc(project.id);
      await projectRef.get().then(async (doc) => {
        if (doc.exists) {
          await projectRef.delete().then(snap => {
            toast.success(`${project.name} was deleted!`);
            window.location.reload();
          }).catch(e => {
            console.log(e);
          });
        }
      }).catch((e) => {
        console.log(e);
      });
    } else {
      toast.error(`Unable to delete default project!`);
    }
  }

  const searchProjects = async (id, mode) => {
    const projectRef = db
      .collection("storage")
      .doc(id);
    await projectRef.get().then(doc => {
      if (doc.exists) {
        projectRef.collection("projects").get().then((snap)=> {
          const projectsList = [];
          let index = 0;
          snap.forEach((project) => {
            let text = project.data().text;
            if (text) {
              text = text.reverse();
            }
            projectsList.push({index, name: project.data().name, id: project.id, text});
            index += 1;
          })
          if (mode === "personal") {
            setProjectsInfo({projects: projectsList});
          } else if (mode === "team") {
            setTeamProjectsInfo({projects: projectsList});
          } else {
            setMyTeamProjectsInfo({projects: projectsList});
          }
        })
      }
    }).catch((e) => {
      console.log(e);
    });
  };

  useEffect(() => {
    searchProjects(currentUser.uid, "personal");
    teamInfo && teamInfo.member_of_team && searchProjects(teamInfo.member_team_id, "team");
    teamInfo && teamInfo.owns_team && searchProjects(teamInfo.owns_team_id, "myTeam");
  }, [currentUser.uid, teamInfo]); // eslint-disable-line react-hooks/exhaustive-deps

  const renderedProjects = currentProjectInfo && currentProjectInfo.projects && currentProjectInfo.projects
      .map(({name, index}) => {
        return <option key={"option"+index} value={index}>{name}</option>
    });
  
  const renderedModes = <>
    <option key={"mode 0"} value={"personal"}>Personal</option>
    {teamInfo && teamInfo.owns_team && <option key={"mode 1"} value={"myTeam"}>My Team</option>}
    {teamInfo && teamInfo.member_of_team && <option key={"mode 2"} value={"team"}>Joined Team</option>}
  </>
  
  return (
    <Layout style={{ minHeight: "100vh" }} theme="light">
      <Header className="site-layout-background" style={{ padding: 0 }}>
        <Row
          justify="space-between"
          style={{ marginRight: "16px" }}
          align="middle"
        >
          <Logo />
          <Space>
            {/* <Link to="/profile">
              <Space>
                {avatar} */}
                <Text>{name}</Text>
              {/* </Space>
            </Link>
            <Divider type="vertical" /> */}
            <Button
              className="back-button"
              onClick={() => app.auth().signOut().then(()=>{history.push("/login")})}
              icon={<LogoutOutlined />}
            >
              Log out
            </Button>
          </Space>
        </Row>
      </Header>
      <Layout className="site-layout">
          <Content style={{ padding: "0 50px" }}>
            <div>&nbsp;</div>
            <div className="site-card-wrapper">
            <Row>
              <h1>Your projects</h1>
            </Row>
            <div className="site-card-wrapper__form">
            <select className="project-select" name="project" id="project" value={localMode} onChange={(e)=>{
                setLocalMode(e.target.value);
              }}>
              {renderedModes}
            </select>
            <select className="project-select" name="project" id="project" value={project ? project.index : 0} onChange={(e)=>{
                setProject(currentProjectInfo.projects[e.target.value]);
              }}>
              {renderedProjects}
            </select>
            <Button className="delete-project" title={"Delete project"} onClick={()=>{setDeleteConfirmation(true)}}>
              <DeleteIcon className="delete-project-icon"/>
            </Button>
            <div className="filter"><Input placeholder="Filter by tool name..." value={search} onChange={(e)=>{setSearch(e.target.value)}}/></div>
              <ButtonSecondary className="search-button" isBlue={true}>Search</ButtonSecondary>
            </div>
              <div className="site-card-wrapper__form">
              <Button onClick={() => history.push("/")}
              icon={<ArrowLeftOutlined />} className="back-button">Back</Button>
                <Texts project={project} search={search} deleteText={deleteText}/>
              </div>
            </div>
          </Content>
        <Footer style={{ textAlign: "center" }}></Footer>
        {deleteConfirmation && 
          <Modal 
            onClick={() => {setDeleteConfirmation(false);}}
            colorOverflow={"rgba(49, 52, 58, 0.212)"}
          >
            <DeleteProjectConfirmation
              name={project.name}
              onClose={()=>{setDeleteConfirmation(false)}}
              onChange={()=>{
                deleteProject();
                setDeleteConfirmation(false);
              }}
            />
          </Modal>
        }
      </Layout>
      {showTeamForm && 
        <Modal 
          onClick={() => {setShowTeamForm(false)}}
          colorOverflow={"rgba(49, 52, 58, 0.212)"}
        >
          <TeamManagementPopup user={{currentUser}} neverAskCheck={neverAskCheck} setNeverAsk={setNeverAsk}/>
        </Modal>}
    </Layout>
  );
};

export default Projects;