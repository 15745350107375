import firebase from 'firebase';
import React, {useState, useContext} from 'react';
import {Radio} from "antd";
import "./index.scss";
import app from '../../../base';
import { SignInContext } from '../Login';
import { AuthContext } from 'Auth';
export const db = app.firestore();
const formDetails = {
  position: [
    "Copywriter - working at a company",
    "Copywriter - agency or freelance",
    "Marketer - working at a company",
    "Marketer - agency or freelance",
    "Business owner",
    "Other Employee",
    "Other Freelancer",
  ],
  industry: [
    "Ecommerce",
    "Digital marketing / SMM",
    "Startup",
    "SEO Professional",
    "Professional Services",
    "IT",
    "Marketing",
  ],
  source: [
    "Online Article",
    "Facebook",
    "Google",
    "LinkedIn",
    "Twitter",
    "Review Websites",
    "Word of mouth",
  ]
}

// eslint-disable-next-line
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

const RegistrationForm = ()=>{
  const [industry, setIndustry] = useState([]);
  const [source, setSource] = useState("");
  const [position, setPosition] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [otherSource, setOtherSource] = useState("");
  const [otherIndustry, setOtherIndustry] = useState("");
  const [otherPosition, setOtherPosition] = useState("");
  const [loading, setLoading] = useState(false);
  const [otherPositionChecked, setOtherPositionChecked] = useState(false);
  const [otherIndustryChecked, setOtherIndustryChecked] = useState(false);
  const [otherSourceChecked, setOtherSourceChecked] = useState(false);
  const {setIsSignedIn} = useContext(SignInContext);
  const {setCurrentUser} = useContext(AuthContext);
  const renderedPosition = formDetails.position.map((name, index)=><Radio value={name} key={`source ${index}`} className='reg-form__option'>{name}</Radio>)
  const renderedIndustry = formDetails.industry.map((name, index)=><Radio value={name} key={`source ${index}`} className='reg-form__option'>{name}</Radio>)
  const renderedSource = formDetails.source.map((name, index)=><Radio value={name} key={`source ${index}`} className='reg-form__option'>{name}</Radio>)

  const updateUser = async() => {
    setLoading(true);
    const user = firebase.auth().currentUser;
    const updatedIndustry = industry === "specialVal" ? otherIndustry : industry;
    const updatedPosition = position === "specialVal" ? otherPosition : position;
    const updatedSource = source === "specialVal" ? otherSource : source;
    if (user){
      try {
        const docRef = db.collection("customers").doc(user.uid);
        await docRef.get().then(doc => {
          if (doc.exists) {
            docRef.update({
              industry: [updatedIndustry], 
              source: updatedSource, 
              position: [updatedPosition], 
              name: user.displayName,
              registration_date: user.metadata.creationTime,
              uid: user.uid,
            })
          }
        }).catch(e =>{
          console.log(e);
        })

        setCurrentUser(user);
        setLoading(false);
        setIsSignedIn(true);
      } catch(e) {
        setErrorMessage("Internal error");
        console.log(e);
        setLoading(false);
      } 
    }
  }
  return <form className='reg-form' onSubmit={(e)=>{
    e.preventDefault();
    if (!source || !industry || !position || (industry === "specialVal" && !otherIndustry) || (position === "specialVal" && !otherPosition) || (source === "specialVal" && !otherSource)) {
      window.scrollTo({left: 0, top: 0, behavior: "smooth"});
      setErrorMessage("Please choose at least one option for each question");
    } else {
      updateUser();
    }
  }}>
    <span className='reg-form__error'>{errorMessage}</span>
    <div className='reg-form__question'>
      <h3 className='reg-form__title'>1. What best describes your role? <span className='reg-form__info'>(You can select more than one)</span></h3>
      <Radio.Group className='reg-form__options' onChange={(e)=>{
          setOtherPositionChecked(e.target.value === "specialVal");
          setPosition(e.target.value);
        }}>
        {renderedPosition}
        <Radio value="specialVal" className='reg-form__option'>
          <input disabled={!otherPositionChecked} className='reg-form__other' value={otherPosition} onChange={(e)=>setOtherPosition(e.target.value)} placeholder="Other..."/>
        </Radio>
      </Radio.Group>
    </div>
    <div className='reg-form__question'>
      <h3 className='reg-form__title'>2. What industry do you work in? <span className='reg-form__info'>(You can select more than one)</span></h3>
      <Radio.Group className='reg-form__options' onChange={(e)=>{
          setOtherIndustryChecked(e.target.value === "specialVal");
          setIndustry(e.target.value);
        }}>
        {renderedIndustry}
        <Radio value="specialVal" className='reg-form__option'>
          <input disabled={!otherIndustryChecked} className='reg-form__other' value={otherIndustry} onChange={(e)=>setOtherIndustry(e.target.value)} placeholder="Other..."/>
        </Radio>
      </Radio.Group>
    </div>
    <div className='reg-form__question'>
      <h3 className='reg-form__title'>3. Last question, how did you hear about us? </h3>
      <Radio.Group className='reg-form__options' onChange={(e)=>{
          setOtherSourceChecked(e.target.value === "specialVal");
          setSource(e.target.value);
        }}>
        {renderedSource}
        <Radio value="specialVal" className='reg-form__option'>
          <input disabled={!otherSourceChecked} className='reg-form__other' value={otherSource} onChange={(e)=>setOtherSource(e.target.value)} placeholder="Other..."/>
        </Radio>
      </Radio.Group>
    </div>
    <button className='reg-form__button' type="submit">{loading ? "Loading..." : "Save and Continue"}</button>
    <img alt="" src="https://ct.capterra.com/capterra_tracker.gif?vid=2201525&vkey=ff43696adfdfe66d0949484b4a81165d" className='trigger' /> {/* Capterra conversion tracking trigger */}
  </form>
};

export default RegistrationForm;
