import React from 'react';
import {logoUrl} from "pages/Login/Login";

const url = (process.env.NODE_ENV === "development") ? "http://localhost:3000/" : "https://app.articleninja.ai/";

const Logo = ({className, source}) => (
  <div className={`${className} logo`}>
    {source === "login" ? 
    <img src={logoUrl} alt="ArticleNinja.ai"/>
    :
    <a href={url}>
      <img src={logoUrl} alt="ArticleNinja.ai"/>
    </a>
    }
  </div>
);

export default Logo;