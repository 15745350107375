export const blackListCountries = {
  "India" : true, 
  "Pakistan" : true,
  "Bangladesh" : true,
  "Nigeria" : true,
  "Indonesia" : true,
  "Philippines" : true,
  "Nepal" : true,
  "Kenya" : true,
  "Morocco" : true,
  "Sri Lanka" : true,
  "Ghana" : true,
  "Uganda" : true,
  // "Kazakhstan" : true
}