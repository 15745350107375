import React, { useEffect, useState, useContext } from "react";
import Modal from "components/Modal";
import Input from "components/Input";
import ArticleResult from "components/ArticleResult";
import InputWrapper from "components/InputWrapper";
import DashboardFormChangeLanguage from "components/DashboardFormChangeLanguage";
import Button from "components/Button";
import ArticleBriefExamples from "components/ArticleBriefExamples"
import KeywordsExamples from "components/KeywordsExamples"
import generatedText from "store/generatedText";
import {Radio} from "antd";
import { observer } from "mobx-react-lite";
import { createText } from "../../fireFunctions";
import { toast } from "react-toastify";
import "./styles.scss";
import { ReactComponent as RegenerateIcon} from "assets/icons/regenerate.svg"
import {ReactComponent as SettingsIcon} from "assets/icons/settings.svg";
import { db } from "pages/Login/GoogleProvider";
import { AuthContext } from "Auth";
const textStyles = ["Standard", "Marketing", "Personal", "Business", "Blog"];
const toolName = "Article Generation 2.0";
export default observer(({ 
    freeDays, 
    coins, 
    typeWords,
    words,
    teamWords,
    subscription, 
    uid, 
    coinsReduce, 
    save,
    onChangeSpecialSection,
    setPlagiarismContent,
    mode,
    teamCoins,
    loadCoins,
    teamInfo
  }) => {
  useEffect(() => {
    return () => {
      generatedText.setResults([]);
    };
  }, []);
  const [loadingOutline, setLoadingOutline] = useState(false);
  const [generateArticle, setGenerateArticle] = useState(false);
  const [completed, setCompleted] = useState(0);
  const [showLanguageModal, toggleLanguageModal] = useState(false);
  const [coinsCounter, setCoinsCounter] = useState(1);

  //Article Brief examples
  const [articleBriefExamples, setArticleBriefExamples] = useState(true);
  const [showArticleBriefModal, setShowArticleBriefModal] = useState(false);
  
  //Keywords examples
  const [keywordsExamples, setKeywordsExamples] = useState(true);
  const [showKeywordsModal, setShowKeywordsModal] = useState(false);
  
  const [otherTag, setOtherTag] = useState("");
  const [tag, setTag] = useState("Standard");
  const {currentUser} = useContext(AuthContext);
  const [loadingTagSubmit, setLoadingTagSubmit] = useState(false);

  useEffect(() => {
    if(generatedText.loading) {
      const interval = setInterval(() => {
        setCompleted((prevState) => {
          const newValue = prevState + 1;
          if(newValue === 100) {
            clearInterval(interval);
          }
          return newValue;
        });
      }, 60);
    }
  }, [generatedText.loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const { description, name, keywords, inputLanguage, outputLanguage } = generatedText;

  const onInputLanguageChange = (language) => {
    generatedText.changeInputLanguage(language);
  };

  const onOutputLanguageChange = (language) => {
    generatedText.changeOutputLanguage(language);
  };

  useEffect(() => {
    const subtopics = generatedText.outline.split("\n").filter(
        (subtopic) => subtopic.trim().length > 0);
    setCoinsCounter(subtopics.length);
  }, [generatedText.outline]) // eslint-disable-line react-hooks/exhaustive-deps

  const onDescriptionChange = (e) => {
    const value = e.target.value;
    generatedText.changeDescription(value);
  };
  const onOutlineChange = (e) => {
    const value = e.target.value;
    generatedText.changeOutline(value);
  };
  const onNameChange = (e) => {
    const value = e.target.value;
    generatedText.changeName(value);
  };
  const onKeywordsChange = (e) => {
    const value = e.target.value;
    generatedText.changeKeywords(value);
  };
  const resetFields = () => {
    setGenerateArticle(false);
  };
  const onSubmitOutline = async(e) => {
    e.preventDefault();
    const amount =  generatedText.outline.split("\n").filter(subtopic => subtopic.trim() !== "").length;
    if ((((mode === "personal" && coins < amount) || (mode === "team" && teamCoins < amount)) && !typeWords) ||
      (((mode === "personal" && words < amount*300) || (mode === "team" && teamWords < amount*300)) && typeWords)) {
      toast.error(
        "Insufficient balance. Please, upgrade your account or reduce your outline manually"
      );
      return;
    }
    if (
      freeDays <= 0 &&
      subscription === "Free Trial"
    ) {
      toast.error("Free trial has expired");
      return;
    }
    if (generatedText.loading || loadingOutline) {
      return;
    }
    if(typeWords) {
      const {wordsReturned, addWordsReturned} = await loadCoins(mode === "personal" ? currentUser.uid : teamInfo.owner_id);
      if (wordsReturned + addWordsReturned < amount*300) {
        toast.error("Insufficient balance");
        return;
      }
    } else {
      const {coinsReturned, addCoinsReturned} = await loadCoins(mode === "personal" ? currentUser.uid : teamInfo.owner_id);
      if (coinsReturned + addCoinsReturned < amount) {
        toast.error("Insufficient balance");
        return;
      }
    }
    if (!generatedText.description) {
      toast.error("no article brief");
      return;
    }
    generatedText.changeLoading(true);
    setCompleted(0);
    const data = {
      tag: tag.toLowerCase(),
      outline: generatedText.outline,
      description: generatedText.description,
      name: generatedText.name,
      basicVersion: true,
      versionNo: 2,
      keywords: generatedText.keywords,
      inputLanguage: generatedText.inputLanguage,
      outputLanguage: generatedText.outputLanguage,
    };
    createText(data)
      .then((result) => {
        const newText = result.data.generatedText;
        generatedText.changeLongText(newText);
        setGenerateArticle(true);
        if(typeWords) {
          let wordsCounter = generatedText.outlineLength;
          newText.forEach((data)=> {
            wordsCounter += data.text.split(/\n| /).filter(subtext => subtext.trim() !== "").length;
          })
          coinsReduce(wordsCounter);
        } else {
          coinsReduce(coinsCounter);
        }
      })
      .catch((error) => {
        console.log(error);
        generatedText.changeLongText([{ text: "", outline: "" }]);
        toast.error("Request timed out, try shorter outline");
      })
      .finally(() => {
        generatedText.changeLoading(false);
      });
  };
  const onSubmit = async(event) => {
    event.preventDefault();
    if ((((mode === "personal" && coins <= 0) || (mode === "team" && teamCoins <= 0)) && !typeWords) ||
      (((mode === "personal" && words <= 30) || (mode === "team" && teamWords <= 30)) && typeWords)) {
      toast.error("Insufficient balance");
      return;
    }
    if (freeDays <= 0 && subscription === "Free Trial") {
      toast.error("Your trial has expired");
      return;
    }
    if (generatedText.loading || loadingOutline) {
      return;
    }
    if(typeWords) {
      const {wordsReturned, addWordsReturned} = await loadCoins(mode === "personal" ? currentUser.uid : teamInfo.owner_id);
      if (wordsReturned + addWordsReturned <= 30) {
        toast.error("Insufficient balance");
        return;
      }
    } else {
      const {coinsReturned, addCoinsReturned} = await loadCoins(mode === "personal" ? currentUser.uid : teamInfo.owner_id);
      if (coinsReturned + addCoinsReturned < 1) {
        toast.error("Insufficient balance");
        return;
      }
    }
    setLoadingOutline(true);
    const data = {
      toolName: "Essay Outline",
      description: generatedText.description,
      inputLanguage: generatedText.inputLanguage,
      outputLanguage: generatedText.outputLanguage,
      isOutline: true,
      hasDescription: true,
    };
    createText(data)
      .then((result) => {
        generatedText.setResultsMore(result.data.generatedText);
        generatedText.changeOutline(
          generatedText.results.length
            ? generatedText.results[0].text.slice(1)
            : ""
        );
        if(typeWords) {
          generatedText.changeOutlineLength(result.data.generatedText[0].text.split(/\n| /).filter(subtopic => subtopic.trim() !== "").length);
          coinsReduce(generatedText.outlineLength);
        } else {
          coinsReduce(1);
        }
      })
      .catch((error) => {
        if (error.message) {
          toast.error(error.message);
        }
        generatedText.changeError(error);
      })
      .finally(() => setLoadingOutline(false));
  };
  const renderedTags = textStyles.map(
    (name, index)=><Radio.Button value={name} key={`tag ${index}`} 
    className='article-form__option'>
      <span className="article-form__option__label">{name}</span>
    </Radio.Button>)

  const input = {
    title: generatedText.name,
    description: generatedText.description,
    keywords: generatedText.keywords,
    tag: tag,
  }

  return (
    <div>
    <section>
        {!generateArticle ? 
          <div className="article-form">
            <form className="article-form__content" onSubmit={onSubmit}>
              <div className="layout-content-block article-form__block">
                <div className="dashboard-form__input">
                  <InputWrapper
                      label="1. Styles"
                      >
                    <Radio.Group defaultValue="Standard" buttonStyle="solid" className='article-form__options' onChange={(e)=>{
                      setTag(e.target.value);
                    }}>
                      {renderedTags}
                    </Radio.Group>
                  </InputWrapper>
                  <div className='article-form__other'>
                    <input className='article-form__other tag' value={otherTag} 
                        onChange={(e)=>setOtherTag(e.target.value)} placeholder="suggest style..."/> 
                    <button className='article-form__other submit' onClick={async(e)=>{
                      e.preventDefault();
                      if (loadingTagSubmit || !otherTag) {
                        return;
                      }
                      setLoadingTagSubmit(true);
                      await db.collection("tagSuggestions").add({
                        email: currentUser.email,
                        userid: currentUser.uid,
                        date: new Date(),
                        name: otherTag
                      }).then(()=>{
                        toast(`Successfully submitted tag: ${otherTag}. Thank you for your suggestion!`);
                        setOtherTag("");
                      }).catch((e)=>{
                        console.log(e.message);
                        toast.error("Could not submit the suggested style. Please try again");
                      }).finally(()=>{
                        setLoadingTagSubmit(false);
                      });
                    }}>{loadingTagSubmit ? "Loading.." : "Submit"}</button>
                  </div>
                </div>
                <div className="dashboard-form__input">
                  <InputWrapper
                    label="2. Article Title"
                    allowClear={true}
                    value={name}
                    onChange={onNameChange}
                    required={true}
                  >
                    <Input
                      placeholder="ex.: AI powered text generation"
                      maxLength={100}
                      value={name}
                      onChange={onNameChange}
                      required={true}
                    />
                  </InputWrapper>
                </div>
                <InputWrapper
                  label="3. Article Brief"
                  value={description}
                  onChange={onDescriptionChange}
                  allowClear={true}
                  required={true}
                  examples={articleBriefExamples}
                  showButton={setArticleBriefExamples}
                  showExamples={setShowArticleBriefModal}
                >
                  <div className="dashboard-form__textarea-audience">
                    <Input
                      tag="textarea"
                      value={description}
                      onChange={onDescriptionChange}
                      maxLength={300}
                      required={true}
                      placeholder="Provide the AI with a brief of what you are writing about, describe
            it like you are speaking to a friend. 20-30 words."
                    />
                  </div>
                  </InputWrapper><br/>
                <InputWrapper
                label="4. Keywords"
                value={keywords}
                onChange={onKeywordsChange}
                allowClear={true}
                required={true}
                examples={keywordsExamples}
                showButton={setKeywordsExamples}
                showExamples={setShowKeywordsModal}
                uid={uid}
                subscription={subscription}
              >
                <div className="dashboard-form__input-audience">
                  <Input
                    tag="textarea"
                    value={keywords}
                    onChange={onKeywordsChange}
                    maxLength={300}
                    required={true}
                    placeholder="Provide comma-separated keywords you want to see in the article. Example: NLP,  text,  AI"
                  />
                </div>
                </InputWrapper>
                <div className="article-form__buttons">
                  <Button
                    IconEl={SettingsIcon}
                    onClick={() => toggleLanguageModal(true)}
                    tool={toolName}
                  >
                    Languages
                  </Button>
                  {showLanguageModal ? (
                    <Modal onClick={() => toggleLanguageModal(false)}>
                      <DashboardFormChangeLanguage
                        inputLanguage={inputLanguage}
                        outputLanguage={outputLanguage}
                        onInputLanguageChange={onInputLanguageChange}
                        onOutputLanguageChange={onOutputLanguageChange}
                        onClose={() => toggleLanguageModal(false)}
                        outline={true}
                      />
                    </Modal>
                  ) : null}
                  <Button
                    type="submit"
                    loading={loadingOutline}
                    disabled={loadingOutline}
                    isBlue={true}
                    coins={!typeWords ? 1 : null}
                    tool={toolName}
                  >
                    {loadingOutline ? "Loading.." : "Create Outline"}
                  </Button>
                </div>
              </div>
            </form>
            <form
                onSubmit={onSubmitOutline}
                className="article-form__content"
              >
                <div
                  className={`layout-content-block article-form__block ${
                    !generatedText.outline && "inactive"
                  }`}
                >
                  <InputWrapper
                    label="5. Outline"
                    value={generatedText.outline}
                    onChange={onOutlineChange}
                    allowClear={true}
                    maxLength={1000}
                    required={true}
                  >
                    <div
                      className={`article-form__textarea-description 
                 ${!generatedText.outline && "inactive"}`}
                    >
                      <Input
                        tag="textarea"
                        value={generatedText.outline}
                        onChange={onOutlineChange}
                        maxLength={1000}
                        required={true}
                      />
                    </div>
                  </InputWrapper>

                  <div className="article-form__buttons">
                    <Button
                      type="button"
                      onClick={onSubmit}
                      loading={generatedText.loading}
                      disabled={generatedText.loading}
                      isYellow={generatedText.outline}
                      coins={!typeWords ? 1 : null}
                      tool={toolName}
                    >
                      {loadingOutline ? "Loading.." : "Regenerate"}
                      <RegenerateIcon className="regenerateIcon"/>
                    </Button>
                    <Button
                      type="submit"
                      loading={generatedText.loading}
                      disabled={generatedText.loading}
                      isGreen={generatedText.outline}
                      coins={!typeWords ? coinsCounter : null}
                      tool={toolName}
                    >
                      {generatedText.loading
                        ? <div className="progressBar">
                            <progress value={completed} max={100} />
                            <span>{completed}%</span>
                          </div>
                        : "Create Text"}
                    </Button>
                  </div>
                </div>
              </form>
          </div>
        : 
          <ArticleResult
            content={generatedText.longText}
            input={input}
            reset={resetFields}
            save={save}
            version={2}
            onChangeSpecialSection={onChangeSpecialSection}
            setPlagiarismContent={setPlagiarismContent}
          /> 
        }
      </section>
      {showArticleBriefModal &&
        <Modal 
          onClick={() => {setShowArticleBriefModal(false); setArticleBriefExamples(true);}}
          colorOverflow={"rgba(49, 52, 58, 0.212)"}
        >
          <ArticleBriefExamples />
        </Modal>
      }
      {showKeywordsModal &&
        <Modal 
          onClick={() => {setShowKeywordsModal(false); setKeywordsExamples(true);}}
          colorOverflow={"rgba(49, 52, 58, 0.212)"}
        >
          <KeywordsExamples />
        </Modal>
      }
    </div>
  );
});
