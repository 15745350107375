import ButtonClose from "components/ButtonClose";
import './styles.scss';

const InputWrapper = ({
  label,
  value = '',
  required = false,
  maxLength = 0,
  onChange,
  allowClear,
  children,
  examples,
  showButton,
  showExamples,
  uid,
  subscription
}) => {
  const showClearButton = value.length && allowClear;
  function onClear() {
    const event = {
      target: {
        value: "",
      },
    };
    onChange(event);
  }

  return (
    <label className="input-wrapper">
      <div className="input-wrapper__header iheader">
        <p className="iheader__label">
          {label}
          {required ? <span className="iheader__required">*</span> : null}
          {examples && <span className="iheader__button" onClick={() => {showButton(false); showExamples(true);}}>?</span>}
        </p>
        {maxLength ? <span className="iheader__counter">{value.length}/{maxLength}</span> : null }
        {/* {subscription ?
          <span className="input-wrapper__link"
            onClick={()=>{
              Mixpanel.track('SEO Keywords');
              window.open(`https://us-central1-texta-ai.cloudfunctions.net/loginSSO/${uid}`, '_blank');
            }}>
            SEO Keywords
          </span> : null} */}
      </div>
      <div className="input-wrapper__input-container">
        {children}
        {showClearButton ? (
          <ButtonClose
            onClick={onClear}
            className="input-wrapper__close-button"
          />
        ) : null}
      </div>
    </label>
  );
};

export default InputWrapper;
