import React, { useContext, useState } from "react";
import Layout from "antd/es/layout";
import Logo from "../Logo";
import Row from "antd/es/row";
import Input from "components/Input";
import InputWrapper from "components/InputWrapper";
import Space from "antd/es/space";
import { Link, useHistory } from "react-router-dom";
import Text from "antd/es/typography/Text";
import Divider from "antd/es/divider";
import ButtonSecondary from "../Button";
import Button from "antd/es/button";
import app from "../../base";
import { LogoutOutlined, UserOutlined } from "@ant-design/icons";
import { AuthContext } from "../../Auth";
import Avatar from "antd/es/avatar/avatar";
import Image from "antd/es/image";
import { Spin } from "antd";
import "./index.scss";
import { toast } from "react-toastify";
export const db = app.firestore();

// eslint-disable-next-line
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}
const { Header, Content, Footer } = Layout;
const PromocodeForm = () => {
  const { currentUser, name } = useContext(AuthContext);
  const history = useHistory();
  const [promocode, setPromocode] = useState("");
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [checkTerms, setCheckTerms] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    let isPremium = false;
    if (!checkTerms) {
      return;
    }
    if (!promocode || promocode.length < 10) {
      toast.error("Promocode can't be empty or less than 10 characters");
      return;
    }
    if (promocode.length === 11) {
      isPremium = true;
    }
    const result = db.collection("promocodes").doc(promocode);
    setLoading(true);
    await result
      .get()
      .then((data) => {
        if (data.exists) {
          const source = data.data().source || "unknown";
          if (source === "unknown") { 
            toast.error("Promocode is not active");
            return;
          }
          let multiplier = null;
          let counter = 0;
          let counter_premium = 0;
          if(isPremium) {
            counter_premium++;
          } else {
            counter++;
          }
          let promocodes = [];
          let promocodes_status = [];
          let usedPremium = isPremium;
          const lifeTimerRef = db.collection("lifeTimers").doc(currentUser.uid);
          lifeTimerRef.get().then(async doc => {
            if (doc.exists) {
              promocodes = doc.data().promocodes;
              promocodes_status = doc.data().promocodes_status;
              usedPremium = doc.data().usedPremium ? doc.data().usedPremium : isPremium;
              // multiplier = doc.data().promocodes_number ? doc.data().promocodes_number + 1 : 1;

              if(doc.data().promocodes_status) {
                doc.data().promocodes_status.forEach((map)=>{
                  if(map.isPremium) {
                    counter_premium++;
                  } else {
                    counter++;
                  }
                })
              } else {
                multiplier = doc.data().promocodes_number ? doc.data().promocodes_number + 1 : 1;
              }
            }
          }).then(()=>{
            lifeTimerRef
              .set({ uid: currentUser.uid, email: currentUser.email, source, promocodes: [...promocodes, promocode], promocodes_status: [...promocodes_status, {promocode, isPremium}], last_promocode_premium: isPremium, usedPremium: usedPremium, start_date: new Date(), promocodes_number: multiplier ? multiplier :  counter, promocodes_number_premium:  counter_premium}, {merge: true})
              .then(() => {
                db.collection("promocodes")
                  .doc(promocode)
                  .delete()
                  .then(() => {
                    setSuccess(true);
                  });
              })
              .catch((e) => {
                console.log(e);
              });
          });
        } else {
          toast.error("Promocode is not active");
        }
      })
      .catch((e) => {
        console.log(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const avatar = currentUser.picture ? (
    <Avatar src={<Image src={currentUser.picture} />} />
  ) : (
    <Avatar icon={<UserOutlined />} />
  );

  return (
    <Layout style={{ minHeight: "100vh" }} theme="light">
      <Header className="site-layout-background" style={{ padding: 0 }}>
        <Row
          justify="space-between"
          style={{ marginRight: "16px" }}
          align="middle"
        >
          <Logo />
          <Space>
            <Link to="/profile">
              <Space>
                {avatar}
                <Text>{name}</Text>
              </Space>
            </Link>
            <Divider type="vertical" />
            <Button
              onClick={() => app.auth().signOut()}
              icon={<LogoutOutlined />}
            >
              Log out
            </Button>
          </Space>
        </Row>
      </Header>
      <Layout className="site-layout">
        <Spin tip="Loading..." spinning={loading}>
          <Content style={{ padding: "0 50px" }}>
            <div>&nbsp;</div>
            <div className="site-card-wrapper">
              <div className="site-card-wrapper__form">
                {success ? (
                  <form
                    className="card-form__content"
                    onSubmit={() => history.push("/")}
                  >
                    <Row>
                      <h1>Congrats! You are now a life time subscriber. Credits will be given in a minute</h1>
                    </Row>
                    <ButtonSecondary
                      // type="primary"
                      // isBlue={true}
                      onClick={() => window.location.reload()}
                    >
                      Redeem more promocodes
                    </ButtonSecondary>
                    <br />
                    <ButtonSecondary
                      type="primary"
                      isBlue={true}
                      onClick={() => history.push("/")}
                    >
                      Go to main
                    </ButtonSecondary>
                  </form>
                ) : (
                  <form className="card-form__content" onSubmit={handleSubmit}>
                    <InputWrapper
                      label="Enter promocode"
                      allowClear={true}
                      maxLength={11}
                      required={true}
                      value={promocode}
                      onChange={(e) => setPromocode(e.target.value)}
                    >
                      <div className={"card-form__textarea-description"}>
                        <Input
                          tag="input"
                          maxLength={11}
                          required={true}
                          value={promocode}
                          onChange={(e) => setPromocode(e.target.value)}
                        />
                      </div>
                    </InputWrapper>
                    <div className="terms">
                    <input type="checkbox" id="terms" name="terms" onClick={()=>setCheckTerms(!checkTerms)}/>
                    <label htmlFor="terms">I have read and agree with the <a href="https://www.articleninja.ai/terms-and-conditions" rel="noreferrer" target="_blank" > Terms and Conditions</a></label></div>
                    <div className="card-form__buttons">
                      <ButtonSecondary
                        className="btn"
                        type="secondary"
                        size="large"
                        isBlue={false}
                        onClick={() => history.push("/")}
                      >
                        Cancel
                      </ButtonSecondary>
                      <ButtonSecondary
                        className="btn"
                        type="primary"
                        size="large"
                        isBlue={checkTerms}
                        onClick={handleSubmit}
                      >
                        Apply
                      </ButtonSecondary>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </Content>
        </Spin>
        <Footer style={{ textAlign: "center" }}></Footer>
      </Layout>
    </Layout>
  );
};

export default PromocodeForm;
