import firebase from 'firebase/app'
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyAr7dDC2u7U_FpaNqJuS_hJhUDPIVl_bPY",
  authDomain: "articleninja-ai.firebaseapp.com",
  projectId: "articleninja-ai",
  storageBucket: "articleninja-ai.appspot.com",
  messagingSenderId: "611869510955",
  appId: "1:611869510955:web:f3bd15aae52bb7ee15096e"
};

const app = firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default app;