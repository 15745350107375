import React from "react";
import {Space} from "antd";
import Button from "components/Button";
import generatedText from "store/generatedText";
import { ReactComponent as RegenerateIcon} from "assets/icons/regenerate.svg";
import "./styles.scss";
import ProjectManagement from "components/ProjectManagement";
// import { toast } from "react-toastify";s
const ArticleResult = ({ reset, content, input, save, onChangeSpecialSection, setPlagiarismContent, version }) => {
  const renderedContent = content.map(({ text, outline }) => {
    const textSplit = text.split("\n");
    const textRendered = [];
    for (let i = 0; i < textSplit.length; i++) {
      if (textSplit[i]) {
        textRendered.push(
          <div key={i} className="subtopic">
            {textSplit[i]}
          </div>
        );
      }
    }

    return (
      <div key={outline}>
        <div className="outline">{outline}</div>
        <br />
        <div>{textRendered}</div>
        <br />
      </div>
    );
  });
  // const sendToPlagiarism = () => {
  //   let resultText = "";
  //   content.map(({ text, outline }) => {
  //     resultText += text;
  //     return resultText;
  //   });
  //   if(resultText !== "\n") {
  //     setPlagiarismContent(resultText);
  //     onChangeSpecialSection("plagiarism");
  //   } else {
  //     toast.error("Cannot proceed empty input!");
  //   }
  // }
  return (
    <div className="layout-content">
      <div className="layout-content__text">
        <div>{renderedContent}</div>
      </div>
      <div className="layout-content__buttons">
      {version === 1 ? 
            <div className="buttons">
              <Button className="span-large" title="Reset" isBlue="true" onClick={()=>{reset();}}>
                <Space>
                  Regenerate
                  <RegenerateIcon className="regenerateIcon"/> 
                </Space>
              </Button>
              {/* <Button className="span-large" title="Reset" isGreen="true" onClick={()=>{sendToPlagiarism();}}>
                Check Plagiarism
              </Button> */}
              <ProjectManagement 
                save={save}
                content={content}
                input={input}
              />
            </div>
            :
            <div className="buttons">
              <Button className="span-large" onClick={()=> {
                generatedText.changeLongTextStringFromLongText(content);
                onChangeSpecialSection("beast-mode");
                }}
                isBlue="true">
                Copy to editor
              </Button>
              <Button className="span-small" title="Reset" onClick={()=>{reset()}}>
                <Space>
                  <RegenerateIcon className="regenerateIcon"/> 
                </Space>
              </Button>
              {/* <Button className="span-large" title="Reset" isGreen="true" onClick={()=>{sendToPlagiarism();}}>
                Check Plagiarism
              </Button> */}
              <ProjectManagement 
                save={save}
                content={content}
                input={input}
              />
            </div>
          }
      </div>
    </div>
  );
};
export default ArticleResult;
