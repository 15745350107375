import React, { useState, useEffect } from "react";
import Video from "../Video";
import "./index.scss";
import app from "../../base";
export const db = app.firestore();

const Tutorials = () => {
  const [videos, setVideos] = useState([]);
  useEffect(() => {
    const search = async () => {
      await db
        .collection("tutorials")
        .get()
        .then((snapshot) => {
          let result = [];
          snapshot.forEach((video) => {
            if (video.data()) {
              result.push(video.data());
            }
          });
          setVideos(result);
        })
        .catch((e) => {
          console.log(e);
        });
    };
    search();
  }, []);
  const renderedVideos = videos.map((video) => {
    return (
      <div className="video" key={video.url}>
        <Video url={video.url} title={video.title} />
        <label className="title">{video.title}</label>
      </div>
    );
  });
  return <div className="tutorials">{renderedVideos}</div>;
};

export default Tutorials;
