import {useContext, useState} from "react";
import {
  ArrowRightOutlined,
  CheckCircleTwoTone,
  LoadingOutlined, 
  CloseCircleTwoTone
} from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { AuthContext } from "Auth";
import { createCheckoutSession } from "createCheckoutSession";
// import {endTrial} from "fireFunctions";

export const PricingCard = ({plan  : {name, price, description, role, price_id, additional}, subscription, isWhiteUser, annual}) => {
  const [loadingCheckout, setLoadingCheckout] = useState(false);
  const {currentUser} = useContext(AuthContext);
  const history = useHistory();
  const renderedDescription = description.map((feature, index) => {
    if (index === 0) {
      return <br key={`${name} ${index}`}/>;
    }
    return <div key={`${name} ${index}`}>
      {index === 1 || index === 2 ? 
        <div className="primary">
          <CheckCircleTwoTone twoToneColor="#fa8314bb" /> {feature}
        </div> 
        : 
        <div>
          <CheckCircleTwoTone twoToneColor="#2b5ceebb" /> {feature}
        </div>}
    </div>
  })
  let btnText;
  if ((subscription && subscription.role === role)){
    btnText = "Current";
  } else {
    btnText = "Buy Now";
  }

  const handleClick = async()=>{
    if (btnText === "Buy Now") {
      setLoadingCheckout(true);
      createCheckoutSession(currentUser.uid, price_id, "subscription");
    } else {
      history.push("/");
    }
    // if (price_id && isWhiteUser) {
    //   createCheckoutSession(currentUser.uid, price_id_white, "subscription_white");
    // } else if (price_id && btnText === "Try For $7"){
    //   createCheckoutSession(currentUser.uid, price_id, "subscription");
    // } else {
    //   if (btnText === "Buy Now"){
    //     try {
    //       await endTrial({subId: subscription.id});
    //       history.push("/success");
    //       return;
    //     } catch (e) {
    //       console.log(e);
    //       setLoadingCheckout(false);
    //     }
    //   } else {
    //     history.push("/");
    //   }
    // }
  }
  return <div className="card-wrapper">
    <div
      className="card-wrapper__centered"
    >
      <h3>{name}</h3>
      <div className="card-wrapper__price">
        <span>$</span>
        <span className="price">{price}</span>
        <div className="vertical">
          <span>per user</span>
          <span>{annual ? "per year" : "per month"}</span>
        </div>
      </div>
      <button
        onClick={()=>{handleClick();}}
        className={`card-button ${btnText === "Buy Now" && "upgrade"} ${btnText === "Current" && "current"}`}
      >
        {btnText}{loadingCheckout ? <LoadingOutlined/> : btnText !== "Current" && <ArrowRightOutlined/>}
      </button> 
      <div className="description">{description[0]}</div> 
    </div>
    <div className="card-wrapper__leftAligned">
      {renderedDescription}
      {additional && <div className="primary"><CloseCircleTwoTone twoToneColor="#51e2d6" /> {additional}</div>}
    </div>
    
  </div>
}