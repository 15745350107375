import React, {useState, useEffect, useContext} from "react";
import "./index.scss";
import {Space, Spin} from "antd";
import Button from "components/Button";
import InputWrapper from "components/InputWrapper";
import Input from "components/Input";
import app from "../../base";
import { AuthContext } from "../../Auth";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
export const db = app.firestore();

const Query = ({index, unique, query, similarities}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <>
      <span className={`${unique ? "" : "plagiarised"}`}>{query} </span>
      {!unique &&
        <div className={`similarities-container${expanded ? " expanded" : ""}`}>
          <span
            onClick={()=>{setExpanded(!expanded)}}
            className="expand-button"
          >
            <span className="label">{expanded ? "hide" : "expand"}</span>
          </span>
          {similarities}
        </div>
      }
    </>
  )
}

const PlagiarismChecker = (props) => {
  const { currentUser } = useContext(AuthContext);
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [queries, setQueries] = useState("");
  const [plagiarism, setPlagiarism] = useState(0);
  const [balance, setBalance] = useState(0);
  const usersRef = db
    .collection("customers")
    .doc(currentUser.uid);

  useEffect(() => {
    usersRef.get().then(async (doc) => {
      if (doc.exists) {
        if(doc.data().plagiarismChecks !== undefined) {
          setBalance(doc.data().plagiarismChecks);
        } else {
          if(doc.data().sub_type !== "Free Trial" && doc.data().sub_type !== "Lifetime Deal") {
            usersRef.update({plagiarismChecks: 5});
            setBalance(5);
          }
        }
      }
    }).catch((e) => {
      console.log(e);
    });
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  
  const sendData = async () => {
    if(balance === 0) {
      toast.error('Insufficient balance!');
      return false;
    }
    if(!props.content) {
      toast.error('Provide input!');
      return false;
    }
    setIsLoading(true);
    let data = props.content.replace(/([.?!\n])\s*(?=[A-Z])/g, '$1|').trim().split('|');
    if(data.length > 70) {
      toast('Input is too big! Only part of it will be checked');
      data = data.slice(0,70);
    }

    let resultResponse = [];
    await Promise.all(data.map(async(text, index) => {
      const apiKey = '8fc6a93653beef5622237172b9580974';
      const url = `https://www.prepostseo.com/apis/checkSentence`;
      await fetch(url, {
        body: `key=${apiKey}&query=${text}`,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        },
        method: "POST"
      }).then(function(response) {
        return response.json();
      }).then(function(data) {
        resultResponse[index] = data;
      });
    }));

    setTimeout(() => {
      if(resultResponse[0].error) {
        toast.error("Internal error occurred! Please contact support");
        console.log(resultResponse[0].error);
        setIsLoading(false);
        return false;
      }

      var nonUniqueQueries = 0;
      const renderedResults = resultResponse && resultResponse
        .map((element, index)=>{
          let similarities = null;
          if(!element.unique) {
            nonUniqueQueries += element.query.length;
            try {
              similarities = element && element.webs &&
                element.webs.map((web, index)=>{
                  return (
                    <div key={"similarities"+index} className="similarities">
                      <a href={web.url} target="_blank" rel="noopener noreferrer" className="link">{web.url.substring(0,50)+"..."}</a>
                      <hr />
                      <span><span className="match">title: </span>{web.title}</span>
                      <br />
                      <span><span className="match">description: </span>{web.desc}</span>
                    </div>
                  )
                });
            }catch (error) {
              setIsLoading(false);
              console.log(error);
            }
          }
          return (
            <div key={"query"+index}>
              <Query index={index} unique={element.unique} query={element.query} similarities={similarities} />
            </div>
          )
        })
    usersRef.get().then(async (doc) => {
      if (doc.exists) {
        usersRef.update({plagiarismChecks: balance-1}).then(()=>{
          setBalance(balance-1);
        })
      }
    }).catch((e) => {
      console.log(e);
    });
    setPlagiarism(~~(nonUniqueQueries/props.content.length*100));
    setQueries(renderedResults);
    setIsLoading(false);
    }, 1000);
  }

  return (
    <div className="plagiarism-content">
      <div className="plagiarism-content__text">
        <div className="plagiarism-content__text__buttons">
          <span className="balance">
            Balance:
            <span className="balance__value"> {balance} checks</span>
            <br />
            <span
              onClick={()=>{
                history.push({
                  pathname: "/profile",
                  state: { detail: "coins" }
                });
              }}
              className="buy-more-button"
            >
              <span className="label">Buy more</span>
            </span>
          </span>
          <Button className="span-save" title="Test" isGreen="true"
            onClick={()=>{sendData();}}
          >
            <Space>
              {isLoading ? <>Loading..<Spin /></> : "Check plagiarism"}
            </Space>
          </Button>
        </div>
        <form className="form-input">
          <InputWrapper
            allowClear={true}
            value={props.content}
            onChange={(e)=>{props.setContent(e.target.value)}}
          >
            <Input
              tag="textarea"
              placeholder="Provide text you want to be checked for plagiarism"
              maxLength={15000}
              value={props.content}
              onChange={(e)=>{props.setContent(e.target.value)}}
              required={true}
            />
          </InputWrapper>
        </form>
      </div>
      <div className="plagiarism-content__output">
        <div className="results">
          <div className="uniqueness">
            <span className="percentage">{queries ? 100-plagiarism : "..."}%</span>
            <br />
            <span>Uniqueness</span>
          </div>
          <div className="plagiarism">
            <span className="percentage">{queries ? plagiarism : "..."}%</span>
            <br />
            <span>Plagiarism</span>
          </div>
        </div>
        {queries}
      </div>
    </div>
  )
}

export default PlagiarismChecker;