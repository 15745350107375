import "./index.scss";
import { Collapse, message } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { LoadingOutlined } from "@ant-design/icons";
import { db } from "pages/Login/GoogleProvider";
import Button from "antd/es/button";
import { toast } from "react-toastify";
const { Panel } = Collapse;

export const validateEmail = (email) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};
export const TeamManagement = ({user: {currentUser}, source}) => {
  const [myTeam, setMyTeam] = useState(null);
  const [joinedTeam, setJoinedTeam] = useState(null);
  const [loadingOne, setLoadingOne] = useState(false);
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [secretCode, setSecretCode] = useState("");
  const [loadingSendEmail, setLoadingSendEmail] = useState(false);
  const [inviteEmail, setEmail] = useState("");
  const [blur, setBlur] = useState(true);
  const sendInvite = ()=>{
    const invite = async()=>{
      setLoadingSendEmail(true);
      if (!validateEmail(inviteEmail)) {
        toast.error("Invalid email");
        setLoadingSendEmail(false);
        return;
      }
      await db.collection("teams").doc(myTeam && myTeam.id).collection("invited")
        .add({
          email: inviteEmail, 
          status: "processing",
          ownerEmail: currentUser.email,
          ownerName: currentUser.displayName
        }).then(()=>{
          setLoadingSendEmail(false);
          toast("Email with secret code to " + inviteEmail + " sent");
          setEmail("");
        })
        .catch(e => {
          setLoadingSendEmail(false);
          toast.error("Error, please refresh the page and try again");
          console.log(e);
        });
    }
    invite();
  }
  const loadTeam = async(id)=>{
    const teamRef = db.collection("teams").doc(id);
    teamRef.get().then(async(teamDoc) => {
      if (teamDoc.exists) {
        const owner_id = teamDoc.data().owner_id;
        await teamRef.collection("members").get().then((membersSnap) =>{
          let allMembers = [];
          membersSnap.forEach(member =>{
            allMembers.push({email: member.data().email, id: member.id, role: member.data().role});
          })
          if (owner_id === currentUser.uid) {
            setMyTeam({
              members: allMembers,
              id: teamDoc.id,
              owner_id
            })
          } else {
            setJoinedTeam({
              members: allMembers,
              id: teamDoc.id,
              owner_id
            })
          }
        })
      }
    }).catch(e =>{
      console.log(e);
    })
  }

  useEffect(()=>{
    const findTeams = async()=>{
      const userRef = db.collection("customers").doc(currentUser.uid);
      userRef.get().then((userDoc) =>{
        const {member_of_team, member_team_id, owns_team, owns_team_id} = userDoc.data();
        if (member_of_team) {
          loadTeam(member_team_id);
        }
        if (owns_team) {
          loadTeam(owns_team_id);
        }
      })
    }
    findTeams(); // eslint-disable-next-line
  }, []);

  const removeMember = async(memberId)=>{
    setLoadingOne(true);
    try {
      await db.collection("teams")
        .doc(myTeam.id).collection("members")
        .doc(memberId).delete();
      await db.collection("customers").doc(memberId).update({member_of_team: false, member_team_id: ""});
      const filteredMembers = myTeam.members.filter((member => member.id !== memberId));
      setMyTeam({...myTeam, members: filteredMembers});
    } catch(e) {
      message.error("Error, please try again");
    }
    setLoadingOne(false);
  }

  const createTeam = async() => {
    setLoadingOne(true);
    try {
      const teamRef = db.collection("teams");
      await teamRef.add({owner_id: currentUser.uid}).then(async(doc)=>{
        await teamRef.doc(doc.id).collection("members").doc(currentUser.uid).set({email: currentUser.email, role: "owner"});
        await db.collection("customers").doc(currentUser.uid).update({owns_team: true, owns_team_id: doc.id})
        const storageRef = db.collection("storage").doc(doc.id);
        await storageRef.set({email: currentUser.email});
        await storageRef.collection("projects").add({name: "Project 1", text: []}).then((snap)=>{
          storageRef.update({currentProject: snap.id});
        })
        loadTeam(doc.id);
      })
    } catch(e) {
      message.error("Error, please try again");
    }
    setLoadingOne(false);
  }

  const joinTeam = async()=>{
    setLoadingTwo(true);
    if (!secretCode || !secretCode.trim()) {
      return;
    }
    try {
      const teamRef = db.collection("teams").doc(secretCode);
      await teamRef.get().then(async(teamDoc)=>{
        if (teamDoc.exists) {
          const membersRef = teamRef.collection("members");
          await membersRef.doc(currentUser.uid).get().then(async(member) => {
            if (member.exists) {
              message.error("You are already a member of this team");
            } else {
              await membersRef.doc(currentUser.uid)
                .set({
                  email: currentUser.email, 
                  role: "member"
                })
              await db.collection("customers").doc(currentUser.uid).update({
                member_of_team: true, 
                member_team_id: secretCode
              });
              loadTeam(secretCode);
            }
          })
        } else {
          message.error("Invalid Code");
        }
      })
    } catch (e) {
      console.log(e);
      message.error("Error. Please try again");
    }
    setLoadingTwo(false);
  }
  const exitTeam = async ()=>{
    setLoadingTwo(true);
    try {
      await db.collection("teams")
        .doc(joinedTeam.id).collection("members")
        .doc(currentUser.uid).delete();
      await db.collection("customers").doc(currentUser.uid).update({member_of_team: false, member_team_id: ""});
      setJoinedTeam(null);
    } catch(e) {
      message.error("Error, please try again");
    }
    setLoadingTwo(false);
  }
  return (
    <div className="team">
      <div className="team__collapse-menu">
        <Collapse
          bordered={false}
          defaultActiveKey={["1", "2"]}
          expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
          className="site-collapse-custom-collapse"
        >
          <Panel isActive={true} header="My team" key="1" className="site-collapse-custom-panel">
              {myTeam ? 
                <div>
                  <p><b>Secret code:   </b> 
                    <span 
                      className={`${blur ? "blurry-text" : "code"}`}
                      onClick={()=>{
                        if (!blur) {
                          navigator.clipboard.writeText(myTeam.id);
                          message.info("Code copied!");
                        }
                      }}
                    >{myTeam.id}</span>
                    <span className="show-btn" onClick={()=>{setBlur(!blur)}}>  Show</span>
                  </p>
                  <p><b>Members</b></p>
                  {myTeam.members.map(({id, email, role})=> {
                    return <div key={id} className="member-line">
                      <div>{email}</div>
                      <div><b>{role}</b></div>
                      {id !== myTeam.owner_id && 
                        <Button className="member-line__btn" onClick={()=>{removeMember(id)}}>
                          Remove {loadingOne && <LoadingOutlined/>}
                        </Button>
                      }
                    </div>
                  })}
                  <div>
                    <input className="code-input" placeholder="Enter email..." value={inviteEmail} onChange={(e)=>{setEmail(e.target.value)}} type="text"/>
                  </div>
                  <Button className="team-button" onClick={()=> {sendInvite()}}>Send invite {loadingSendEmail && <LoadingOutlined/>}</Button>
                  
                  </div>
                :
                <div>
                  <Button className="team-button" onClick={createTeam}>Create a team {loadingOne && <LoadingOutlined/>}</Button>
                </div>
              }
          </Panel>
          {source !== "dashboard" &&
            <Panel active={true} header="Team I joined" key="2" className="site-collapse-custom-panel">
              {joinedTeam ? 
                <div>
                  {joinedTeam.members.map(({id, email, role})=> {
                      return <div key={id} className="member-line">
                        <div>{email}</div>
                        <div><b>{role}</b></div>
                      </div>
                    })}
                    <Button className="team-button" onClick={()=>{exitTeam()}}>Exit Team {loadingTwo && <LoadingOutlined/>}</Button>
                </div>
                :
                <div>
                  <div>
                    <input className="code-input" placeholder="Insert a secret code..." value={secretCode} onChange={(e)=>{setSecretCode(e.target.value)}} type="text"/>
                  </div>
                  <Button className="team-button" onClick={()=> {joinTeam()}}>Join a team {loadingTwo && <LoadingOutlined/>}</Button>
                </div>
              }
            </Panel>
          }
        </Collapse>
      </div>
    </div>
  );
}
