import getStripe from "./initializeStripe";
import app from "./base";

const db = app.firestore();

export async function createCheckoutSession(uid, stripePrice, mode) {
  try {
    function getClientReferenceId() {
      return (
        (window.Rewardful && window.Rewardful.referral) ||
        "checkout_" + new Date().getTime()
      );
    }
    let data = {};
    switch (mode) {
      case "subscription":
        let query = localStorage.getItem("affiliate") || "";
        if (query) {
          query = "/?via=" + query;
        }
        data = {
          success_url: window.location.origin + "/success" + query,
          cancel_url: window.location.origin + query,
          allow_promotion_codes: true,
          client_reference_id: getClientReferenceId(),
          billing_address_collection: "auto",
          price: stripePrice,
          quantity: 1,
        }
        break;
      case "payment":
        data = {
          price: stripePrice,
          mode,
          success_url: window.location.origin + "/success",
          cancel_url: window.location.origin,
          client_reference_id: getClientReferenceId(),
          billing_address_collection: "auto",
        }
        break;
      default:
        return;
    }
    const checkoutSessionRef = await db
      .collection("customers")
      .doc(uid)
      .collection("checkout_sessions")
      .add(data);
    checkoutSessionRef.onSnapshot(async (snap) => {
      const { sessionId } = snap.data();
      if (sessionId) {
        // console.log(sessionId, "session Id");
        const stripe = await getStripe();
        stripe.redirectToCheckout({ sessionId });
      }
    });
  } catch (error) {
    console.log(error);
  }
}
