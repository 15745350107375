import React, {useState, useContext} from 'react';
import firebase from 'firebase';
import "./login.scss";
import app from '../../base';
import GoogleProvider from "./GoogleProvider";
import RegistrationForm from './RegistrationForm';
import { SignInContext } from './Login';
import {VerifiedEmailButton} from "./VerifiedEmailButton";
import { toast } from "react-toastify";
export const db = app.firestore();
const auth = firebase.auth();

function SignIn({setLoading}) {
  const [email, setEmail] = useState("");
  const [password, setPassword]= useState("");
  const [currentStep, setCurrentStep] = useState("one");
  const [errorMessage, setErrorMessage] = useState("");
  const [resetForm, setResetForm] = useState(false);
  const {setIsSignedIn} = useContext(SignInContext);
  const stepOne = <div className='login-form main'>
    <button className='login-form__button email' onClick={()=>{setCurrentStep("email")}}>Sign in with Email</button>
    <GoogleProvider setCurrentStep={setCurrentStep} setLoading={setLoading} setErrorMessage={setErrorMessage}/>
    </div>;

  const signInEmail = async(e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await auth.signInWithEmailAndPassword(email, password);
      if (!auth.currentUser.emailVerified) {
        await auth.currentUser.sendEmailVerification();
        setLoading(false);
        setCurrentStep("email-verification");
      } else {
        await db.collection("customers").doc(auth.currentUser.uid).get().then(userRef =>{
          if (userRef.exists && (userRef.data().reg_answer4 || userRef.data().source)) {
            setLoading(false);
            setIsSignedIn(true);
          } else {
            setLoading(false);
            setCurrentStep("reg-form");
          } 
        });
      }
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
      console.log(e);
    }; 
  }

  const sendResetEmail = async(e) => {
    e.preventDefault();
    try {
      setLoading(true);
      await auth.sendPasswordResetEmail(email).then(()=>{
        toast(`Reset password form was sent to ${email}!`);
        setResetForm(false);
        setLoading(false);
      })
    } catch (e) {
      setErrorMessage(e.message);
      setLoading(false);
      console.log(e);
    };
  }

  const emailForm = <form onSubmit={(e)=>{
      if(resetForm){
        sendResetEmail(e);
      } else {
        signInEmail(e);
      }
    }} className='login-form'>
    <div className='login-form__input'>
      <input id="email" placeholder="Email" type="email" value={email} onChange={(e)=>setEmail(e.target.value)} required/>
    </div>
    {!resetForm ?
      <div className='login-form__input'>
        <input id="psw" type="password" placeholder="Password" value={password} onChange={(e)=>setPassword(e.target.value)} required/>
      </div>
    : null}
    <span className='login-form__reset' onClick={()=>{
      setResetForm(!resetForm);
    }}>
      {resetForm ? "Back to Sign In form" : "Forgot password?"}
    </span>
    <span className='login-form__error'>{errorMessage}</span>
    <button className="login-form__button" type="submit">
      {resetForm ? "Reset password" : "Sign In"}
    </button>
  </form>;

  const renderPage = () => {
    switch (currentStep) {
      case "one":
        return stepOne;
      case "email":
        return emailForm;
      case "email-verification":
        return <VerifiedEmailButton setLoading={setLoading} setCurrentStep={setCurrentStep}/>;
      case "reg-form":
        return <RegistrationForm/>;
      default:
        return stepOne;
    }
  }
  return (renderPage());
};
export default SignIn;
