import React, { useState, useEffect } from 'react';
import ButtonClose from "components/ButtonClose";
import InputWrapper from "components/InputWrapper";
import Input from "components/Input";
import Button from "components/Button";
import './index.scss';

const Questionnaire = (props) => {
  const [step, setStep] = useState(1);
  const [answers, setAnswers] = useState([
    {
      id: 1,
      answer: "",
    },
    {
      id: 2,
      answer: "",
    },
    {
      id: 3,
      answer: "",
    },
    {
      id: 4,
      answer: "",
    },
  ]);
  useEffect(() => {
    if(step === 7) {
      setTimeout(() => {
        props.onClick();
      }, 5000);
    }
  }, [step]); // eslint-disable-line react-hooks/exhaustive-deps
  
  const updateFieldChanged = (name, index) => (event) => {
    let newArr = answers.map((item, i) => {
      if (index === i) {
        return { ...item, [name]: event.target.value };
      } else {
        return item;
      }
    });
    setAnswers(newArr);
  };
  return (
    <div className="questionnaire">
      <div className="questionnaire__content">
        {step === 1 && <>
          <div className="heading">
            <span>
              We need your help!
            </span>
          </div>
          <div className="content-body">
            <span>
              We're trying to make your experience with Texta better and would really love your feedback. 
            </span>
            <br/>
            <span className="question">
              Would you mind answering 4 (very short) questions?
            </span>
          </div>
        </>}
        {step === 2 &&
          <div className="content-body step-2">
            <span>
              We really appreciate you sharing your experience.
            </span>
            <br />
            <span>
              We know you're super busy, so we promise to only ask a few key questions.
            </span>
            <br />
            <span>
              This should take less than 3 minutes to complete.
            </span>
          </div>
        }
        {(step > 2 && step < 7) && 
            <div className="content-body input-form">
              <span className={`label${step===5?" step-5":""}`}>
                {step === 3 && "1. Before using Texta, what tools/solutions did you use to make copywriting easier?"}
                {step === 4 && "2. What particular benefits of Texta made you choose our product over other options?"}
                {step === 5 && "3. What downsides of Texta would have prevented you from choosing Texta?"}
                {step === 6 && "4. Now that you have Texta in your life, what's the #1 thing you're able to do that you weren't before?"}
              </span>
              <InputWrapper
                allowClear={true}
                value={answers[step-3].answer}
                onChange={updateFieldChanged("answer", step-3)}
              >
                <Input
                  placeholder="Provide your answer"
                  maxLength={150}
                  value={answers[step-3].answer}
                  onChange={updateFieldChanged("answer", step-3)}
                  required={true}
                />
              </InputWrapper>
            </div>
        }
        {step === 7 && <>
          <div className="heading close-label">
            <span>Thank you for your response!</span>
          </div>
        </>}
        <div className="button-block">
          {(step > 2 && step < 7) && <>
            <Button isYellow={true} className="button" onClick={()=>{setStep(step-1)}}>
              Back
            </Button>
          </>}
          <Button isGreen={true} className="button"
            onClick={()=>{
              if(step === 6) {
                props.handleSubmit(answers, "answered");
              }
              if(step === 7) {
                props.onClick();
              }
              setStep(step+1);
            }}
          >
            {step === 1 ? "Yes, I'm happy to help" :
            step === 2 ? "Start" :
            step === 6 ? "Submit" :
            step === 7 ? "Close" : "Next"}
          </Button>
        </div>
        {step !== 7 &&
          <div className="hide-block" onClick={()=>{
            props.handleSubmit(answers, "rejected");
            props.onClick();}}
          >
            Do not show anymore
          </div>
        }
        <ButtonClose
          onClick={props.onClick}
          className="questionnaire__close-button"
        />
      </div>
    </div>
  )
}

export default Questionnaire;
