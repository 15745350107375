export const plansMonthly = [
  {
    price_id: "price_1KdDafEYRXygJOoDPLRLEUMr",
    name: "Basic",
    role: "Basic",
    price: 79,
    description: [
      "For solopreneurs",
      "500 credits",
      "up to 120 000 words",
      "Short content 30+ tools",
      "Professional Editor",
      "21 Languages",
      "Multiple user seats",
    ]
  }, {
    price_id: "price_1KdDbbEYRXygJOoDkZ15PAbC",
    name: "Professional",
    role: "Professional",
    price: 99,
    description: [
      "For startups",
      "1000 credits",
      "up to 240 000 words",
      "Article Writer",
      "Ninja Mode (Writing Assistant)",
      "Multiple Writing Styles",
      "Professional Editor",
      "Short content 30+ tools",
      "21 Languages",
      "Multiple user seats",
    ]
  }, {
    price_id: "price_1KdDcEEYRXygJOoDf38qklld",
    name: "Agency",
    role: "Agency",
    price: 199,
    description: [
      "For agencies and marketers",
      "3000 credits",
      "up to 720 000 words",
      "Article Writer",
      "Ninja Mode (Writing Assistant)",
      "Multiple Writing Styles",
      "Professional Editor",
      "Article generator 1.0",
      "Short content 30+ tools",
      "21 Languages",
      "Multiple user seats",
      "Priority Support",
    ]
  }
]
export const plansAnnual = [
  {
    price_id: "price_1KQpNjIyK88iiIsFuEgCESeI",
    name: "Pro",
    role: "Pro Annual",
    price: 490,
    description: [
      "For professional use",
      "75000 words",
      "1$ = 1530 words", 
      "Article generation",
      "Professional Editor",
      "SEO keyword research",
      "30+ Templates",
      "Multiple user seats",
      "Multiple writing styles",
      "Beast Mode(Open ended article generator)"
    ]
  }, {
    price_id: "price_1KHjTrIyK88iiIsFDhcS7XA6",
    name: "Pro MAX",
    role: "Pro MAX Annual",
    price: 890,
    description: [
      "For startups and small businesses",
      "155000 words",
      "1$ = 1741 words", 
      "Article generation",
      "Professional Editor",
      "SEO keywords research",
      "30+ Templates",
      "Multiple user seats",
      "Multiple Writing Styles",
      "Beast Mode (Open ended article generator)",
      "SEO PRO TOOL",
      "5 Plagiarism Checks",
      "Professional Content Rewriter / Paraphraser"
    ]
  }, {
    price_id: "price_1KQpOIIyK88iiIsFFojblxDo",
    name: "Pro MAX Plus",
    role: "Pro MAX Plus Annual",
    price: 2490,
    description: [
      "To generate content on scale",
      "498000 words",
      "1$ = 2000 words", 
      "Article generation",
      "Professional Editor",
      "SEO keywords research",
      "30+ Templates",
      "Multiple user seats",
      "Multiple Writing Styles",
      "Beast Mode (Open ended article generator)",
      "SEO PRO TOOL",
      "10 Plagiarism Checks",
      "Priority Support",
      "Online-meeting and direct guidance from Texta team",
    ]
  }
]

