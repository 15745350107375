import { useState, useContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
// import Avatar from "antd/es/avatar/avatar";
// import { UserOutlined } from "@ant-design/icons";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Popconfirm, message } from "antd";
// import {Table} from "antd";
import { AuthContext } from "Auth";
import Modal from "components/Modal";
import "./index.scss";
import Button from "antd/es/button";
import { cancelSubscription } from "fireFunctions";
import ResetForm from "components/ResetForm";
import {UnsubscribeForm} from "./UnsubscribeForm";
import app from '../../base';
export const db = app.firestore();

// eslint-disable-next-line
Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}
const formatDate = (date) => {
  return date.getDate() + "/" + (date.getMonth()+1) + "/" + date.getFullYear();
};


export const ProfileDetails = ({user: {subscription, currentUser}, resetField}) => {
  const [dates, setDates] = useState({startDate: "", endDate: ""});
  const [cancelled, setCancelled] = useState(subscription ? subscription.cancelled : false);
  const [showUnsubscribeForm, setShowUnsubscribeForm] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [updateDate, setUpdateDate] = useState({ sDate: "", nextCoinUpdateDate: "" });
  const [localValue, setLocalValue] = useState({ planValue: 0, additionalValue: 0 });
  const [typeWords, setTypeWords] = useState(false);
  const { name } = useContext(AuthContext);
  const history1 = useHistory();
  const [showReset, setShowReset] = useState(false);

  useEffect(() => {
    let startDate = "", endDate = "";
    if (!subscription) {
      startDate = new Date(currentUser.metadata.creationTime);
      endDate = startDate.addDays(7);
    } else if (subscription.role !== "Lifetime Deal") {
      startDate = new Date(subscription.current_period_start);
      endDate = new Date(subscription.current_period_end);
    }
    if (startDate && endDate) {
      startDate = formatDate(startDate);
      endDate = formatDate(endDate);
    }
    setDates({startDate, endDate}); // eslint-disable-next-line
  }, [subscription]);


  useEffect(()=>{
    const cancel = async()=>{
      try {
        await cancelSubscription({subId: subscription.id, uid: currentUser.uid});
        setCancelled(true);
        message.success("Thank you for feedback. Subscription is successfully cancelled");
      } catch(e) {
        console.log(e);
        message.error("Error, please try again");
      } finally {
        setShowUnsubscribeForm(false);
      }
    }
    if (formSubmitted) {
      cancel();
    } // eslint-disable-next-line
  }, [formSubmitted]);

  
  // Get coin info from db
  useEffect(() => {
    const search = async () => {
      const customerRef = db
        .collection("customers")
        .doc(currentUser.uid);
      customerRef.get().then(doc => {
        if (doc.exists) {
          const { type_words, coins } = doc.data();
          const lifetimerRef = db
          .collection("lifeTimers")
          .doc(currentUser.uid);
          lifetimerRef.get().then(doc => {
            if (doc.exists) {
              const { start_date } = doc.data();
              setUpdateDate({
                sDate: start_date ? formatDate(start_date.toDate()) : 0, 
                nextCoinUpdateDate: coins ? formatDate(coins.due_date.toDate()) : 0
              });
            }
          }).catch((e) => {
            console.log(e);
          });
          let value, additional_value;
          if(type_words) {
            setTypeWords(type_words);
            value = doc.data().words;
            additional_value = doc.data().additional_words;
          } else {
            value = doc.data().coins;
            additional_value = doc.data().additional_coins;
          }
          setLocalValue({
            planValue: value ? value.amount : 0,
            additionalValue: additional_value ? additional_value.amount : 0
          });
        }
      }).catch((e) => {
        console.log(e);
      });
    };
    search(); // eslint-disable-next-line
  }, []);

  // const planData = [
  //   {
  //     key: '1',
  //     first: "Current plan",
  //     second: subscription ? subscription.role : "Free"
  //   }, 
  //   {
  //     key: '2',
  //     first: "Period start date (dd/mm/yyyy)",
  //     second: dates.startDate
  //   },
  //   {
  //     key: '3',
  //     first: "Period end date",
  //     second: dates.endDate
  //   },
  //   {
  //     key: '4',
  //     first: `Subscription ${typeWords ? "Words" : "Coins"}`,
  //     second: localValue.planValue
  //   },
  //   {
  //     key: '5',
  //     first: `Additional ${typeWords ? "Words" : "Coins"}`,
  //     second: localValue.additionalValue
  //   }
  // ]
  
  // const planColumns = [
  //   {
  //     title: 'first',
  //     dataIndex: 'first',
  //     key: 'first',
  //   },
  //   {
  //     title: 'second',
  //     dataIndex: 'second',
  //     key: 'second',
  //   }
  // ];
  
  return (
    <div>
    <div className="user">
      {(subscription && subscription.role !== "Lifetime Deal") && (cancelled ? <div className="user__cancel-message">Subscription is cancelled</div> :
      <Popconfirm
        title="You are trying to cancel subscription. All coins will stay available until the end of this period."
        onConfirm={()=>{setShowUnsubscribeForm(true)}}
      >          
      <button className="user__cancel-subscription">
            Cancel Subscription   
          </button>
      </Popconfirm> )}
      {showReset && 
        <Modal
          onClick={()=>setShowReset(false)}
        >
          <ResetForm />
        </Modal>}
      {showUnsubscribeForm && <Modal
        colorOverflow={"rgba(49, 52, 58, 0.212)"}
        hideClose={true}
      >   
        <UnsubscribeForm 
          role={subscription && subscription.role} 
          user={currentUser}
          id={subscription.id}
          setFormSubmitted={setFormSubmitted}
        />
      </Modal>}
    </div>
    <div className="profile-details-content">
    <div className="back-button">
    <Button
                  // type="primary"
                  className="back-button"
                  onClick={() => history1.push("/")}
                  icon={<ArrowLeftOutlined />}
                >
                  Back
                </Button>
    </div>
    <div className="profile-details">
      
      <div className="profile-layout-content-block">
        <p className="profile-label">Account Info</p>
        <div className="user-name-email">
            <div className="data">
              <div className="data-label-value-container">
                <p className="data__label">Name</p>
                <p className="data__value"><span className="name-span">{name}</span></p>
              </div>
              <div className="data-add-container">
                <p className="data__add" onClick={()=>setShowReset(true)}>Update</p>
              </div>
            </div>
        <div className="user-email">
          <div className="data">
            <div className="data-label-value-container">
              <p className="data__label">Email</p>
              <p className="data__value"><span className="email-span">{currentUser.email}</span></p>
            </div>
            <div className="data-add-container">
              <p className="data__add" onClick={()=>setShowReset(true)}>Update</p>
            </div>
          </div>
        </div>
        <div className="user-password">
          <div className="data">
            <div className="data-label-value-container">
              <p className="data__label">Password</p>
              <p className="data__value"><span className="password-span">• • • • • • •</span></p>
            </div>
            <div className="data-add-container">
              <p className="data__add" onClick={()=>setShowReset(true)}>Reset</p>
            </div>
          </div>
        </div>
        {(subscription && subscription.role === 'Lifetime Deal') && <>
          <p><b>Start Date: </b>{updateDate.sDate}</p>
          <p><b>Update Date: </b>{updateDate.nextCoinUpdateDate}</p>
          </>
        }  
        
        </div>
      </div>
      <div className="profile-layout-content-block">
      <div className="plan-details">
      <div>
        <p className="profile-label">Plan details</p>
      </div>
      {/* <Table bordered={true} dataSource={planData} showHeader={false} columns={planColumns} pagination={false}/> */}
      <div className="plan-data">
            <div className="plan-data-label-value-container">
              <p className="data__label">Current plan</p>
              <p className="data__value"><span className="password-span">{subscription ? subscription.role : "Free"}</span></p>
            </div>
      </div>
      <div className="plan-data">
            <div className="plan-data-label-value-container">
              <p className="data__label">Period start date (dd/mm/yyyy)</p>
              <p className="data__value"><span className="password-span">{dates.startDate}</span></p>
            </div>
      </div>
      <div className="plan-data">
            <div className="plan-data-label-value-container">
              <p className="data__label">Period end date</p>
              <p className="data__value"><span className="password-span">{dates.endDate}</span></p>
            </div>
      </div>
      <div className="plan-data">
            <div className="plan-data-label-value-container">
              <p className="data__label"> {`Subscription ${typeWords ? "Words" : "Coins"}`}</p>
              <p className="data__value"><span className="password-span">{localValue.planValue}</span></p>
            </div>
      </div>
      <div className="plan-data">
            <div className="plan-data-label-value-container">
              <p className="data__label"> {`Additional ${typeWords ? "Words" : "Coins"}`}</p>
              <p className="data__value"><span className="password-span">{localValue.additionalValue}</span></p>
            </div>
      </div>
     </div> 
     </div>
     </div>
      </div>
      </div>
  );
}
