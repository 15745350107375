import {loadStripe} from '@stripe/stripe-js';

const PUBLIC_STRIPE_PUBLISHABLE_KEY = "pk_live_51KcpDaEYRXygJOoDieSKh787wCBcZPMwvVvs36Q7r56CuDZJydeK0DO9RBaA8eLRLv2KjVHM1UHCa1GWpokowSO4005gcYBX24";
// const PUBLIC_STRIPE_PUBLISHABLE_TEST_KEY = "pk_test_51GxmscIyK88iiIsF9Dq5bNKf61cHPfE7iPFILOhSs29j3edB2Y9bNk7gU3I579qUDi8BwpiavTJXSOpBAWJ5vgIL00DbB3Ia1h";

let stripePromise;
const getStripe = () => {
  if (!stripePromise) {
    stripePromise = loadStripe(PUBLIC_STRIPE_PUBLISHABLE_KEY);
  }
  return stripePromise;
};
export default getStripe;