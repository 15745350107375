import React from 'react'
import { ReactComponent as Arrow1} from "assets/icons/arrow1.svg"
import { ReactComponent as Arrow2} from "assets/icons/arrow2.svg"
import "./styles.scss";

const ArticleBriefExamples = () => {  
  return (
      <div className="article-brief-examples-window">
        <span className="article-brief-examples-window__heading">
          Article brief is a short summary of the article you would like our AI to generate
        </span>
        <span className="article-brief-examples-window__label">Examples</span>
        <Arrow1 className="article-brief-examples-window__arrow-1" />
        <Arrow2 className="article-brief-examples-window__arrow-2" />
        <div className="article-brief-examples-window__block first">
          <span className="article-brief-examples-window__block__content first-text">
            AI is gradually taking over content marketing — it is way faster and cheaper. What should we expect from it?
          </span>
        </div>
        <div className="article-brief-examples-window__block second">
          <span className="article-brief-examples-window__block__content second-text">
            As the world electricity consumption increases, so does the severity of climate change.
          </span>
        </div>
      </div>
  )
}

export default ArticleBriefExamples
