import { ReactComponent as CloseIcon } from "assets/icons/close.svg";
import "./styles.scss";

const ButtonClose = ({onClick, className}) => {
  return (
    <div onClick={onClick} className={`${className} button-close`}>
      <CloseIcon className="button-close__icon" />
    </div>
  );
};

export default ButtonClose;
