import React from "react";
import Input from "components/Input";
import Button from "components/Button";
import {ReactComponent as RobotIcon} from "assets/icons/robot.svg";
import {ReactComponent as TitleIcon} from "assets/icons/title.svg";
import {ReactComponent as AdIcon} from "assets/icons/ad.svg";
import {ReactComponent as KeyIcon} from "assets/icons/key.svg";
import { ReactComponent as SaveIcon } from "assets/icons/save.svg";
import './styles.scss';
import GeneratedText from "../../store/generatedText";
import {Spin, Space, message} from "antd";
import useFocus from "../../hooks/useFocus";
import ProjectManagement from "components/ProjectManagement";

// const countWords = (str) => {
//   const matches = str.match(/[\w\d\’\'-]+/gi);
//   return matches ? matches.length : 0;
// }
//
// const countChars = (str) => str.length;

const LongTextGenPanel = ({className, generateContent, loading, saveInStorage, save, userLevel, source, typeWords}) => {
  const [selectedLength, setSelectedLength] = React.useState("medium");
  const [inputRef, setInputFocus] = useFocus();
  const [articleBriefRef, setArticleBriefFocus] = useFocus();
  const [keywordsRef, setKeywordsFocus] = useFocus();
  const [title, setTitle] = React.useState(GeneratedText.name);
  const [articleBrief, setArticleBrief] = React.useState(GeneratedText.description);
  const [keywords, setKeywords] = React.useState(GeneratedText.keywords);

  const changeLength = (e) => {
    const length = e.target.value;
    setSelectedLength(length);
    GeneratedText.setLongTextResponseLength(length);
  }

  const isTitleBlank = () => !title || /^\s*$/.test(title);
  const isArticleBriefBlank = () => !articleBrief || /^\s*$/.test(articleBrief);
  const isKeywordsBlank = () => !keywords || /^\s*$/.test(keywords);

  const checkTitleAndGenerateContent = (e) => {
    e.preventDefault();
    if (userLevel < 3 && userLevel > 0) {
      message.error("Unavailable at your current plan. Please update and try again.");
      return;
    }
    if (isTitleBlank()) {
      setInputFocus();
      message.error('Please set Document Title');
    } else if (isArticleBriefBlank()) {
      setArticleBriefFocus();
      message.error('Please set Article Brief');
    } else if (isKeywordsBlank()) {
      setKeywordsFocus();
      message.error('Please set Keywords');
    } else {
      generateContent();
    }
  }

  const changeTitle = (event) => {
    const title = event.target.value;
    setTitle(title);
    GeneratedText.changeName(title);
  };

  const changeArticleBrief = (event) => {
    const title = event.target.value;
    setArticleBrief(title);
    GeneratedText.changeDescription(title);
  };

  const changeKeywords = (event) => {
    const val = event.target.value;
    setKeywords(val);
    GeneratedText.changeKeywords(val);
  };
  return (
    <div className={`${className} long-text-gen-panel`}>
    {source === "beast-mode" &&
      <form>
        <div className="long-text-gen-panel__block">
        <div className="panel-title">
            <TitleIcon className="panel-title__icon title" />
            <span className="panel-title__text">Document title</span>
          </div>
          <div className="panel-set-title">
            <Input
              required={true}
              innerRef={inputRef}
              value={title}
              onChange={changeTitle}
              type="text"
              className="panel-set-title__input"
            />
          </div>
        </div>
        <div className="long-text-gen-panel__block">
          <div className="panel-title">
            <RobotIcon className="panel-title__icon" />
            <span className="panel-title__text">Output length</span>
          </div>
          <p className="panel-paragraph">
            How much should the AI write at a time?
          </p>

          <div className="panel-length">
            <label className="panel-length__radio radio">
              <input
                className="radio__input"
                type="radio"
                name="changeLength"
                value="small"
                checked={selectedLength === "small"}
                onChange={changeLength}
              ></input>
              <div className="radio__custom-radio">A little</div>
            </label>
            <label className="panel-length__radio radio">
              <input
                className="radio__input"
                type="radio"
                name="changeLength"
                value="medium"
                checked={selectedLength === "medium"}
                onChange={changeLength}
              ></input>
              <div className="radio__custom-radio">Between</div>
            </label>
            <label className="panel-length__radio radio">
              <input
                className="radio__input"
                type="radio"
                name="changeLength"
                value="large"
                checked={selectedLength === "large"}
                onChange={changeLength}
              ></input>
              <div className="radio__custom-radio">A lot</div>
            </label>
          </div>
        </div>
        <div className="long-text-gen-panel__block">
          <div className="panel-title">
            <AdIcon className="panel-title__icon" />
            <span className="panel-title__text">Article brief</span>
          </div>
          {/* <p className="panel-paragraph">
            Provide the AI with a brief of what you are writing about, describe
            it like you are speaking to a friend.
          </p> */}

          <Input
            className="panel-brief-area"
            tag="textarea"
            onChange={changeArticleBrief}
            value={articleBrief}
            innerRef={articleBriefRef}
          />
        </div>
        <div className="long-text-gen-panel__block">
          <div className="panel-title">
            <KeyIcon className="panel-title__icon" />
            <span className="panel-title__text">Keywords</span>
          </div>
          <Input
            className="panel-brief-area"
            tag="textarea"
            onChange={changeKeywords}
            value={keywords}
            innerRef={keywordsRef}
          />
        </div>
        {/*<div className="long-text-gen-panel__block">*/}
        {/*  <div className="panel-title">*/}
        {/*    <ChartBoxIcon className="panel-title__icon" />*/}
        {/*    <span className="panel-title__text">Writing Stats</span>*/}
        {/*  </div>*/}

        {/*  <div className="panel-stats">*/}
        {/*    <div className="panel-stats__row">*/}
        {/*      <span className="panel-stats__label">Pages</span>*/}
        {/*      <span className="panel-stats__value">21</span>*/}
        {/*    </div>*/}
        {/*    <div className="panel-stats__row">*/}
        {/*      <span className="panel-stats__label">Words</span>*/}
        {/*      <span className="panel-stats__value">{words_count}</span>*/}
        {/*    </div>*/}
        {/*    <div className="panel-stats__row">*/}
        {/*      <span className="panel-stats__label">Characters</span>*/}
        {/*      <span className="panel-stats__value">{char_count}</span>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="buttons">
          <Button
            isBlue={userLevel > 2 || userLevel === 0}
            onClick={(e)=>{checkTitleAndGenerateContent(e);}}
            type="submit"
            coins={!typeWords ? 2 : null}
            className="span-large"
          >
            <Space>
              <span>Write for me</span>
              {loading && <Spin />}
            </Space>
          </Button>
          <Button
            onClick={()=>{saveInStorage();}}
            className="span-small"
            title="Save in editor"
          >
            <Space>
              <SaveIcon/>
            </Space>
          </Button>
        </div>
      </form> }
      {source !== "beast-mode" &&
        <ProjectManagement 
          save={save}
          input={{description: articleBrief, title, keywords}}
        />
      }
    </div>
  );
};

export default LongTextGenPanel;
