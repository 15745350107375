import { ReactComponent as SocialAds } from 'assets/icons/social-ads.svg';
import { ReactComponent as StartupTools } from 'assets/icons/startup-tools.svg';
import { ReactComponent as WebsiteCopy } from 'assets/icons/website-copy.svg';
import { ReactComponent as EcommerceIcon } from "assets/icons/comm.svg";
import { ReactComponent as Emails } from 'assets/icons/emails.svg';
import { ReactComponent as SocialMedia } from 'assets/icons/social-media.svg';
import { ReactComponent as SalesCopy } from 'assets/icons/sales-copy.svg';
import { ReactComponent as WritingTools } from 'assets/icons/writing-tools.svg';
import { ReactComponent as LongTextIcon } from "assets/icons/text.svg";
import { ReactComponent as FavoriteIcon } from "assets/icons/star.svg";
import { ReactComponent as ClipboardIcon } from "assets/icons/heart.svg";
import { ReactComponent as LightningIcon } from "assets/icons/lightning.svg";
import { ReactComponent as TutorialIcon } from "assets/icons/tutorials.svg";
import { ReactComponent as SEOIcon } from "assets/icons/compas.svg";
import { ReactComponent as EditorIcon } from "assets/icons/scissors.svg";
import { ReactComponent as PlagirismCheckerIcon } from "assets/icons/scanner.svg"
const icons = {
  "Social Ads": SocialAds,
  "Startup Tools": StartupTools,
  "Website Copy": WebsiteCopy,
  "Email/Letter": Emails,
  "Social Media": SocialMedia,
  "Ecommerce": EcommerceIcon,
  "Sales Copy": SalesCopy,
  "Writing Tools": WritingTools,
  "long-text-gen": LongTextIcon,
  "long-text-gen-2.0": LongTextIcon,
  "favorite-tool": FavoriteIcon,
  "clipboard": ClipboardIcon,
  "tutorials" : TutorialIcon,
  "beast-mode": LongTextIcon,
  "beast-mode-2.0" : LightningIcon,
  "editor": EditorIcon,
  "plagiarism" : PlagirismCheckerIcon,
  "SEO Tools" : SEOIcon
};

export default icons;