import React, {useState} from 'react';
import { withRouter, Redirect } from "react-router";
import axios from 'axios';
import Row from "antd/es/row";
import Col from "antd/es/col"
import "./login.scss";
import app from '../../base';
import Spin from "antd/es/spin";
import SignIn from './SignIn';
import SignUp from './SignUp';
// import { AuthContext } from 'Auth';
import { blackListCountries } from './blackListCountries';
export const db = app.firestore();
export const SignInContext = React.createContext();
export const logoUrl = "https://firebasestorage.googleapis.com/v0/b/articleninja-ai.appspot.com/o/logo.png?alt=media&token=b25b593a-e664-44a5-9ce1-ce379d0b8788"

function Login({ location }) {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  const [page, setPage] = useState(true);
  const [loading, setLoading] = useState(false);
  // const {subType} = useContext(AuthContext);
  // const [isWhiteUser, setIsWhiteUser] = useState();

  const getData = axios.get('https://geolocation-db.com/json/')
  .then((res) => {
    return {country_status: blackListCountries[res.data.country_name], ip: res.data.IPv4};
  });

  // const checkUser = async (uid) => {
  //   const usersRef = db
  //     .collection("customers")
  //     .doc(uid);
  //   await usersRef.get().then(async (doc) => {
  //     if (doc.exists) {
  //       if (!doc.data().is_white_user) {
  //         await getData.then((res) => {
  //           setLoading(false);
  //           usersRef.update({is_white_user: !res.country_status});
  //           setIsWhiteUser(!res.country_status);
  //           setIsSignedIn(true);
  //         });
  //       } else {
  //         setLoading(false);
  //         setIsWhiteUser(doc.data().is_white_user);
  //         setIsSignedIn(true);
  //       }
  //     }
  //   }).catch((e) => {
  //     console.log(e);
  //   });
  // }
  
  return (<div>
    {
      !isSignedIn ? (
      <SignInContext.Provider value={{
        setIsSignedIn
      }}>
      <div>
      {loading && 
      <Row
        className='spin'
        type="flex"
        justify="center"
        align="middle"
        style={{ minHeight: "100vh" }}
      >
        <Spin tip="Loading..." />
      </Row>}
        <Row type="flex" justify="center" align="middle" style={{minHeight: '100vh'}}>
          <Col>
            <div className="logo" align="middle">
              <a href="https://www.articleninja.ai/">
                <img src={logoUrl} alt="ArticleNinja.ai"/>
              </a>
            </div>
            <div className='nav'>
            <h3 onClick={()=>setPage(true)} className={`main-text ${page && "active"}`} align="middle">Sign In</h3>
            <h3 onClick={()=>setPage(false)} className={`main-text ${!page && "active"}`} align="middle">Create Account</h3>
            </div>
            <div>

            </div>
            {page ? <SignIn setLoading={setLoading}/> : <SignUp setLoading={setLoading} loading={loading} getData={getData}/> } 
          </Col>
        </Row>
        
      </div>
      </SignInContext.Provider>
    ) : 
    (location && location.state && location.state.from ? 
      (<Redirect to={location.state.from.pathname} />) 
      : 
      <Redirect to="/" />)
    }
  </div>);
  
}

export default withRouter(Login);
