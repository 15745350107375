import firebase from "firebase";

if (process.env.NODE_ENV === "development") {
  firebase.functions().useEmulator("localhost", 5001);
}

export const loginSSO = firebase.functions().httpsCallable("loginSSO");

export const callbackSSO = firebase.functions().httpsCallable("callbackSSO");

export const createText = firebase.functions().httpsCallable("createText");

export const generateLongText = firebase.functions().httpsCallable("generateLongText");

export const createTextAPI = firebase.functions().httpsCallable("createTextAPI");

export const cancelSubscription = firebase.functions().httpsCallable("cancelSubscription");

export const applyDiscountOnCancel = firebase.functions().httpsCallable("applyDiscountOnCancel"); 

export const endTrial = firebase.functions().httpsCallable("endTrial");