import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AuthProvider } from "./Auth";
import PrivateRoute from "./components/PrivateRoute";
import Login from "pages/Login/Login";
import Profile from "./components/Profile";
import { ToastContainer } from "react-toastify";
import PromocodeForm from "components/PromocodeForm";
// import EarnCredits from "components/EarnCredits"
import Dashboard from "pages/Dashboard";
import Projects from "./components/Projects";
import Action from "./components/Action";
import PersonalProfile from "./components/PersonalProfile"
import {SuccessPayment} from "./components/SuccessPayment"
function App() {
  return (
    <AuthProvider>
      <Router>
        <div>
          <PrivateRoute exact path="/" component={Dashboard} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/plans" component={Profile} source="login"/>
          <PrivateRoute path="/personal-profile" component={PersonalProfile} />
          <PrivateRoute path="/life-time-deal" component={PromocodeForm}/>
          <PrivateRoute path="/projects" component={Projects}/>
          {/* <PrivateRoute path="/earn-credits" component={EarnCredits}/> */}
          <Route exact path="/login" component={Login} />
          <Route exact path="/action" component={Action} />
          <PrivateRoute exact path="/success" component={SuccessPayment} />
          <ToastContainer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
