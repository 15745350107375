import React from 'react'
import { ReactComponent as Arrow1} from "assets/icons/arrow1.svg"
import { ReactComponent as Arrow2} from "assets/icons/arrow2.svg"
import "./styles.scss";

const KeywordsExamples = () => {  
  return (
      <div className="keywords-examples-window">
        <span className="keywords-examples-window__heading">
          Keywords need to be comma-separated
        </span>
        <span className="keywords-examples-window__label">Examples</span>
        <Arrow1 className="keywords-examples-window__arrow-1" />
        <Arrow2 className="keywords-examples-window__arrow-2" />
        <div className="keywords-examples-window__block first">
          <span className="keywords-examples-window__block__content first-text">
            AI, copywriting, writing assistant
          </span>
        </div>
        <div className="keywords-examples-window__block second">
          <span className="keywords-examples-window__block__content second-text">
            CO2, global warming, ozone layer 
          </span>
        </div>
      </div>
  )
}

export default KeywordsExamples
