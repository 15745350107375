import React from "react";
import SidebarMenu from "../SidebarMenu";
import "./index.scss";

const Sidebar = ({
  className,
  onChangeSection,
  onChangeSpecialSection,
  toggleCategory,
  selectedProps,
  activeSpecialSection,
  subscription,
  typeWords,
  uid
}) => {
  return (
    <aside className={`${className} layout-content-block sidebar`}>
      <div className="sidebar__logo" />

      <SidebarMenu
        className="sidebar__menu"
        onChangeSection={onChangeSection}
        onChangeSpecialSection={onChangeSpecialSection}
        toggleCategory={toggleCategory}
        selectedProps={selectedProps}
        activeSpecialSection={activeSpecialSection}
        subscription={subscription}
        typeWords={typeWords}
        uid={uid}
      />
    </aside>
  );
};

export default Sidebar;
