import React, {useContext, useEffect, useState} from "react";
import Modal from "components/Modal";
import Input from "components/Input";
import InputWrapper from "components/InputWrapper";
import Button from "components/Button";
import Results from "components/Results";
import DashboardFormChangeLanguage from "components/DashboardFormChangeLanguage";
import {ReactComponent as SettingsIcon} from "assets/icons/settings.svg";
import generatedText from "store/generatedText";
import { createText } from "fireFunctions";
import {observer} from "mobx-react-lite";
import { toast } from "react-toastify";
import "./index.scss";
import {ReactComponent as CheckIcon} from "assets/icons/checkbox-yes.svg";
import { AuthContext } from "Auth";
const ExampleLink = observer(({active, index, setIndex}) => {
  const filled = generatedText.examples[index].title && 
    generatedText.examples[index].description && 
    generatedText.examples[index].bulletPoint; 
  return <div className={`link ${active && "active"}`} onClick={()=> setIndex(index)}>
    <span>Step {index+1} </span>
    <div className={`link__status ${filled && "filled"}`}>{filled ? <CheckIcon/> : " "}</div>
  </div>;
});
const Example = observer(({index}) => {
  const {examples} = generatedText;
  const {title, description, bulletPoint} = examples[index];

  const onDescriptionChange = (e)=>{
    const value = e.target.value;
    generatedText.changeExampleDescription(index, value);
  }
  const onTitleChange = (e) => {
    const value = e.target.value;
    generatedText.changeExampleTitle(index, value);
  }

  const onBulletPointChange = (e) => {
    const value = e.target.value;
    generatedText.changeExampleBulletPoint(index, value);
  }
  return (
    <div className="form-examples__wrapper">
      <div className={`dashboard-form__examples _small`}>
        <InputWrapper
          label={"Title"}
          value={title}
          onChange={onTitleChange}
          allowClear={true}
          required={true}
        >
          <Input
            value={title}
            onChange={onTitleChange}
            required={true}
            placeholder={`Provide the related product title`}
          />
        </InputWrapper>
      </div>
      <div className={`dashboard-form__examples _small`}>
        <InputWrapper
          label={"Bullet Point"}
          value={bulletPoint}
          onChange={onBulletPointChange}
          allowClear={true}
          required={true}
        >
          <Input
            tag="textarea"
            value={bulletPoint}
            onChange={onBulletPointChange}
            required={true}
            placeholder={`Provide the bullet pointed description of related product`}
          />
        
        </InputWrapper>
      </div>
      <div className={`dashboard-form__examples _small`}>
        <InputWrapper
          label={"Product Description"}
          value={description}
          onChange={onDescriptionChange}
          allowClear={true}
          required={true}
        >
          <Input
            tag="textarea"
            value={description}
            onChange={onDescriptionChange}
            required={true}
            placeholder={`Provide the description of related product`}

          />
        </InputWrapper>
      </div>
    </div>
  )
});

export default observer(({
    freeDays, 
    coins, 
    typeWords,
    words,
    teamWords,
    subscription,
    coinsReduce, 
    save, 
    teamInfo,
    teamCoins, 
    mode,
    loadCoins
  }) => {
  useEffect(() => {
    return () => {
      generatedText.setResults([]);
    };
  }, []);
  const {currentUser} = useContext(AuthContext);
  const [showLanguageModal, toggleLanguageModal] = useState(false);
  const [currentLength, setCurrentLength] = useState(0);
  const [index, setIndex]= useState(0);
  const {hasName, hasAudience, hasDescription, hasSubtopic, hasKeywords, hasExamples} =
    generatedText.selectedToolProperties;

  const hasRightBlock = hasName || hasAudience || hasKeywords;

  const {description, name, audience, keywords, subtopic, inputLanguage, outputLanguage} =
    generatedText;

  const onDescriptionChange = (e) => {
    const value = e.target.value;
    generatedText.changeDescription(value);
  };

  const onNameChange = (e) => {
    const value = e.target.value;
    generatedText.changeName(value);
  };

  const onInputLanguageChange = (language) => {
    generatedText.changeInputLanguage(language);
  };

  const onOutputLanguageChange = (language) => {
    generatedText.changeOutputLanguage(language);
  };

  const onAudienceChange = (e) => {
    const value = e.target.value;
    generatedText.changeAudience(value);
  };

  const onKeywordsChange = (e) => {
    const value = e.target.value;
    generatedText.changeKeywords(value);
  };
  const onSubtopicChange = (e) => {
    const value = e.target.value;
    generatedText.changeSubtopic(value);
  };
  const checkExamples = () => {
    let filled = true;
    generatedText.examples.forEach(({title, description, bulletPoint})=>{
      if (!(title && description && bulletPoint)) {
        filled = false;
      }
    });
    return filled;
  }
  const generateTextLocal = async() => {
    
    const amount =  generatedText.outline.split("\n").filter(subtopic => subtopic.trim() !== "").length;
    if ((((mode === "personal" && coins < amount) || (mode === "team" && teamCoins < amount)) && !typeWords) ||
      (((mode === "personal" && words < 50) || (mode === "team" && teamWords < 50)) && typeWords)) {
      toast.error(
        "Insufficient balance. Please, upgrade your account or reduce your outline manually"
      );
      return;
    }
    if (freeDays <= 0 && subscription === "Free Trial") {
      toast.error("Your trial has expired");
      return;
    }
    if (generatedText.loading) {
      return;
    }
    if(typeWords) {
      const {wordsReturned, addWordsReturned} = await loadCoins(mode === "personal" ? currentUser.uid : teamInfo.owner_id);
      if (wordsReturned + addWordsReturned < 50) {
        toast.error("Insufficient balance");
        return;
      }
    } else {
      const {coinsReturned, addCoinsReturned} = await loadCoins(mode === "personal" ? currentUser.uid : teamInfo.owner_id);
      if (coinsReturned + addCoinsReturned < 1) {
        toast.error("Insufficient balance");
        return;
      }
    }
    if (hasExamples) {
      const examplesDone = checkExamples();
      if (!examplesDone) {
        toast.error("Please fill ALL examples with information on related products");
        return;
      }
      generatedText.changeLoading(true);
      const {examples} = generatedText;
      const tools = [
        { 
          toolName: "Amazon Title Generator",
          title1: examples[0].title,
          title2: examples[1].title,
          title3: examples[2].title,
          exampleFieldNo: 1,
          resultName: "Title"
        },
        {
          toolName: "Amazon Bullet Points Generator", 
          bulletPoint1: examples[0].bulletPoint,
          bulletPoint2: examples[1].bulletPoint,
          bulletPoint3: examples[2].bulletPoint,
          exampleFieldNo: 2,
          resultName: "Bullet Point"
        },
        {
          toolName: "Amazon Product Description Generator", 
          description1: examples[0].description,
          description2: examples[1].description,
          description3: examples[2].description,
          exampleFieldNo: 3,
          resultName: "Description"
        },
      ];
      const result = await Promise.all(
        tools.map(async(tool) => {
          let res;
          await createText({
            ...tool,
            keywords,
            typeWords
          }).then(result => {res = result;})
            .catch((error) => {
              if (error.message) {
                toast.error(error.message);
              }
              generatedText.changeError(error);
          })
          return {data: res.data.generatedText, name: tool.resultName}
        })
      )
      let resultString = "";
      result.forEach(({data, name})=>{
        resultString += name + "\n"+data[0].text + "\n";
      })
      generatedText.setResultsMore([{index: 0, text: resultString}]);
      generatedText.changeLoading(false);
    } else {
      generatedText.generateText(typeWords);
    }
  }
  const onSubmit = async (event) => {
    event.preventDefault();
    generateTextLocal();
  };

  useEffect(() => {
    if(!typeWords) {
      if (subscription === "Free Trial"){
        coinsReduce(1);
      }
    } else {
      let wordsCounter = 0 - currentLength;
      generatedText.results.forEach((item)=> {
        wordsCounter += item.text.split(/\n| /).filter(subtopic => subtopic.trim() !== "").length;
      });
      setCurrentLength(wordsCounter);
      coinsReduce(wordsCounter);
    }
  }, [generatedText.results]) // eslint-disable-line react-hooks/exhaustive-deps
  
  const input = {
    description: generatedText.description,
    ...(generatedText.selectedToolProperties.hasName) && {title: generatedText.name},
    ...(generatedText.selectedToolProperties.hasAudience) && {audience: generatedText.audience},
  }

  return (
    <section className="dashboard-form">
      <form className="dashboard-form__content" onSubmit={onSubmit}>
        <div className="layout-content-block dashboard-form__block">
          {hasDescription && (
            <InputWrapper
              label={hasSubtopic ? "Article Brief" : "Describe your product"}
              value={description}
              onChange={onDescriptionChange}
              allowClear={true}
              maxLength={300}
              required={true}
            >
              <div
                className={`dashboard-form__textarea-description ${
                  !hasRightBlock ? "_small" : ""
                }`}
              >
                <Input
                  tag="textarea"
                  value={description}
                  onChange={onDescriptionChange}
                  maxLength={300}
                  required={true}
                  placeholder={hasSubtopic && `Provide the AI with a brief of what you are writing about, describe it like you are speaking to a friend. 20-30 words.`}

                />
              </div>
            </InputWrapper>
          )}
          {hasExamples && 
            <div className="form-examples">
              <h3 className="title">Related products </h3>
              <div className="form-examples__menu">
                <ExampleLink setIndex={setIndex} index={0} active={index === 0}/>
                <ExampleLink setIndex={setIndex} index={1} active={index === 1}/>
                <ExampleLink setIndex={setIndex}  index={2} active={index === 2}/>
              </div>
              <Example index={index}/>
            </div>
          }
          <div className="dashboard-form__buttons">
            <Button
              IconEl={SettingsIcon}
              onClick={() => toggleLanguageModal(true)}
              tool={generatedText.selectedToolProperties.category+': '+generatedText.selectedToolProperties.toolName}
            >
              Languages
            </Button>
            {showLanguageModal ? (
              <Modal onClick={() => toggleLanguageModal(false)}>
                <DashboardFormChangeLanguage
                  inputLanguage={inputLanguage}
                  outputLanguage={outputLanguage}
                  onInputLanguageChange={onInputLanguageChange}
                  onOutputLanguageChange={onOutputLanguageChange}
                  onClose={() => toggleLanguageModal(false)}
                />
              </Modal>
            ) : null}
            <div className="right-button">
            {hasExamples && index < 2 ? 
              <Button
                type="submit"
                isBlue={true}
                className={"next-button"}
                onClick={()=>{
                  if ((generatedText.examples[index].title && 
                            generatedText.examples[index].description && 
                            generatedText.examples[index].bulletPoint)) {
                        setIndex(Math.min(2, index+1))
                  }
                }}
              >
                Next
              </Button>

            : 
              <Button
                type="submit"
                loading={generatedText.loading}
                disabled={generatedText.loading}
                isBlue={true}
                coins={!typeWords ? 1 : null}
                subscription={subscription}
                tool={generatedText.selectedToolProperties.category+': '+generatedText.selectedToolProperties.toolName}
              >
                {generatedText.loading ? "Loading.." : "Create"}
              </Button>}
            </div>
          </div>
        </div>
        {hasRightBlock ? (
          <div className="layout-content-block dashboard-form__block">
            {hasName ? (
              <div className="dashboard-form__input">
                <InputWrapper
                  label={hasSubtopic ? "Article Title" : "Name of your brand / Product (optional)"}
                  allowClear={true}
                  value={name}
                  onChange={onNameChange}
                  required={hasSubtopic}
                >
                  <Input
                    placeholder={hasSubtopic ? "ex.: AI powered text generation" : "e.g.  Texta.ai"}
                    maxLength={100}
                    value={name}
                    onChange={onNameChange}
                    required={hasSubtopic}
                  />
                </InputWrapper>
              </div>
            ) : null}

            {hasAudience ? (
              <InputWrapper
                label="Target Audience"
                value={audience}
                onChange={onAudienceChange}
                allowClear={true}
              >
                <div className="dashboard-form__textarea-audience">
                  <Input
                    tag="textarea"
                    value={audience}
                    onChange={onAudienceChange}
                    placeholder="e.g. digital marketers in Paris"
                    maxLength={100}
                  />
                </div>
              </InputWrapper>
            ) : null}
            {hasSubtopic ? (
              <div className="dashboard-form__input">
                <InputWrapper
                  label="Subtopic"
                  value={subtopic}
                  onChange={onSubtopicChange}
                  allowClear={true}
                  required
                >
                  <Input
                    value={subtopic}
                    onChange={onSubtopicChange}
                    placeholder="ex.:  History of AI"
                    maxLength={100}
                    required
                  />
                </InputWrapper>
              </div>
            ): null}
            {hasExamples &&
              <InputWrapper
                  label="Describe your product"
                  value={description}
                  onChange={onDescriptionChange}
                  allowClear={true}
                  maxLength={300}
                  required={true}
                >
                <div className="dashboard-form__description">
                  <Input
                    tag="textarea"
                    value={description}
                    onChange={onDescriptionChange}
                    required={true}
                    placeholder="Provide AI with a description of your product"
                  />
                  </div>
              </InputWrapper> }
            {hasKeywords ? (
              <InputWrapper
                label="Keywords"
                value={keywords}
                onChange={onKeywordsChange}
                allowClear={true}
                required
              >
                <div className="dashboard-form__keywords">
                  <Input
                    tag="textarea"
                    value={keywords}
                    onChange={onKeywordsChange}
                    placeholder="Provide comma-separated keywords you want to see in the article. Example: NLP,  text,  AI"
                    maxLength={100}
                    required
                  />
                </div>
              </InputWrapper>
            ): null}
          </div>
        ) : null}
      </form>

      {generatedText.results.length || generatedText.loading ? (
        <Results
          className="dashboard-form__results"
          results={generatedText.results}
          input={input}
          generateText={generateTextLocal}
          loading={generatedText.loading}
          toolName={generatedText.selectedToolProperties.toolName}
          save={save}
        />
      ) : null}
    </section>
  );
});
