import './styles.scss';

const Input = ({
  tag = 'input',
  placeholder,
  innerRef,
  maxLength,
  type = "text",
  required = false,
  value,
  onChange,
}) => {
  const TagType = tag === "textarea" ? "textarea" : "input";

  return (
    <TagType
      ref={innerRef}
      className="iinput"
      type={type}
      value={value}
      onChange={onChange}
      required={required}
      placeholder={placeholder}
      maxLength={maxLength}
    />
  );
};

export default Input;
