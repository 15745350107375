import { useState } from "react";
import Dropdown from "components/Dropdown";
import InputWrapper from "components/InputWrapper";
import Button from "components/Button";
import langList from "./staticDataLang";
import "./styles.scss";

const DashboardFormChangeLng = ({
  inputLanguage,
  outputLanguage,
  onInputLanguageChange,
  onOutputLanguageChange,
  onClose,
  outline,
}) => {
  const [inputLang, setInputLang] = useState(inputLanguage);
  const [outputLang, setOutputLang] = useState(outputLanguage);

  function onChange() {
    onInputLanguageChange(inputLang);
    onOutputLanguageChange(outputLang);

    onClose();
  }
  return (
    <div className="change-lang">
      <div className="change-lang__row">
        <InputWrapper label={`${
            outline ? "Input Language (Name, Description & Outline)"
            : "Input Language (Name & Description)"
          }`}>
          <Dropdown list={langList} value={inputLang} onChange={setInputLang} />
        </InputWrapper>
      </div>

      <div className="change-lang__row">
        <InputWrapper label={`${
            outline ? "Output Language (Long Text)"
            : "Output Language (Result)"
          }`}>
          <Dropdown
            list={langList}
            value={outputLang}
            onChange={setOutputLang}
          />
        </InputWrapper>
      </div>

      <div className="change-lang__row _buttons">
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={onChange} isBlue={true}>
          Apply Setting
        </Button>
      </div>
    </div>
  );
};

export default DashboardFormChangeLng;
