import React, {useState} from 'react';
import firebase from 'firebase';
import axios from 'axios';
import "./login.scss";
import app from '../../base';
import GoogleProvider from './GoogleProvider';
import RegistrationForm from "./RegistrationForm";
import { VerifiedEmailButton } from './VerifiedEmailButton';
export const db = app.firestore();
const auth = firebase.auth();

function SignUp({setLoading, loading, getData}) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword]= useState("");
  const [passwordConfirm, setPasswordConfirm]= useState("");
  const [currentStep, setCurrentStep] = useState("one");
  const [errorMessage, setErrorMessage] = useState("");
  // const [isBlackListCountry, setIsBlackListCountry] = useState(true);
  const [ip, setIP] = useState(null);

  const checkUserCountry = async() => {
    await getData.then((res) => {
      // setIsBlackListCountry(res.country_status);
      setIP(res.ip);
    });
  }

  const stepOne = <div className='login-form main'>
    <button className="login-form__button email" onClick={()=>{setCurrentStep("email"); checkUserCountry();}}>Sign up with Email</button>
    <GoogleProvider setCurrentStep={setCurrentStep} setLoading={setLoading} setErrorMessage={setErrorMessage}/>
    </div>;

  const signUpEmail = async(e) => {
    e.preventDefault();

    if (password !== passwordConfirm) {
      setErrorMessage("Passwords do not match");
      return;
    }
    setLoading(true);
    let isError = false;

      var timestamp = firebase.firestore.Timestamp.now().seconds;
      const ipRef = db
        .collection("regAttempts")
        .doc(ip);
      await ipRef.get().then(async (doc) => {
        if (doc.exists) {
          const { attempts, pause } = doc.data();
          if(attempts + 1 > 3 && (timestamp - pause < 300)) {
            setLoading(false);
            isError = true;
            setErrorMessage("Please try again later");
            return;
          } else if (timestamp - pause > 300) {
            ipRef.delete();
          }
        }
      }).catch((e) => {
        console.log(e);
      });
      if(isError) {
        return;
      }

      await axios.get(`https://api.mailcheck.ai/domain/${email.substring(email.indexOf("@") + 1)}`)
      .then(async(res) => {
        if(res.data.disposable) {
          await ipRef.get().then(async (doc) => {
            const attempts = doc.exists ? doc.data().attempts : 0;
            ipRef.set({
              attempts: attempts + 1,
              pause: timestamp
            });
          }).catch((e) => {
            console.log(e);
          });
          setLoading(false);
          isError = true;
          setErrorMessage("Please do not use temporary email address");
        }
      }).catch((e) => {
        console.log(e);
      });
      if(isError) {
        return;
      }
    
    await auth.createUserWithEmailAndPassword(email, password).then(async(credential) => {
      await credential.user.sendEmailVerification();
      setErrorMessage("");
      credential.user.updateProfile({
        displayName: name
      });
      db.collection("customers").doc(auth.currentUser.uid).set({email: email});
      setLoading(false);
      setCurrentStep("email-verification");
    }
    ).catch((e)=>{
      setLoading(false);
      if (e.message === "auth/weak-password") {
        setErrorMessage("Weak password");
      } else {
        setErrorMessage(e.message);
      }
    });
  }
  const emailForm = <form onSubmit={signUpEmail} className='login-form'>
      <div className='login-form__input'>
        <input id="email" type="email" placeholder="Email" value={email} onChange={(e)=>setEmail(e.target.value)} required/>
      </div>
      <div className='login-form__input'>
        <input id="name" placeholder="Name" value={name} onChange={(e)=>setName(e.target.value)} required/>
      </div>
      <div className='login-form__input'>
        <input id="psw" type="password" placeholder="Password" value={password} onChange={(e)=>setPassword(e.target.value)} required/>
      </div>
      <div className='login-form__input'>
        <input id="pswConfirm" type="password" placeholder="Confirm Password" value={passwordConfirm} onChange={(e)=>setPasswordConfirm(e.target.value)} required/>
      </div>
      <span className='login-form__error'>{errorMessage}</span>
      <button className='login-form__button' type="submit">Sign Up</button>
    </form>

  const renderPage = (param) => {
    switch (param) {
      case "one":
        return stepOne;
      case "email":
        return emailForm;
      case "email-verification":
        return <VerifiedEmailButton setLoading={setLoading} setCurrentStep={setCurrentStep}/>;
      case "reg-form":
        return <RegistrationForm/>;
      default:
        return stepOne;
    }
  }
  return (renderPage(currentStep));
};

export default SignUp;
