import './styles.scss';
import { ReactComponent as CoinIcon } from "assets/icons/coins2.svg";

const Button = ({
  onClick,
  type = "button",
  isBlue,
  isYellow,
  isGreen,
  disabled,
  IconEl,
  children,
  className,
  coins,
  subscription,
  title,
  tool
}) => {
  const clickEvent = (e) => {
    if (disabled || !onClick) {
      return;
    }
    onClick(e);
  }
  return (
    <button
      type={type}
      onClick={clickEvent}
      title={title}
      className={`button ${isBlue ? "_blue" : ""}${isYellow ? "_yellow" : ""}${isGreen ? "_green" : ""} ${className}`}
    >
      {IconEl ? <IconEl className={`button__icon`} /> : null}
      {children}
      {(!disabled && (subscription !== "Lifetime Deal") && (isYellow || isGreen || isBlue) && coins) &&
      <>
        <span className='button__coins'>{coins}</span>
        <CoinIcon className={`button__icon_coins`} />
      </>}
    </button>
  );
};

export default Button;