import React, { useContext, useState, useEffect } from "react";
import Layout from "antd/es/layout";
import Logo from "../Logo";
import Row from "antd/es/row";
import Space from "antd/es/space";
import { useHistory } from "react-router-dom";
import Text from "antd/es/typography/Text";
import Divider from "antd/es/divider";
import Button from "antd/es/button";
import app from "../../base";
import { LogoutOutlined } from "@ant-design/icons";
import { AuthContext } from "../../Auth";
// import Avatar from "antd/es/avatar/avatar";
// import Image from "antd/es/image";
import {ProfileDetails} from "components/ProfileDetails";
// import {TeamManagement} from "components/TeamManagement";
import "./index.scss";

const db = app.firestore();

const { Header, Content, Footer } = Layout;

const PersonalProfile = () => {
  const { currentUser, name } = useContext(AuthContext);
  // eslint-disable-next-line
  const [page, setPage] = useState(0);
  const [subscription, setSubscription] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const updateSubscription = async ()=>{
      await db.collection("customers")
        .doc(currentUser.uid)
        .collection("subscriptions")
        .get()
        .then((snapshot) => {
          snapshot.forEach((subscription) => {
            if (subscription.data()) {
              setSubscription({
                id: subscription.id,
                role: subscription.data().role,
                current_period_start: subscription
                  .data()
                  .current_period_start.toDate(),
                current_period_end: subscription
                  .data()
                  .current_period_end.toDate(),
                cancelled: subscription
                  .data()
                  .cancel_at_period_end,
              });
            }
          });
        });
      await db.collection("lifeTimers")
        .doc(currentUser.uid)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setSubscription({
              role: "Lifetime Deal"
            })
          }
        });
    }
    currentUser && updateSubscription();
    // eslint-disable-next-line
  }, []);

  // const avatar = currentUser.picture ? (
  //   <Avatar src={<Image src={currentUser.picture} />} />
  // ) : (
  //   <Avatar icon={<UserOutlined />} />
  // );
  return (
    <Layout style={{ minHeight: "100vh" }} theme="light">
      <Header className="site-layout-background" style={{ padding: 0 }}>
        <Row
          justify="space-between"
          style={{ marginRight: "16px" }}
          align="middle"
        >
          <Logo />
          <Space>
            {/* <Link to="/profile">
              <Space>
                {avatar} */}
                <Text>{name}</Text>
              {/* </Space>
            </Link> */}
            <Divider type="vertical" />
            <Button
              className="back-button"
              onClick={() => app.auth().signOut().then(()=>{history.push("/login")})}
              icon={<LogoutOutlined />}
            >
              Log out
            </Button>
          </Space>
        </Row>
      </Header>
      <Layout className="site-layout">
        <Content style={{ padding: "0 50px" }}>
            <div>&nbsp;</div>
            
              <div className="">
                  <ProfileDetails user={{subscription, currentUser}} resetField={true}/>
                {/* <div className={`content ${page === 1 && "visible"}`}>
                  <TeamManagement user={{subscription, currentUser}} />
                </div> */}
              </div>
            
          </Content>
        <Footer style={{ textAlign: "center" }}></Footer>
      </Layout>
    </Layout>
  );
}

export default PersonalProfile;