import { ReactComponent as CNFlagIcon } from "assets/icons/flags/CN.svg";
import { ReactComponent as DEFlagIcon } from "assets/icons/flags/DE.svg";
import { ReactComponent as DKFlagIcon } from "assets/icons/flags/DK.svg";
import { ReactComponent as ESFlagIcon } from "assets/icons/flags/ES.svg";
import { ReactComponent as FRFlagIcon } from "assets/icons/flags/FR.svg";
import { ReactComponent as ITFlagIcon } from "assets/icons/flags/IT.svg";
import { ReactComponent as JPFlagIcon } from "assets/icons/flags/JP.svg";
import { ReactComponent as MYFlagIcon } from "assets/icons/flags/MY.svg";
import { ReactComponent as PLFlagIcon } from "assets/icons/flags/PL.svg";
import { ReactComponent as RUFlagIcon } from "assets/icons/flags/RU.svg";
import { ReactComponent as USFlagIcon } from "assets/icons/flags/US.svg";
import { ReactComponent as PTFlagIcon } from "assets/icons/flags/PT.svg";
import { ReactComponent as BRFlagIcon } from "assets/icons/flags/BR.svg";
import { ReactComponent as NLFlagIcon } from "assets/icons/flags/NL.svg";
import { ReactComponent as PHFlagIcon } from "assets/icons/flags/PH.svg";
import { ReactComponent as GRFlagIcon } from "assets/icons/flags/GR.svg";
//import { ReactComponent as INFlagIcon } from "assets/icons/flags/IN.svg";
// import { ReactComponent as IDFlagIcon } from "assets/icons/flags/ID.svg";
import { ReactComponent as NOFlagIcon } from "assets/icons/flags/NO.svg";
import { ReactComponent as CZFlagIcon } from "assets/icons/flags/CZ.svg";
import { ReactComponent as SEFlagIcon } from "assets/icons/flags/SE.svg";
import { ReactComponent as TWFlagIcon } from "assets/icons/flags/TW.svg";
import { ReactComponent as VNFlagIcon } from "assets/icons/flags/VN.svg";


const langList = [
  { code: "en", label: "English", icon: USFlagIcon },
  { code: "zh", label: "Chinese", icon: CNFlagIcon },
  { code: "da", label: "Danish", icon: DKFlagIcon },
  { code: "fr", label: "French", icon: FRFlagIcon },
  { code: "de", label: "German", icon: DEFlagIcon },
  { code: "it", label: "Italian", icon: ITFlagIcon },
  { code: "ja", label: "Japanese", icon: JPFlagIcon },
  { code: "ms", label: "Malay", icon: MYFlagIcon },
  { code: "pl", label: "Polish", icon: PLFlagIcon },
  { code: "ru", label: "Russian", icon: RUFlagIcon },
  { code: "es", label: "Spanish", icon: ESFlagIcon },
  { code: "pt-PT", label: "Portuguese (Portugal)", icon: PTFlagIcon },
  { code: "pt-BR", label: "Portuguese (Brazil)", icon: BRFlagIcon },
  { code: "nl", label: "Dutch", icon: NLFlagIcon },
  { code: "fil", label: "Filipino", icon: PHFlagIcon },
  { code: "el", label: "Greek", icon: GRFlagIcon },
  //{ code: "hi", label: "Hindi", icon: INFlagIcon },
  // { code: "id", label: "Indonesian", icon: IDFlagIcon },
  { code: "no", label: "Norwegian", icon: NOFlagIcon },
  { code: "cs", label: "Czech", icon: CZFlagIcon },
  { code: "sv", label: "Swedish", icon: SEFlagIcon },
  { code: "zh-TW", label: "Chinese (Taiwan)", icon: TWFlagIcon },
  { code: "vi", label: "Vietnamese", icon: VNFlagIcon },
  
];

export default langList;
