import React from "react";
import AnimateHeight from 'react-animate-height';
import Icons from './icons';
import { categories, specialCategories } from 'store/categories';
import {ReactComponent as LabelIcon} from "assets/icons/label.svg"

import "./index.scss";

const categoryNames = Object.keys(categories);

const SidebarMenu = ({
  className,
  onChangeSection,
  onChangeSpecialSection,
  toggleCategory,
  selectedProps,
  activeSpecialSection,
  subscription,
  typeWords,
  // uid
}) => {
  let activeCategory = selectedProps.category;
  let activeSubcategory = selectedProps.toolName;
  // const IconSEO = Icons["SEO Tools"];
  return (
    <nav className={`${className} sidebar-menu`}>
      {specialCategories.map((category) => {
        const Icon = Icons[category.uid];

        return (
          <div key={category.uid}>
            {typeWords || category.uid !== "beast-mode-2.0" ?
              <div
                onClick={() => {onChangeSpecialSection(category.uid);}}
                className={`menu _special ${
                  activeSpecialSection === category.uid ? "_active" : ""
                }`}
              >
                {Icon ? <Icon className="menu__icon" /> : null}
                <span className="menu__text">{category.btnText}</span>
                {category.label ? (
                  <div className="menu__label">
                    <LabelIcon />
                    <div className="menu__text menu__label__text">{category.label}</div>
                  </div>
                ) : null}
              </div>
            : null}
          </div>
        );
      })}
      {/* <div className="menu-list">
        <span className="menu seo"
          onClick={()=>{
            Mixpanel.track('SEO Tools');
            window.open(`https://us-central1-texta-ai.cloudfunctions.net/loginSSO/${uid}`, '_blank');
          }}>
          <IconSEO className="menu__icon" />
          <span className="menu__text">SEO Tools</span>
        </span>
      </div> */}
      {categoryNames.map((category) => {
        const Icon = Icons[category];
        let currentCategory = categories[category];
        if (category === "Writing Tools" && subscription === "Lifetime Deal") {
          delete currentCategory["Paragraph Generator"];
        }
        return (
          <div key={category} className={`menu-list`}>
            <div
              className={`menu ${activeCategory === category ? "_active" : ""}`}
              onClick={() => toggleCategory(category)}
            >
              <Icon className="menu__icon" />
              <span className="menu__text"> {category} </span>
              <span className="menu__counter">
                {" "}
                {Object.keys(currentCategory).length}{" "}
              </span>
              {/* <MenuItemIcon className="menu__right-icon" /> */}
            </div>
            <div className={`submenu-list`}>
              <AnimateHeight
                duration={500}
                height={activeCategory === category ? "auto" : 0}
              >
                {Object.keys(categories[category]).map((tool) => (
                    <div
                      key={tool}
                      className={`submenu ${
                        activeSubcategory === tool ? "_active" : ""
                      }`}
                      onClick={() => {onChangeSection({ category, tool });}}
                    >
                      {tool}
                    </div>
                ))}
              </AnimateHeight>
            </div>
          </div>
        );
      })}
    </nav>
  );
};

export default SidebarMenu;
