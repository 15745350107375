import React from "react";

const useFocus = () => {
    const htmlElRef = React.useRef(null)
    const setFocus = () => {htmlElRef.current && htmlElRef.current.focus()}

    return [ htmlElRef, setFocus ]
};

export default useFocus;
